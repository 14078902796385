import React from 'react';
import meetTheTeamHeading from '@assets/footages/meet-the-team/heading.svg';
import HeadingBackground from '@assets/footages/meet-the-team/heading-background.svg';
import advisorImg from '@assets/footages/meet-the-team/advisor.svg';
import { useNavigate } from 'react-router-dom';
import CoreTeam from './CoreTeam';
import {
  CORE_MEMBERS,
  EXECUTIVE_MEMBERS,
  SPECIAL_THANKS_MEMBERS,
} from './TeamList';
import ExecutiveMember from './ExecutiveMember';

import placeholder01 from '@assets/footages/meet-the-team/executive-01.svg';
import placeholder02 from '@assets/footages/meet-the-team/executive-02.svg';
import placeholder03 from '@assets/footages/meet-the-team/executive-03.svg';
import placeholder04 from '@assets/footages/meet-the-team/executive-04.svg';

import HeartPerson1 from '@assets/footages/meet-the-team/heart-person-1.svg';
import HeartPerson2 from '@assets/footages/meet-the-team/heart-person-2.svg';
import HeartPerson3 from '@assets/footages/meet-the-team/heart-person-3.svg';
import HeartPerson4 from '@assets/footages/meet-the-team/heart-person-4.svg';
import HeartPerson5 from '@assets/footages/meet-the-team/heart-person-5.svg';
import HeartPerson6 from '@assets/footages/meet-the-team/heart-person-6.svg';
import HeartPerson7 from '@assets/footages/meet-the-team/heart-person-7.svg';
import HeartPerson8 from '@assets/footages/meet-the-team/heart-person-8.svg';
import HeartPerson9 from '@assets/footages/meet-the-team/heart-person-9.svg';

import SpecialThanksPerson1 from '@assets/footages/meet-the-team/special-thanks-person-1.svg';
import SpecialThanksPerson2 from '@assets/footages/meet-the-team/special-thanks-person-2.svg';
import SpecialThanksPerson3 from '@assets/footages/meet-the-team/special-thanks-person-3.svg';
import SpecialThanksPerson4 from '@assets/footages/meet-the-team/special-thanks-person-4.svg';
import Button from '@components/Button';

const placeholders = [
  placeholder01,
  placeholder02,
  placeholder03,
  placeholder04,
];

const HeartPeople = [
  HeartPerson1,
  HeartPerson2,
  HeartPerson3,
  HeartPerson4,
  HeartPerson5,
  HeartPerson6,
  HeartPerson7,
  HeartPerson8,
  HeartPerson9,
];

const SpecialThanksPeople = [
  SpecialThanksPerson1,
  SpecialThanksPerson2,
  SpecialThanksPerson3,
  SpecialThanksPerson4,
];

export default function MeetTheTeam() {
  const navigate = useNavigate();
  const gotoContactUs = () => {
    navigate('/contact-us');
  };

  return (
    <>
      <div className="relative pt-[30px] md:pt-[100px] px-[28px] md:px-[76px]">
        <div className="absolute right-0 top-0" style={{ zIndex: -1 }}>
          <img src={HeadingBackground} alt="Heading Background" />
        </div>

        <div className="text-6xl md:text-8xl leading-[58px] md:leading-[85px] font-extrabold">
          Meet the Team
        </div>

        <div className="md:text-2xl mt-[20px] md:mt-[40px] max-w-[796px]">
          Driven by a shared vision and a passion for mental health, we are
          dedicated to making mental health care inclusive, accessible, and
          readily available to all.
        </div>

        <div className="mt-[80px] mb-[40px] py-[20px] md:my-[80px] md:py-[40px] grid grid-cols-1 md:grid-cols-2 md:gap-x-[52px] lg:gap-x-[76px] gap-y-[64px] md:gap-y-[80px]">
          {EXECUTIVE_MEMBERS.map((member, index) => (
            <div key={member.name} className="w-full">
              <ExecutiveMember
                placeholderImage={placeholders[index]}
                name={member.name}
                videoId={member.videoId}
                title={member.title}
                subTitle={member.subTitle}
                about={member.about}
              />
            </div>
          ))}
        </div>

        <div className="pb-[20px] md:pb-[40px] mb-[40px] md:mb-[96px] grid grid-cols-1 md:grid-cols-2 md:gap-[64px]">
          <div className="md:hidden mb-[20px] text-center font-extrabold text-[28px] leading-[36px]">
            Our Advisor
          </div>

          <div className="w-full">
            <img
              src={advisorImg}
              alt="Professor Simon Collinson"
              className="mb-[20px] md:mb-0 w-full h-auto object-cover"
            />
          </div>

          {/* Text Content Section */}
          <div>
            <h2 className="hidden md:block mb-[5px] md:mb-[20px] text-[28px] md:text-7xl leading-[36px] md:leading-[65px] font-extrabold">
              Our Advisor
            </h2>
            <h3 className="hidden md:block mb-[5px] md:mb-[10px] text-[12px] md:text-5xl leading-[20px] md:leaing-[45px] font-semibold md:font-extrabold">
              Professor Simon Collinson
            </h3>
            <h3 className="md:hidden font-extrabold text-[28px] leading-[36px]">
              Simon Collinson
            </h3>
            <p className="mb-[20px] mt-[5px] md:mt-[28px] md:mb-[28px] md:text-3xl md:leading-[30px] font-semibold">
              Professor of Psychology and Digital Health at James Cook
              University | Visiting Professor at the National University of
              Singapore
            </p>

            <p className="text-[15px] md:text-lg leading-[20px] md:leading-[26px]">
              Professor Simon Collinson is an expert in neuropsychology and
              digital health with over 20 years of experience. He is a Professor
              of Psychology at James Cook University, specializing in brain
              health and digital innovation, and a Visiting Professor at NUS's
              Yeo Boon Khim Mind Science Centre. His career spans academia and
              industry, including a role as Chief Scientific Officer at Savonix,
              where he led digital health projects. As a Consultant
              Neuropsychologist, he specializes in brain injury, dementia, and
              adult ADHD. A recognized thought leader, Professor Collinson
              bridges research and practice to enhance mental health.
            </p>
          </div>
        </div>

        <div className="pt-[20px] mb-[40px] text-center font-extrabold text-[28px] md:text-7xl md:leading-[65px]">
          The Heart of HeyJuni
        </div>
        <div className="grid grid-cols-2 md:grid-cols-2 lg:grid-cols-3 gap-x-[20px] md:gap-x-[72px] gap-y-[40px] md:gap-y-[80px] pb-[20px] mb-[40px] md:pb-[69px] md:mb-[96px]">
          {CORE_MEMBERS.map((member, index) => (
            <CoreTeam
              avatar={HeartPeople[index]}
              name={member.name}
              title={member.title}
            />
          ))}
        </div>

        <div className="pt-[20px] mb-[40px] text-center font-extrabold text-[28px] md:text-7xl md:leading-[65px]">
          Special Thanks
        </div>
        <div className="grid grid-cols-2 md:grid-cols-2 lg:grid-cols-4 gap-x-[20px] md:gap-x-[72px] gap-y-[40px] md:pb-0 md:mb-0">
          {SPECIAL_THANKS_MEMBERS.map((member, index) => (
            <CoreTeam
              avatar={SpecialThanksPeople[index]}
              name={member.name}
              title={member.title}
            />
          ))}
        </div>
      </div>

      <div className="bg-[#181818] flex flex-col justify-center items-center p-[50px] md:py-[80px] mt-[60px] md:mt-[200px]">
        <p className="text-5xl md:text-7xl leading-[48px] md:leading-[65px] font-extrabold text-white text-center">
          Be a part of our team
        </p>
        <Button
          label="Join Our Team"
          onClick={gotoContactUs}
          className="mt-[30px] md:mt-[40px] rounded-[20px] md:rounded-[24px] h-[50px] md:h-[60px] px-[30px] md:px-[30px] font-semibold text-lg md:text-2xl"
        />
      </div>
    </>
  );
}
