import React, { useEffect, useState } from 'react';
import Chip from './Chip';
import { useNavigate } from 'react-router-dom';

interface ChipRadioProps {
  options: string[];
  selectedOption: string;
}

export default function ChipRadio({ options, selectedOption }: ChipRadioProps) {
  const navigate = useNavigate();

  const handleSelect = (option: string) => () => {
    navigate(`/faq?selected=${option}`);
  };

  return (
    <div className="flex flex-wrap gap-2">
      {options.map((option) => (
        <Chip
          key={option}
          text={option}
          isSelected={selectedOption === option}
          select={handleSelect(option)}
        />
      ))}
    </div>
  );
}
