import React from 'react';

interface StepProgressBarProps {
  total: number;
  currentStep: number;
}

// TODO: Put varible into tailwind

export function StepProgressBar({ total, currentStep }: StepProgressBarProps) {
  return (
    <div className="flex justify-between items-center gap-[8px] md:gap-[13.5px] w-full">
      {/* Loop to create steps */}
      {[...Array(total)].map((_, index) => (
        <div
          key={index}
          className={`flex-1 h-[5px] md:h-2 rounded-full transition-all duration-300 ${
            index < currentStep ? 'bg-[#977CFF]' : 'bg-[#F6F6F6]'
          }`}
        />
      ))}
    </div>
  );
}
