import z from 'zod';
import { UserBasicInfoSchema } from '../user';

export enum CauseOfAnxiety {
    FAMILY_CONFLICT = 'FAMILY_CONFLICT',
    FEAR_OF_FLYING = 'FEAR_OF_FLYING',
    PUBLIC_SPEAKING = 'PUBLIC_SPEAKING',
    FINANCIAL_STRESS = 'FINANCIAL_STRESS',
    WORK_OR_SCHOOL_STRESS = 'WORK_OR_SCHOOL_STRESS',
    BULLYING = 'BULLYING',
    SEXUAL_ABUSE_OR_HARASSMENT = 'SEXUAL_ABUSE_OR_HARASSMENT',
    ANXIETY_DUE_TO_LIFE_CHANGES = 'ANXIETY_DUE_TO_LIFE_CHANGES',
    OTHER = 'OTHER',
}
export const CauseOfAnxietySchema = z.nativeEnum(CauseOfAnxiety);

export enum FeelingDescription {
    NERVOUS = 'NERVOUS',
    UNABLE_TO_RELAX = 'UNABLE_TO_RELAX',
    SOMETHING_AWFUL = 'SOMETHING_AWFUL',
    UNCONTROLLED_WORRYING = 'UNCONTROLLED_WORRYING',
    RESTLESS = 'RESTLESS',
    UNABLE_TO_CONCENTRATE = 'UNABLE_TO_CONCENTRATE',
    TOO_MANY_WORRIES = 'TOO_MANY_WORRIES',
    OBSESSIVE_THOUGHTS = 'OBSESSIVE_THOUGHTS',
    URGE_TO_ESCAPE = 'URGE_TO_ESCAPE',
}
export const FeelingDescriptionSchema = z.nativeEnum(FeelingDescription);

export type FeelingDescriptions = FeelingDescription[];
export const FeelingDescriptionsSchema = z.array(FeelingDescriptionSchema);

export enum OutcomeExpectation {
    OPEN_UP = 'OPEN_UP',
    HELP_TO_CALM = 'HELP_TO_CALM',
    LEARN_TECHNIQUES = 'LEARN_TECHNIQUES',
    NEED_ENCOURAGEMENT = 'NEED_ENCOURAGEMENT',
    UNDERSTAND_TRIGGERS = 'UNDERSTAND_TRIGGERS',
    OTHER = 'OTHER',
}
export const OutcomeExpectationSchema = z.nativeEnum(OutcomeExpectation);

export enum AgeGroup {
    '16_19' = '16_19',
    '20_24' = '20_24',
    '25_29' = '25_29',
    '30_34' = '30_34',
    '35_39' = '35_39',
    '40_45' = '40_45',
    'ABOVE_45' = 'ABOVE_45',
}
export const AgeGroupSchema = z.nativeEnum(AgeGroup);

export const TimezoneSchema = z.union([
    z.literal('-12'),
    z.literal('-11'),
    z.literal('-10'),
    z.literal('-9'),
    z.literal('-8'),
    z.literal('-7'),
    z.literal('-6'),
    z.literal('-5'),
    z.literal('-4'),
    z.literal('-3'),
    z.literal('-2'),
    z.literal('-1'),
    z.literal('0'),
    z.literal('1'),
    z.literal('2'),
    z.literal('3'),
    z.literal('4'),
    z.literal('5'),
    z.literal('5.5'),
    z.literal('5.75'),
    z.literal('6'),
    z.literal('6.5'),
    z.literal('7'),
    z.literal('8'),
    z.literal('9'),
    z.literal('9.5'),
    z.literal('10'),
    z.literal('11'),
    z.literal('12'),
    z.literal('13'),
    z.literal('14'),
]);
export type Timezone = z.infer<typeof TimezoneSchema>;

export enum PreferredMode {
    TEXT = 'TEXT',
    VOICE = 'VOICE',
    VIDEO = 'VIDEO',
}
export const PreferredModeSchema = z.nativeEnum(PreferredMode);

export const BookingFormDataSchema = z.object({
    concernDescription: z.string().optional(),
    causeOfAnxiety: CauseOfAnxietySchema,
    feelingDescriptions: FeelingDescriptionsSchema,
    anxietyIntensity: z.number(),
    outcomeExpectation: OutcomeExpectationSchema,
    currentlySeekingTherapy: z.boolean(),
    preferredMode: PreferredModeSchema,
    preferredDateTime: z.coerce.date(),
    timezone: TimezoneSchema.optional(),
    ageGroup: AgeGroupSchema,
});
export type BookingFormData = z.infer<typeof BookingFormDataSchema>;

export const CreateBookingRequestInputSchema = UserBasicInfoSchema.merge(BookingFormDataSchema);
export type CreateBookingRequestInput = z.infer<typeof CreateBookingRequestInputSchema>;

export const CheckoutInfoSchema = z.object({
    bookingRequestId: z.number(),
    paymentSecret: z.string(),
    publishableKey: z.string(),
});
export type CheckoutInfo = z.infer<typeof CheckoutInfoSchema>;
