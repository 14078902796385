import React from 'react';
import peopleImage from '@assets/footages/learn-more/for-woman-by-woman.svg';
import peopleImageDesktop from '@assets/footages/learn-more/for-woman-by-woman-desktop.svg';
import Button from '@components/Button';
import { useNavigate } from 'react-router-dom';

export default function RealWomenSection() {
  const navigate = useNavigate();

  return (
    <section className="relative bg-white mb-[64px] md:mb-0 md:py-[64px] md:px-[76px] overflow-hidden">
      <div className="hidden md:block  md:leading-[65px] md:text-7xl font-extrabold text-center md:text-left md:mb-[16px]">
        For Women, By Women
      </div>

      <div className="md:hidden text-5xl leading-[48px] font-extrabold text-center">
        <div>For Women,</div>
        <div>By Women</div>
      </div>

      <div className="md:hidden my-[40px]">
        <img src={peopleImage} />
      </div>

      <div className="md:w-[52%] px-[28px] md:px-0 md:py-[16px] font-regular text-base md:text-2xl">
        <p className="">
          There’s something deeply healing about being heard by another woman.
          It creates a space where closeness and understanding naturally grow.
        </p>
        <br />
        <p className="">
          Our Peer Supporters are all women who’ve faced their own hardships,
          and they genuinely want to empathize and connect with you - just as
          you are. While we’re here to help ease your anxiety and worries, we
          believe that real conversations shouldn’t have barriers. True healing
          comes when we allow ourselves to be vulnerable, feeling safe and free
          from judgment in the presence of someone who is similar to us, who
          understands deeply.
        </p>
        <div className="my-[20px] md:mt-[40px] md:mb-0 text-center md:text-left">
          <Button
            label="Book Session"
            className="h-[50px] rounded-[20px] md:h-[60px] md:rounded-[24px] font-semibold text-lg md:text-2xl !px-[30px]"
            onClick={() => navigate('/booking')}
          />
        </div>
      </div>

      {/* TODO: Fix this position later */}
      <div className="hidden md:block md:absolute md:top-[calc(50%-20px)] md:right-[0px] md:transform md:-translate-y-1/2">
        <img src={peopleImageDesktop} />
      </div>
    </section>
  );
}
