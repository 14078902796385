import React, { useContext, useState } from 'react';

import { useBookingContext } from './BookingContext';
import Option from './components/Option';
import { PopUp } from '@components/Popup';

import checkedIcon from '@assets/icons/shared/checked.svg';

interface Step11Props {
  setShowTermCondition: (value: boolean) => void;
}

export function Step11(props: Step11Props) {
  const { email, setEmail, isChecked, setIsChecked } = useBookingContext();
  const { setShowTermCondition } = props;

  const [isModalOpen, setIsModalOpen] = useState(false);
  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  return (
    <>
      <div className="hidden md:block booking-step-title">
        Email confirmation
      </div>

      <div className="block md:hidden font-extrabold text-[28px] leading-[36px]">
        Email confirmation
      </div>

      <div className="flex flex-col gap-[20px] md:gap-[24px] my-[10px] md:my-[30px]">
        <p className="font-regular text-sm md:text-lg text-[#181818]">
          Kindly share your email address so we can confirm your session and
          keep you updated.
        </p>
        <p className="font-semibold text-base md:text-lg text-[#181818]">
          Please check your inbox for important follow-ups.
        </p>

        <div className="">
          <input
            type="email"
            placeholder="Enter Email Address"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="w-full border-b border-black focus:outline-none focus:border-black placeholder-gray-500 py-2"
          />
        </div>

        <div className="flex flex-row items-center">
          <label className="flex items-center cursor-pointer">
            <input
              type="checkbox"
              checked={isChecked}
              onChange={() => setIsChecked(!isChecked)}
              className="w-0 h-0 opacity-0 absolute"
            />
            <div
              className={`w-[40px] h-[40px] mr-[15px] rounded-[3px] border border-black flex items-center justify-center ${
                isChecked ? 'bg-[#AF9CFB]' : 'bg-white'
              }`}
            >
              {isChecked && (
                <img src={checkedIcon} alt="Check Icon" className="" />
              )}
            </div>
          </label>

          {/* Text does not toggle checkbox */}
          <span className="text-[13px] md:text-base">
            {`I have read HeyJuni's `}
            <span
              className="text-[#797979] underline cursor-pointer font-semibold select-none"
              onClick={() => setShowTermCondition(true)}
            >
              *Terms & Conditions here
            </span>
          </span>
        </div>
      </div>
    </>
  );
}
