import React, { useState, useEffect } from 'react';

interface TimeLeft {
  days: number;
  hours: number;
  minutes: number;
  seconds: number;
}

export function CountdownTimer({ targetDate }: { targetDate: Date }) {
  const calculateTimeLeft = (): TimeLeft => {
    const difference = targetDate.getTime() - new Date().getTime();

    if (difference > 0) {
      return {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / (1000 * 60)) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
    } else {
      return { days: 0, hours: 0, minutes: 0, seconds: 0 }; // Countdown ended
    }
  };

  const [timeLeft, setTimeLeft] = useState<TimeLeft>(calculateTimeLeft);

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000); // Update every second

    // Clear interval on component unmount
    return () => clearInterval(timer);
  }, [targetDate]);

  return (
    <div className="md:mt-[40px] p-[18px] min-w-auto md:min-w-[530px] flex justify-between border border-gray-300 rounded-[20px] space-x-2 md:space-x-4 text-center font-bold text-lg">
      <div className="flex flex-col gap-[25px]">
        <ValueItem value={timeLeft.days} />
        <div className="font-semibold md:text-3xl md:leading-[30px]">Days</div>
      </div>
      <Colon />

      <div className="flex flex-col gap-[25px]">
        <ValueItem value={timeLeft.hours} />
        <div className="font-semibold md:text-3xl md:leading-[30px]">Hours</div>
      </div>
      <Colon />

      <div className="flex flex-col gap-[25px]">
        <ValueItem value={timeLeft.minutes} />
        <div className="font-semibold md:text-3xl md:leading-[30px]">Mins</div>
      </div>
      <Colon />

      <div className="flex flex-col gap-[25px]">
        <ValueItem value={timeLeft.seconds} />
        <div className="font-semibold md:text-3xl md:leading-[30px]">Secs</div>
      </div>
    </div>
  );
}

function ValueItem(props: { value: number }) {
  const { value } = props;
  return (
    <span className="font-extrabold md:text-5xl md:leading-[30px]">
      {String(value).padStart(2, '0')}
    </span>
  );
}

function Colon() {
  return (
    <span className="font-extrabold md:text-5xl md:leading-[30px]">:</span>
  );
}
