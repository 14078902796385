import React, { useRef, useEffect, useState } from 'react';

import bg01 from '@assets/footages/become-a-listener/supporter-background-1.svg';
import bg02 from '@assets/footages/become-a-listener/supporter-background-2.svg';
import bg03 from '@assets/footages/become-a-listener/supporter-background-3.svg';
import bg04 from '@assets/footages/become-a-listener/supporter-background-4.svg';

import bg01Mobile from '@assets/footages/become-a-listener/supporter-background-1-mobile.svg';
import bg02Mobile from '@assets/footages/become-a-listener/supporter-background-2-mobile.svg';
import bg03Mobile from '@assets/footages/become-a-listener/supporter-background-3-mobile.svg';
import bg04Mobile from '@assets/footages/become-a-listener/supporter-background-4-mobile.svg';

import avatar01 from '@assets/footages/become-a-listener/avatar-pei-shan.svg';
import avatar02 from '@assets/footages/become-a-listener/avatar-pradnya.svg';
import avatar03 from '@assets/footages/become-a-listener/avatar-ana.svg';
import avatar04 from '@assets/footages/become-a-listener/avatar-yoke.svg';
import avatar05 from '@assets/footages/become-a-listener/avatar-jeba.svg';
import avatar06 from '@assets/footages/become-a-listener/avatar-bao.svg';
import avatar07 from '@assets/footages/become-a-listener/avatar-wen-xin.svg';

import profile01 from '@assets/footages/become-a-listener/profile-pei-shan.svg';
import profile02 from '@assets/footages/become-a-listener/profile-pradnya.svg';
import profile03 from '@assets/footages/become-a-listener/profile-ana.svg';
import profile04 from '@assets/footages/become-a-listener/profile-yoke.svg';
import profile05 from '@assets/footages/become-a-listener/profile-jeba.svg';
import profile06 from '@assets/footages/become-a-listener/profile-bao.svg';
import profile07 from '@assets/footages/become-a-listener/profile-wen-xin.svg';

import profileBackground from '@assets/footages/become-a-listener/profile-background.svg';

import closeIcon from '@assets/icons/shared/close-icon.svg';

import clsx from 'clsx';
import { Swipe } from '@components/Swipe';
import { PopUp } from '@components/Popup';
import { MobileSwipe } from '@components/MobileSwipe';

type CardItem = {
  id: number;
  name: string;
  description: string;
  avatar: string;
  background: string;
  backgroundMobile: string;
};

const cards = [
  {
    id: 1,
    name: 'Hey, I’m Pei-Shan',
    description:
      '“I’ve learned how crucial it is to navigate life’s challenges without being steered solely by emotions or experiences, embracing instead the power of conscious choice and perspective. We all can live fulfilling lives by choosing how we respond, rather than merely reacting.”',
    avatar: avatar01,
    background: bg01,
    backgroundMobile: bg01Mobile,
  },
  {
    id: 2,
    name: 'Hi there, I’m Pradnya',
    description:
      '“I have had moments in my life where I felt alone, unheard and misunderstood and I wished I could have had someone to speak to. Sometimes, this may entail speaking to someone outside my usual support system too. Don’t be so hard on yourself, you are doing your best. “',
    avatar: avatar02,
    background: bg02,
    backgroundMobile: bg02Mobile,
  },
  {
    id: 3,
    name: 'Hey, I’m Ana',
    description:
      '“I believe that if people would start actually listening to each other, we can create a safe space for everyone to be open about their feelings and mental wellbeing. I feel that speaking about it, is a step in the right direction of healing”',
    avatar: avatar03,
    background: bg03,
    backgroundMobile: bg03Mobile,
  },
  {
    id: 4,
    name: 'Hello, I’m Yoke',
    description: `”Reaching out to others can be daunting. Yet, by reading my bio, you have taken a courageous step in your journey towards emotional relief.  Sometimes, we just need someone around. I'm here for you.”`,
    avatar: avatar04,
    background: bg04,
    backgroundMobile: bg04Mobile,
  },
  {
    id: 5,
    name: 'Hi there, I’m Jeba',
    description: `“Having navigated my own mental health experiences, I empathize with the need for non-judgmental support. Today, I'm committed to offering that same level of understanding and care to others. Whenever you feel the need to talk, please know that I'm here to listen. 🌟”`,
    avatar: avatar05,
    background: bg01,
    backgroundMobile: bg01Mobile,
  },
  {
    id: 6,
    name: 'Hey, I’m Bao',
    description:
      "“In the past, I used to hold my feelings in due to fear of judgment. But as I grew, I've learned that talking about my feelings can really help, and I've indeed become happier ever since.”",
    avatar: avatar06,
    background: bg02,
    backgroundMobile: bg02Mobile,
  },
  {
    id: 7,
    name: 'Hello, I’m Wen Xin',
    description:
      '“Personally, I have had my experiences with anxiety and depression in my life. I know pain, despair and loneliness. These first-hand experiences help me to empathize, understand and relate to others that are hurting better.”',
    avatar: avatar07,
    background: bg03,
    backgroundMobile: bg03Mobile,
  },
];

const profiles = [
  {
    id: 1,
    image: profile01,
    title: 'Introducing Pei-Shan',
    language: 'Mandarin-Chinese, English',
    description:
      'Hi there! I believe in the power of compassionate listening and the importance of having a space to be truly heard - not defined by the past but empowered to choose our perspective. This drives my commitment to empathetic listening, guiding others toward growth and self-acceptance. In my own journey, I’ve learned how crucial it is to navigate life’s challenges without being steered solely by emotions or experiences, embracing instead the power of conscious choice and perspective. We all can live fulfilling lives by choosing how we respond, rather than merely reacting.',
    area: 'Depression, anxiety and trauma, loneliness, peer support, stress, burn out, gender topics, bullying and harassment, workplace conflicts, school and career issues, eating disorders, negative body image, emotional immunity, life changes, minority and ethnic communities, relationship issues, mindfulness practices',
  },
  {
    id: 2,
    image: profile02,
    title: 'Introducing Pradnya',
    language: 'English, Hindi, Konkani',
    description:
      'Hi there! This is Pradnya. I value mental health because it makes us human and is as important as physical health; Both are intertwined. I became a Peer Supporter as I believe that peer support can provide a safe space for one to share about their issues, feel validated, reflect and see new perspectives, and hopefully encourage healthy coping and drive small positive changes a step at a time. I have had moments in my life where I felt alone, unheard and misunderstood and I wished I could have had someone to speak to. Sometimes, this may entail speaking to someone outside my usual support system too. Don’t be so hard on yourself, you are doing your best. I’m here to listen and to support you on your path to mental wellness. Let’s start this journey together!',
    area: 'Mental health, women’s health, anxiety and trauma stemming from sexual violence, depression, harassment and bullying, workplace conflicts, stress, burnout, minority and ethnic communities, life changes, grief, addiction, relationship problems, family issues, loneliness, person-centered care, utilizing the strengths perspective, community development, social-health integration',
  },
  {
    id: 3,
    image: profile03,
    title: 'Introducing Ana',
    language: 'English, Afrikaans',
    description:
      "Hi there! My name is Ana and I'm so happy you're here! Mental health is a big part of our lives and aren't talked about enough. I believe that if people would start actually listening to each other, we can create a safe space for everyone to be open about their feelings and mental wellbeing. Through out my mental health journey, I found that most people are afraid to open up the topic in fear of judgment. I feel that speaking about it, is a step in the right direction of healing. In conclusion, people need people to listen, to understand and for support. We shouldn't push each other away.",
    area: 'Anxiety, depression, peer support, stress, burnout, emotional immunity, workplace conflicts, life changes, grief, relationship conflicts, family issues, loneliness, negative body image, addiction, mindfulness practices',
  },
  {
    id: 4,
    image: profile04,
    title: 'Introducing Yoke',
    language: 'English',
    description:
      "Hello! I'm Yoke. Reaching out to others can be daunting. Yet, by reading my bio, you have taken a courageous step in your journey towards emotional relief. I am really proud of you for being here! Having experienced the impact of appropriate emotional support, I understand how being an active and empathetic peer supporter can uplift us. Sometimes, we just need someone around. I'm here for you.",
    area: 'Anxiety and trauma, peer support, stress, burn out, eating disorders, negative body image, life changes, youth’s and children’s mental health, relationship issues, mindfulness practices',
  },
  {
    id: 5,
    image: profile05,
    title: 'Introducing Jeba',
    language: 'English',
    description:
      "Hi, I'm Jeba. Throughout my journey, I've recognized the significance of having a safe space to share inner conflicts and life challenges. This realization led me to embrace the role of a compassionate Peer Supporter. HeyJuni's platform has provided an invaluable opportunity for individuals like us to connect and extend support, irrespective of gender, age, or cultural differences. Your decision to reach out is deeply appreciated. Having navigated my own mental health experiences, I empathize with the need for non-judgmental support. Today, I'm committed to offering that same level of understanding and care to others. Whenever you feel the need to talk, please know that I'm here to listen. 🌟",
    area: 'Trauma and anxiety, peer support, stress, life changes, family issues, loneliness, school and education, youth’s and children’s mental health, relationship issues',
  },
  {
    id: 6,
    image: profile06,
    title: 'Introducing Bao',
    language: 'English, Vietnamese',
    description:
      "Hi there, I'm Bao, and I'm delighted to be your Peer Supporter today. I believe that mental wellness is crucial for a fulfilling life. By sharing our thoughts and feelings openly, we can improve our moods and find greater peace. In the past, I used to hold my feelings in due to fear of judgment. But as I grew, I've learned that talking about my feelings can really help, and I've indeed become happier ever since. Sometimes, all we need is an attentive ear. I'm here to offer a safe space, listen to your stories, and feel with you.",
    area: 'Trauma and anxiety, peer support, gender topics, stress, life changes, family issues, loneliness, grief, school and education, youth’s and children’s mental health, relationship issues, mindfulness practices',
  },
  {
    id: 7,
    image: profile07,
    title: 'Introducing Wen Xin',
    language: 'English',
    description: (
      <>
        <p>
          Hello, I’m Wen Xin! To me, mental health has always been everything.
          It touches all aspects of life: relationships, choices, interactions,
          and most importantly, our souls. It’s a part of us 24/7, from the
          moment we wake up until we sleep. It is essential to treat something
          so inescapable with proper attention and severity, similar to our
          physical health. So when things are too overwhelming, please reach
          out! I am a listener for that reason, to listen and provide support.
          Personally, I have had my experiences with anxiety and depression in
          my life. I know pain, despair and loneliness. These first-hand
          experiences help me to empathize, understand and relate to others that
          are hurting better.
        </p>
        <br />
        <p>
          I’m glad you have stumbled upon my bio, it means that you’re reaching
          out for help. It’s brave and the right thing to do :)
        </p>
      </>
    ),
    area: 'Depression, anxiety, peer support, school and career matters, stress, burnout, emotional immunity, bullying and exclusion, life changes, grief, children’s and youth’s mental health, addiction, relationship issues, family conflicts, loneliness, mindfulness practices',
  },
];

export function SupporterSwipe() {
  const [selectedSupporter, setSelectedSupporter] = useState<number | null>(
    null
  );

  return (
    <>
      <Swipe
        className="hidden md:block pb-[5px] md:pb-[10px]"
        currentStepList={Array(7).fill('')}
        cardWidth={656}
        gap={67}
        boundaryPadding={64}
      >
        {cards.map((card, index) => {
          const className =
            index === 0
              ? 'md:pr-[33.5px] md:pl-[64px]'
              : index === cards.length - 1
                ? 'md:pl-[33.5px] md:pr-[64px]'
                : 'md:px-[33.5px]';

          return (
            <Card
              name={card.name}
              description={card.description}
              avatar={card.avatar}
              background={card.background}
              className={className}
              handleClick={() => setSelectedSupporter(card.id)}
            />
          );
        })}
      </Swipe>
      <MobileSwipe dataList={cards}>
        {cards.map((card, index) => {
          const className = 'min-w-full flex justify-center';

          return (
            <Card
              name={card.name}
              description={card.description}
              avatar={card.avatar}
              background={card.background}
              className={className}
              handleClick={() => setSelectedSupporter(card.id)}
            />
          );
        })}
      </MobileSwipe>

      <PopUp
        isOpen={!!selectedSupporter}
        onClose={() => {
          setSelectedSupporter(null);
        }}
        className="rounded-[25px] w-[88%] md:w-[93.75%] h-[99.9%] md:h-[613px]"
        isGlobalOverlap={true}
      >
        <Profile
          handleClose={() => setSelectedSupporter(null)}
          profile={profiles.find((profile) => profile.id === selectedSupporter)}
          card={cards.find((card) => card.id === selectedSupporter)}
        />
      </PopUp>
    </>
  );
}

interface CardProps {
  name: string;
  description: string;
  avatar: string;
  background: string;
  className?: string;
  handleClick: () => void;
}

function Card(props: CardProps) {
  const { name, description, avatar, background, className, handleClick } =
    props;

  return (
    <div className={clsx(className)}>
      <div className="relative w-[80%] md:w-[656px] h-[460px] md:h-[420px] rounded-[20px] overflow-hidden shadow-[0px_4px_12px_rgba(0,0,0,0.2)] flex flex-col">
        <div className="absolute top-[7px] left-1/2 -translate-x-1/2">
          <img
            src={avatar}
            alt={name + 'avatar'}
            className="w-[123px] h-[123px] md:w-[197px] md:h-[197px]"
          />
        </div>
        <div>
          <img
            src={background}
            alt={name + 'background'}
            className="h-[91px] md:h-[124px] object-cover"
          />
        </div>

        <div className="md:px-[44px] mt-[65px] md:mt-[92px] text-center flex-1 flex flex-col justify-between">
          <div>
            <p className="font-extrabold text-[28px] leading-[36px] md:mb-[8px]">
              {name}
            </p>
            <p className="md:text-base md:leading-[24px] px-[20px] md:px-0">
              {description}
            </p>
          </div>

          <p
            className="md:text-[13px] text-[#A5A5A7] underline pb-[20px] md:pb-[24px] cursor-pointer select-none"
            onClick={handleClick}
          >
            View full profile
          </p>
        </div>
      </div>
    </div>
  );
}

interface ProfileProps {
  handleClose: () => void;
  profile:
    | {
        id: number;
        image: string;
        title: string;
        language: string;
        description: string | React.ReactNode;
        area: string;
      }
    | undefined;
  card: CardItem | undefined;
}

function Profile(props: ProfileProps) {
  const { handleClose, profile, card } = props;

  if (!profile) return null;

  return (
    <div
      className={`relative box-border md:w-full h-full md:h-[613px] p-[32px] md:py-[63px] md:px-[37px]`}
    >
      <div
        className="absolute left-0 top-0 w-full h-full bg-white"
        style={{ zIndex: -1 }}
      >
        <img
          src={profileBackground}
          alt=""
          className="hidden md:block absolute left-0 top-0 w-full object-cover"
        />
        <img
          src={card?.backgroundMobile}
          alt=""
          className="md:hidden absolute left-0 top-0 w-full object-cover"
        />
      </div>

      <div
        className="absolute right-[18px] top-[18px] md:right-[37px] top-[28px] w-[15px] h-[15px] md:w-auto md:h-auto cursor-pointer"
        onClick={() => {
          handleClose();
        }}
      >
        <img
          src={closeIcon}
          alt="Close Icon"
          className="w-[15px] h-[15px] object-contain"
        />
      </div>

      <div className="flex flex-col md:flex-row md:gap-[28px] h-full overflow-auto">
        <div className="flex flex-col items-center gap-[20px]">
          <div className="md:hidden pt-[20px] font-extrabold text-center text-[28px] leading-[36px]">
            {profile.title}
          </div>

          <img
            src={profile.image}
            alt=""
            className="w-[160px] h-[244px] md:w-auto md:h-auto"
          />
        </div>

        <div className="flex-1 mt-[20px] md:mt-0">
          <div className="hidden md:flex flex-row justify-between items-center md:mb-[32px]">
            <span className="font-bold md:text-4xl md:leading-[40px]">
              {profile.title}
            </span>
            <span className="md:text-lg">
              <span className="font-bold">Languages: </span>
              {profile.language}
            </span>
          </div>
          <div className="text-[13px] md:text-lg leading-[20px] md:leading-[26px]">
            <p>{profile.description}</p>
            <div className="mt-[20px] md:mt-[40px]">
              <p className="font-bold mb-[4px]">
                Areas of interest and proficiency:
              </p>
              <p>{profile.area}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
