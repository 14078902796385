import closeIcon from '@assets/icons/shared/close-icon.svg';

interface TermConditionContentProps {
  onClose: () => void;
}

export function TermConditionContent(props: TermConditionContentProps) {
  const { onClose } = props;

  return (
    <div className="relative p-[32px]">
      {/* <img
        src={closeIcon}
        alt="Close Icon"
        className="absolute right-0 top-0 md:right-[23px] md:top-[23px] cursor-pointer"
        onClick={onClose}
      /> */}

      <div
        className="absolute right-[23px] top-[23px] cursor-pointer w-[15px] h-[15px] md:w-auto md:h-auto flex items-center justify-center"
        onClick={onClose}
      >
        <img
          src={closeIcon}
          alt="Close Icon"
          className="w-full h-full object-contain"
        />
      </div>

      <h2 className="text-center font-bold text-xl mb-[16px] md:font-bold md:text-xl">
        Terms & Conditions
      </h2>
      <div className="text-sm space-y-3 text-gray-800 md:font-regular md:text-base">
        <p>
          (1) To protect your anonymity, avoid sharing personal information or
          using unofficial channels.
        </p>
        <p>
          (2) Should you feel uncomfortable during a session, end the
          conversation immediately and email us (
          <a
            href="mailto:contact@heyjuni.com"
            className="text-blue-600 underline"
          >
            contact@heyjuni.com
          </a>
          ).
        </p>
        <p>(3) You must be 16 years or older.</p>
        <p>
          (4) We do not offer crisis support. For emergencies like self-harm or
          suicidal thoughts, seek help here:{' '}
          <a
            href="https://en.wikipedia.org/wiki/List_of_suicide_crisis_lines"
            className="text-blue-600 underline break-words"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://en.wikipedia.org/wiki/List_of_suicide_crisis_lines
          </a>
          . Be sure to use the same email as the one you provided earlier in
          this Survey.
        </p>
      </div>
    </div>
  );
}
