import { trpc } from '@src/App';

export const getAccessToken = () => localStorage.getItem('accessToken');
export const getRefreshToken = () => localStorage.getItem('refreshToken');

export const setTokens = (accessToken: string, refreshToken: string) => {
  localStorage.setItem('accessToken', accessToken);
  localStorage.setItem('refreshToken', refreshToken);
};

// Check if access token is expired (simple approach, assuming JWT)
export const isAccessTokenExpired = (token: string) => {
  try {
    const payload = JSON.parse(atob(token.split('.')[1]));
    return payload.exp < Math.floor(Date.now() / 1000);
  } catch {
    return true;
  }
};

export const refreshAccessToken = async () => {
  const refreshToken = getRefreshToken();
  if (!refreshToken) return null;

  try {
    const refreshTokenLoginMutation =
      trpc.authentication.refreshTokenLogin.useMutation();
    const response = await refreshTokenLoginMutation.mutateAsync({
      refreshToken,
    });
    setTokens(response.accessToken, response.refreshToken);
    return response.accessToken;
  } catch (err) {
    console.error('Failed to refresh token:', err);
    return null;
  }
};
