import React, { useState } from 'react';
import AccordionItem from './AccordionItem';

export interface AccordionProps {
  items: {
    title: string;
    content: string | React.ReactNode;
  }[];
}

export default function Accordion({ items }: AccordionProps) {
  return (
    <div className="w-full mx-auto">
      {items.map((item, index) => (
        <AccordionItem key={index} title={item.title} content={item.content} />
      ))}
    </div>
  );
}
