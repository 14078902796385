import React, { useState } from 'react';
import image01 from '@assets/footages/landing/hero.svg';
import Button from '@components/Button';

import MoodIcon from '@assets/footages/landing/finding-stability.svg';
import SectionTitle from '@components/SectionTitle';
import { StateSlider } from './components/StateSlider';

import state1Icon from '@assets/footages/landing/finding-stability.svg';
import state2Icon from '@assets/footages/landing/building-resilience.svg';
import state3Icon from '@assets/footages/landing/managing-obstacles.svg';
import state4Icon from '@assets/footages/landing/focusing-on-recovery.svg';
import { PopUp } from '@components/Popup';
import { QuizContent } from './components/QuizContent';

// TODO: Padding and margin again

const states = [
  {
    icon: state1Icon,
    title: "I'm Feeling Okay",
    description:
      "You're feeling stable, but occasional emotional fluctuations may arise. Talking to peers, loved ones, or seeking external emotional support can help maintain your well-being and health.",
  },
  {
    icon: state2Icon,
    title: "I'm Feeling Mildly Distressed",
    description:
      " Emotional challenges are surfacing and you're learning to cope. Peer support can ease transitions and help manage these emerging feelings and build your resilience.",
  },
  {
    icon: state3Icon,
    title: "I'm Feeling Moderately Distressed",
    description:
      "You're facing deeper emotional challenges. Peer and Listening support, alongside therapy, can ease your stress, provide relief, and strengthen your long-term coping strategies.",
  },
  {
    icon: state4Icon,
    title: "I'm Feeling Profoundly Distressed",
    description:
      'You may feel significantly distressed or overwhelmed. Besides emotional and peer support, consider reaching out to a therapist or doctor for specialised help to regain balance and find relief.',
  },
];

export function QuizSection() {
  const [currentSection, setCurrentSection] = useState(0);

  const [isOpenQuiz, setIsOpenQuiz] = useState(false);

  return (
    <section className="text-center px-[24px] md:px-[76px] py-[20px] mb-[64px]">
      <p className="text-5xl md:text-7xl leading-[48px] md:leading-[65px] font-extrabold mb-[20px] md:mb-0">
        How are you feeling?
      </p>

      <p className="font-regular text-[15px] md:text-[22px] leading-[22px] md:leading-[26px] mt-[20px]">
        Explore this interactive scale to understand the different levels of
        anxiety and stress.
      </p>

      {/* Mood Box */}
      <div className="relative bg-white border border-gray-300 rounded-[15px] md:rounded-[40px] pt-[40px] px-[20px] md:p-[40px] md:pb-[70px] mx-auto mt-[40px] md:mt-[20px]">
        <img
          src={states[currentSection].icon}
          alt="Mood Icon"
          className="w-[120px] h-[120px] md:w-[180px] md:h-[180px] mx-auto mb-[30px] md:mb-[38px]"
        />

        <div className="h-[220px] md:h-auto flex flex-col justify-between md:block mb-[40px] md:mb-0">
          <div className="flex-1">
            <h3 className="text-xl md:text-5xl font-bold md:font-extrabold leading-[26px] md:leading-[45px]">
              {states[currentSection].title}
            </h3>
            <p className="mt-[10px] text-sm md:text-lg md:w-1/2 md:leading-[26px] md:font-regular mx-auto max-w-[440px] md:mb-[72px]">
              {states[currentSection].description}
            </p>
          </div>

          <StateSlider
            states={states}
            currentSection={currentSection}
            onChangeCurrentSection={setCurrentSection}
          />
        </div>
      </div>

      {/* Quiz Call to Action */}
      <p className="text-base md:text-4xl font-semibold md:font-bold leading-[24px] md:leading-[40px] mt-[40px] md:mt-[20px]">
        Find out how you’re feeling right now
      </p>

      <Button
        label="Start Quiz"
        onClick={() => setIsOpenQuiz(true)}
        className="w-[142px] md:w-[160px] h-[50px] md:h-[60px] text-lg md:text-2xl font-semibold rounded-[20px] md:rounded-[24px] mt-[20px]"
      />

      <PopUp
        isOpen={isOpenQuiz}
        onClose={() => setIsOpenQuiz(false)}
        className="md:rounded-[25px] w-full md:w-[88.05%] md:max-h-[95vh]"
        isGlobalOverlap={true}
      >
        <QuizContent handleClose={() => setIsOpenQuiz(false)} />
      </PopUp>
    </section>
  );
}
