import { openWhatsapp } from '@src/utils/open-whatsapp';
import whatsapp from '@assets/icons/shared/whatsapp.svg';
import React from 'react';

export default function WhatsAppButton() {
  return (
    <button
      onClick={openWhatsapp}
      className="mt-4 bg-[#23D366] hover:bg-green-600 text-white text-lg py-4 px-4 rounded-[24px] flex items-center shadow-lg transition duration-300 ease-in-out"
    >
      <img src={whatsapp} alt="whatsapp" className="h-6 mr-1" />
      Chat on WhatsApp
    </button>
  );
}
