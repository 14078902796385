import closeIcon from '@assets/icons/shared/close-icon.svg';
import PaperPlaneIcon from '@assets/icons/shared/paper-plane-icon.svg';
import Button from '@components/Button';
import { useBookingContext } from '../BookingContext';
import { BookingTimeType } from '@src/types/booking';

interface BookNowReadyProps {
  onClose: () => void;
}

export function BookNowReady(props: BookNowReadyProps) {
  const { onClose } = props;

  const { setBookingTimeType } = useBookingContext();

  return (
    <div className="relative">
      <div
        className="absolute right-[24px] top-[24px] md:right-[35px] md:top-[30px] cursor-pointer w-[15px] h-[15px] md:w-auto md:h-auto flex items-center justify-center"
        onClick={onClose}
      >
        <img
          src={closeIcon}
          alt="Close Icon"
          className="w-[15px] h-[15px] object-contain"
        />
      </div>

      {/* Popup content */}
      <div className="flex flex-col items-center justify-center w-full p-[30px] bg-white text-center space-y-[24px]">
        <div className="flex flex-col items-center">
          <img src={PaperPlaneIcon} alt="PaperPlaneIcon" />
          <div className="text-xl md:text-3xl leading-[26px] font-bold mt-[10px] md:mt-[20px]">
            Get Ready for Your Session
          </div>
        </div>

        <p className="text-[15px] md:text-lg">
          We’ll do our best to match you with a Peer Supporter within <br />
          <span className="font-semibold text-black underline">
            15 minutes
          </span>{' '}
          after completing this booking.
        </p>
        <p className="text-[15px] md:text-lg">
          Once your booking is confirmed, please keep an eye on your email for
          updates, <br />
          details about your Peer Supporter, and a link to join your session.
        </p>

        <Button
          label="Okay"
          className="h-[50px] rounded-[24px] md:rounded-[20px] px-[56px] md:px-[56px] font-semibold text-lg"
          onClick={() => {
            onClose();
            setBookingTimeType(BookingTimeType.BOOK_NOW);
          }}
        />
      </div>
    </div>
  );
}
