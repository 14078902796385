import Accordion from '@components/Accordion';
import React from 'react';

export default function HelpSeekersFAQ() {
  const items = [
    {
      title: 'Who is the person that will support me in a session?',
      content: (
        <>
          <p>
            Female Peer Supporters are the persons who will provide support to
            you.
          </p>
          <br />
          <p>
            The Peer Supporters at HeyJuni are a compassionate group of
            dedicated women trained to provide empathetic support and actively
            listen to individual needs. They are skilled in using cognitive
            behavioral techniques, mindfulness exercises, and other effective
            tools to assist those experiencing distress, trauma, or anxiety.
            Coming from diverse backgrounds - ranging from mental health
            professionals to advocates and individuals with lived experiences -
            they bring a variety of perspectives and understanding.
          </p>
          <br />
          <p>
            Each Peer Supporter undergoes rigorous screening, comprehensive
            training, and ongoing supervision by clinicians to ensure they
            deliver safe, understanding, and non-judgmental support. Their
            primary goal is to create a confidential and supportive environment
            where you can express yourself freely, receive personalized
            guidance, and feel accompanied in your healing process. They are
            there to take you by the hand, guiding you through coping strategies
            in real-time to help you manage your unique struggles.
          </p>
        </>
      ),
    },
    {
      title: 'What does a session look like? What can I expect?',
      content: (
        <>
          <p>
            During a session at HeyJuni, you can expect a supportive and
            non-judgmental environment designed to help you share your thoughts
            and feelings freely. Here's what you can anticipate:
          </p>
          <ol className="list-disc list-inside inline-block">
            <br />
            <li>
              <span className="font-bold">Personalized Matching:</span> You will
              be matched with a trained Peer Supporter who best suits your
              needs, ensuring you receive the appropriate support.
            </li>
            <br />
            <li>
              <span className="font-bold">Flexible Communication:</span>{' '}
              Sessions can be conducted through text, audio, or video, depending
              on your preference, allowing you to choose the most comfortable
              way to connect.
            </li>
            <br />
            <li>
              <span className="font-bold">Duration:</span> You will be matched
              with a trained Peer Supporter who best suits your needs, ensuring
              you receive the appropriate support.
            </li>
            <br />
            <li>
              <span className="font-bold">Open Sharing:</span> Within this time,
              you can express what's on your mind and explore how your Peer
              Supporter can assist you. You can set specific goals for the
              session, whether it's venting, seeking motivation, or discussing
              coping strategies.
            </li>
            <br />
            <li>
              <span className="font-bold">Confidentiality Assurance:</span> We
              prioritize your privacy. Everything shared during the session
              remains confidential.
            </li>
            <br />
            <li>
              <span className="font-bold">Adaptable Support:</span> Your Peer
              Supporter will check in with you throughout the session to ensure
              your needs are being met and adapt the conversation as necessary.
            </li>
          </ol>
        </>
      ),
    },
    {
      title: 'What are the different options of communication in a session?',
      content: (
        <>
          <p>
            It's always a 1-on-1 conversation, and depending on your preference,
            you can choose to communicate with a Peer Supporter via text, voice
            or video.
          </p>
        </>
      ),
    },
    {
      title: 'How does the matching process to a Peer Supporter work?',
      content: (
        <>
          <p>
            Our matching process is designed to address your unique needs. When
            you connect with a Peer Supporter, we take into account your
            emotions, social context, and cultural background. By combining
            technology with genuine empathy, we aim to create a personalized
            experience that aligns with your specific challenges and
            preferences. This ensures you receive the most suitable support
            tailored to you.
          </p>
        </>
      ),
    },
    {
      title: 'Will I talk to the same Peer Supporter every session?',
      content: (
        <>
          <p>
            It’s entirely your choice! You can opt to speak with different Peer
            Supporter for varied perspectives or request to connect with the
            same Peer Supporter for continuity and comfort.
          </p>
          <br />
          <p>
            To ensure your privacy and anonymity, please contact our customer
            service at{' '}
            <a href="mailto:anna@heyjuni.com" className="text-blue-800">
              anna@heyjuni.com
            </a>{' '}
            if you’d like to speak with a specific Peer Supporter.
          </p>
        </>
      ),
    },
    {
      title: 'How are Peer Supporter trained? How can I trust them?',
      content: (
        <>
          <p>
            Our Peer Supporter are rigorously trained and carefully vetted to
            ensure they provide the highest level of support:
          </p>
          <br />
          <ol className="list-disc list-inside inline-block">
            <li>
              <span className="font-bold">Thorough Vetting:</span> Each Peer
              Supporter undergoes a comprehensive selection process to ensure
              they meet our standards.
            </li>
            <li>
              <span className="font-bold">Accredited Training Program:</span>{' '}
              They are trained by certified trainers in our clinically vetted
              training program.
            </li>
            <li>
              <span className="font-bold">Regular Supervision:</span> Peer
              Supporter receive ongoing supervision from qualified clinicians to
              enhance their skills and ensure quality care.
            </li>
            <li>
              <span className="font-bold">Continuous Development:</span> They
              participate in ongoing advanced training sessions to stay updated
              on best practices in emotional support.
            </li>
            <li>
              <span className="font-bold">Understanding of Anonymity:</span> Our
              Peer Supporter are specifically trained in online support and the
              importance of maintaining user anonymity.
            </li>
            <li>
              <span className="font-bold">Data Privacy:</span> Peer Supporter do
              not have access to your personal data, ensuring your
              confidentiality.
            </li>
            <li>
              <span className="font-bold">Robust Security Measures:</span>{' '}
              Thanks to our strict policies and technical protections, you can
              trust that your information is safe with us.
            </li>
          </ol>
        </>
      ),
    },
    {
      title: 'How are Peer Supporters different from therapists?',
      content: (
        <>
          <p>Here are the key distinctions:</p>
          <ol className="list-disc list-inside inline-block">
            <br />
            <li>
              <span className="font-bold">Approach:</span> Peer Supporters
              engage in a more conversational, peer-based format, fostering an
              environment of equality and connection. This casual approach makes
              it easier to 'click' with someone and share your feelings without
              the pressure often associated with therapy.
            </li>
            <br />
            <li>
              <span className="font-bold">Accessibility:</span> Finding the
              right therapist can be a challenging and often frustrating
              experience. The process of scheduling, attending, and potentially
              switching therapists can take considerable time and effort. Our
              platform simplifies this journey by allowing you to explore a
              variety of Peer Supporters with minimal effort. You can easily try
              different Peer Supporters without the hassle of lengthy
              appointments, ensuring that your experience remains cost-effective
              and efficient.
            </li>
            <br />
            <li>
              <span className="font-bold">Flexibility:</span> You don't have to
              commit to scheduled appointments or long-term relationships, which
              can feel overwhelming. Our service is designed for on-demand
              support, allowing you to reach out whenever you need it,
              instantly. And if you want to connect with a Peer Supporter
              long-term, that’s in your power.
            </li>
            <br />
            <li>
              <span className="font-bold">Cost-Effectiveness:</span> Finding the
              right therapist can be expensive and time-consuming. Our platform
              reduces the financial burden by allowing you to explore various
              Peer Supporters without the high costs typically associated with
              traditional therapy.
            </li>
            <br />
            <li>
              <span className="font-bold">Techniques and Skills:</span> While we
              incorporate skills and techniques from therapeutic practices, our
              focus remains on connection, empathy, and building closeness
              rather than formal treatment.
            </li>
          </ol>
        </>
      ),
    },
    {
      title: 'How much does a session cost?',
      content: (
        <div>
          <p>
            We currently offer flexible options to access our peer support
            services:
          </p>
          <ol className="list-decimal list-inside inline-block">
            <li className="mb-4">
              <span className="font-bold">Single Session Option:</span>{' '}
              Pay-as-you-go at SG $29.90 per session, allowing you the freedom
              to book only when needed without any long-term commitment.
            </li>
            <li className="mb-4">
              <span className="font-bold">Monthly Package:</span> Enjoy 4
              sessions per month for only SG $49.90. This option offers
              significant savings compared to booking single sessions, providing
              better value and consistent support.
            </li>
            <li className="mb-4">
              <span className="font-bold">Yearly Package:</span> If you seek
              ongoing access, our annual plan is priced at SG $499.90. This
              package offers unlimited sessions and communication with a peer
              supporter throughout the year. It's the most cost-effective option
              for regular users, ensuring continuous support and peace of mind.
            </li>
          </ol>
          <p>
            We prioritize the quality of user experience by investing in
            extensive training and fair compensation for our Peer Supporters,
            Mentors and Clinicians, ensuring a supportive and uplifting
            environment. We maintain a strict commitment to user privacy by
            refraining from selling any user data, setting us apart from other
            "free" platforms. Instead of relying on intrusive ads to generate
            revenue, we focus on creating a seamless and ad-free platform to
            enhance user satisfaction.
          </p>
        </div>
      ),
    },
    {
      title: 'How long is a session?',
      content: (
        <>
          <p>Each session lasts for 50 minutes.</p>
        </>
      ),
    },
    {
      title:
        'How will you protect my anonymity and confidentiality on the platform and  in sessions?',
      content: (
        <>
          <p>
            At HeyJuni, your anonymity is our highest priority. We understand
            the importance of expressing yourself freely, which is why our
            platform is designed to ensure your confidentiality at every step.
          </p>
          <br />
          <p className="text-2xl font-bold">
            Key Measures for Protecting Your Anonymity
          </p>
          <ol className="list-decimal list-inside inline-block">
            <li className="mb-4">
              <span className="font-bold">
                No Personal Identifiable Information:
              </span>{' '}
              During the booking process, you are not required to provide any
              personally identifiable information, including your real name.
            </li>
            <li className="mb-4">
              <span className="font-bold">Data Encryption:</span> All
              information you share is encrypted using industry-standard
              protocols. This ensures that your data is securely stored and
              protected against unauthorized access.
            </li>
            <li className="mb-4">
              <span className="font-bold">Confidential Environment:</span> At
              HeyJuni, you can discuss your experiences without fear of
              judgment. No one will know who you are or what you talk about in
              your sessions. Our Peer Supporters follow strict protocols to
              ensure that all interactions remain private, so you can speak
              openly without fear of repercussions or exposure.
            </li>
            <li className="mb-4">
              <span className="font-bold">Self-Disclosure Guidelines:</span>{' '}
              Additionally, to safeguard your own anonymity, we recommend you to
              refrain from sharing details that could reveal your identity. This
              further safeguards your anonymity.
            </li>
          </ol>
        </>
      ),
    },
    {
      title: 'How should I prepare for a session?',
      content: (
        <>
          <p>
            Everyone is different, every situation is different but setting the
            right mindset and environment before talking to a Peer Supporter can
            enhance your experience.
          </p>
          <br />
          <p>Here are some tips:</p>
          <ol className="list-decimal list-inside inline-block">
            <li>
              <span className="font-bold">
                No Personal Identifiable Information:
              </span>{' '}
              Choose a comfortable, quiet environment where you can speak freely
              without distractions. This helps you express yourself openly.
            </li>
            <li>
              <span className="font-bold">Be Yourself:</span> Approach the
              session as you are. Authenticity fosters genuine connection.
            </li>
            <li>
              <span className="font-bold">Stay Open:</span> Be willing to share
              your thoughts and feelings. The more open you are, the more you
              can benefit from the session.
            </li>
            <li>
              <span className="font-bold">Know You're Safe:</span> Remember that
              you’re in a non-judgmental space designed for support.
            </li>
          </ol>
          <br />
          <p>
            Your feelings and experiences are valid, and confidentiality is
            paramount.
          </p>
        </>
      ),
    },
    {
      title: 'What if I have self-harm or suicidal ideations',
      content: (
        <>
          <p>
            HeyJuni does not support individuals experiencing active self-harm
            or suicidal ideations. We strongly urge you to contact your local
            crisis helpline or seek professional help immediately. Your safety
            is our top priority, and there are resources available to provide
            the support you need. If you're in crisis, please know that help is
            accessible. Reach out to trained professionals who can offer
            immediate assistance.
          </p>
        </>
      ),
    },
    {
      title: 'What issues can I address during a session?',
      content: (
        <>
          <p>
            At HeyJuni, we believe that every issue, big or small, deserves to
            be heard. Whether you're feeling slightly moody or experiencing
            profound distress, it's important to reach out. Users often share
            their experiences and feelings related to various aspects of sexual
            violence, including:
          </p>
          <br />
          <ol className="list-disc list-inside inline-block">
            <li>
              <span className="font-bold">Trauma and PTSD:</span> Discussing the
              lasting emotional impact of traumatic events.
            </li>
            <br />
            <li>
              <span className="font-bold">Anxiety and Depression:</span> Coping
              with anxiety, distress, depression, and feelings of hopelessness
              stemming from their experiences.
            </li>
            <br />
            <li>
              <span className="font-bold">Shame and Guilt:</span> Navigating
              complex emotions like shame, guilt, and self-blame.
            </li>
            <br />
            <li>
              <span className="font-bold">Isolation:</span> Feeling alone or
              disconnected from others after experiencing violence.
            </li>
            <br />
            <li>
              <span className="font-bold">Fear and Trust Issues:</span>{' '}
              Struggling with fear in relationships and difficulty trusting
              others.
            </li>
            <br />
            <li>
              <span className="font-bold">Family Dynamics:</span> Exploring how
              experiences of violence have affected relationships with family
              members.
            </li>
            <br />
            <li>
              <span className="font-bold">Perpetrator Impact:</span> Processing
              feelings related to the perpetrator and the influence they may
              have on one’s life.
            </li>
            <br />
            <li>
              <span className="font-bold">Sleeplessness:</span> Dealing with
              insomnia and disrupted sleep patterns as a result of emotional
              distress.
            </li>
            <br />
            <li>
              <span className="font-bold">Seeking Support:</span> The challenges
              of reaching out for help, including fears of judgment or stigma.
            </li>
            <br />
            <li>
              <span className="font-bold">Coping Mechanisms:</span> Exploring
              healthy coping strategies and ways to manage distressing feelings.
            </li>
            <br />
            <li>
              <span className="font-bold">Reclaiming Control:</span> Discussions
              about empowerment and regaining a sense of agency in their lives.
            </li>
            <br />
          </ol>
          <p>
            There are many other related topics stemming from sexual violence
            that our female users discuss, showcasing the many different
            challenges and emotions they navigate on their journey toward
            healing and empowerment.
          </p>
        </>
      ),
    },
    {
      title:
        'Is there a cancellation policy? What if I decide to back out on a session last minute?',
      content: (
        <>
          <p>
            If you need to cancel or reschedule your session, please contact us
            via:
          </p>
          <ol className="list-disc list-inside inline-block">
            <li>
              <span className="font-bold">Email:</span>{' '}
              <a href="mailto:anna@heyjuni.com" className="text-blue-800">
                anna@heyjuni.com
              </a>
            </li>
            <li>
              <span className="font-bold">WhatsApp:</span>{' '}
              <a
                href="https://wa.me/+6590894520"
                target="_blank"
                className="text-blue-800"
              >
                +65 9089 4520
              </a>
            </li>
          </ol>
          <br />
          <p>
            Our team is committed to assisting you and accommodating your needs
            as best as we can.
          </p>
        </>
      ),
    },
  ];

  return (
    <>
      <p className="text-5xl font-extrabold mb-10">Users</p>
      <Accordion items={items} />
    </>
  );
}
