import React from 'react';
import WomanSupport from '@assets/footages/become-a-listener/woman-support.svg';

import bgImg01 from '@assets/footages/become-a-listener/supporters-do-bg-01.svg';
import bgImg02 from '@assets/footages/become-a-listener/supporters-do-bg-02.svg';
import bgImg03 from '@assets/footages/become-a-listener/supporters-do-bg-03.svg';
import bgImg04 from '@assets/footages/become-a-listener/supporters-do-bg-04.svg';
import Button from '@components/Button';
import { openGoogleForm } from '@src/utils/open-googleform';

const cardData = [
  {
    src: bgImg01,
    title: 'Identifying Triggers and Signs',
    description:
      'Recognizing anxiety triggers and understanding emotions linked to trauma and anxiety.',
  },
  {
    src: bgImg02,
    title: 'Real-Time Support',
    description:
      'Assisting survivors in grounding anxiety and reducing negative feelings during sessions.',
  },
  {
    src: bgImg03,
    title: 'Self-Help Tools',
    description:
      'Equipping survivors with grounding and calming techniques they can use independently.',
  },
  {
    src: bgImg04,
    title: 'Safe, Open Conversations',
    description:
      'Providing a safe space for survivors to confide and to talk freely about their trauma.',
  },
  {
    src: bgImg02,
    title: 'Understanding & Coping',
    description:
      'Guiding survivors empathetically to process trauma from sexual assault.',
  },
  {
    src: bgImg01,
    title: 'Building Confidence',
    description:
      'Fostering positivity and empowerment for those feeling ashamed, lost, or isolated.',
  },
];

export function SupportersDoSection() {
  return (
    <div className="mb-[84px] md:mb-0 md:px-[76px] md:py-[40px] text-center">
      <div className="hidden md:block text-center mb-[28px] font-extrabold text-7xl leading-[65px]">
        What Peer Supporters Do
      </div>
      <div className="md:hidden mb-[20px] text-center px-[28px] font-extrabold text-5xl leading-[48px]">
        <div>What Peer</div>
        <div>Supporters Do</div>
      </div>

      <div className="px-[28px] md:p-0 grid grid-cols-1 md:grid-cols-2 gap-[20px] md:gap-[40px]">
        {cardData.map((data, index) => (
          <Card
            key={index}
            src={data.src}
            title={data.title}
            description={data.description}
          />
        ))}
      </div>

      <Button
        label="Supporting with HeyJuni"
        className="mt-[20px] md:mt-[40px] h-[50px] md:h-[60px] px-[30px] font-semibold text-lg md:text-2xl rounded-[20px]"
        onClick={openGoogleForm}
      />
    </div>
  );
}

interface CardProps {
  src: string;
  title: string;
  description: string;
}

function Card(props: CardProps) {
  const { src, title, description } = props;
  return (
    <div
      className={`relative px-[28px] py-[20px] md:p-[32px] min-h-[160px] md:min-h-[264px] overflow-hidden text-left`}
    >
      <div
        className={`absolute top-0 left-0 w-full h-full`}
        style={{ zIndex: -1 }}
      >
        <img src={src} alt={title} className="w-full h-full object-cover" />
      </div>
      <h3 className="mb-[20px] md:mb-[32px] font-bold text-xl md:text-4xl md:leading-[40px]">
        {title}
      </h3>
      <p className="text-[15px] md:text-2xl leading-[22px] md:leading-[32px]">
        {description}
      </p>
    </div>
  );
}
