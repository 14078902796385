import React, { useRef, useState } from 'react';
import clsx from 'clsx';
import SwirlStepBg from '@assets/footages/how-it-works/swirl-steps.svg';
import Button from '@components/Button';
import SectionTitle from '@components/SectionTitle';

import step01Img from '@assets/footages/how-it-works/how-it-works-1.svg';
import step02Img from '@assets/footages/how-it-works/how-it-works-2.svg';
import step03Img from '@assets/footages/how-it-works/how-it-works-3.svg';
import step04Img from '@assets/footages/how-it-works/how-it-works-4.svg';
import { useNavigate } from 'react-router-dom';
import { MobileSwipe } from '@components/MobileSwipe';

const steps = [
  {
    id: 1,
    src: step01Img,
    title: 'Personalize',
    description1: 'Provide information',
    description2: 'about your story',
    description3: 'anonymously.',
  },
  {
    id: 2,
    src: step02Img,
    title: 'Get Matched',
    description1: 'Connect with a Peer',
    description2: 'Supporter matched',
    description3: 'just for you.',
  },
  {
    id: 3,
    src: step03Img,
    title: 'Seek Support',
    description1: 'Be heard, cope with',
    description2: 'trauma in real-time via',
    description3: 'text, voice or video call.',
  },
  {
    id: 4,
    src: step04Img,
    title: 'Follow-up',
    description1: 'Be heard, cope with',
    description2: 'the same or new',
    description3: 'Peer Supporter.',
  },
];

function MobileCards() {
  return (
    <>
      {steps.map((step) => (
        <div
          key={step.id}
          className="min-w-full flex-shrink-0 flex flex-col items-center justify-center"
        >
          <img
            src={step.src}
            alt={`Step ${step.id}`}
            className="w-full h-auto object-cover mb-[30px]"
          />
          <h3 className="text-[28px] leading-[36px] font-extrabold p-[10px] mb-[10px]">
            {step.title}
          </h3>
          <p className="text-center text-lg">{step.description1}</p>
          <p className="text-center text-lg">{step.description2}</p>
          <p className="text-center text-lg">{step.description3}</p>
        </div>
      ))}
    </>
  );
}

export function WorkFlowSection() {
  const navigate = useNavigate();

  return (
    <div className="py-[20px] mb-[64px]">
      <div className="text-center">
        {/* TODO: Put this to common component */}
        <p className="text-5xl md:text-7xl leading-[48px] md:leading-[65px] font-extrabold mb-[24px] md:mb-0">
          How it works
        </p>
      </div>

      {/* Desktop view with the background and steps */}
      <div className="hidden md:block relative w-full pt-[94px] pb-[47px]">
        <img src={SwirlStepBg} alt="How it works" className="w-full h-auto" />

        <div className="absolute top-full transform translate-y-[40px] inset-0 flex justify-between items-center">
          {steps.map((step) => (
            <div key={step.id} className="flex flex-col items-center flex-1">
              <h3 className="text-4xl font-bold md:leading-[40px] pt-[14px]">
                {step.title}
              </h3>
              <p className="text-center font-regular md:leading-[26px] text-lg mt-[14px]">
                {step.description1}
              </p>
              <p className="text-center font-regular md:leading-[26px] text-lg">
                {step.description2}
              </p>
              <p className="text-center font-regular md:leading-[26px] text-lg">
                {step.description3}
              </p>
            </div>
          ))}
        </div>
      </div>

      {/* Mobile view with swipeable images */}
      <MobileSwipe dataList={steps}>
        <MobileCards />
      </MobileSwipe>

      <div className="mt-[42px] md:mt-[158px] text-center">
        <Button
          label="Book Session"
          className="h-[50px] w-[168px] md:h-[60px] md:w-[192px] text-lg md:text-2xl font-semibold rounded-[20px] md:rounded-2xl"
          onClick={() => navigate('/booking')}
        />
      </div>
    </div>
  );
}
