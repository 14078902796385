import React, { useState } from 'react';

export interface AccordionItemProps {
  title: string;
  content: string | React.ReactNode;
}

export default function AccordionItem({ title, content }: AccordionItemProps) {
  const parseNewLines = (text: string) => {
    return text.replace(/\n/g, '<br />');
  };

  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="w-full">
      {/* Line at the top */}
      <div className="border-t border-black w-full"></div>

      {/* Title section */}
      <button
        className="w-full py-5 flex justify-between items-center gap-[8px] text-left focus:outline-none font-bold text-xl md:text-4xl"
        onClick={() => setIsOpen((prev) => !prev)}
      >
        <div className="flex-1">{title}</div>

        {/* Animated Plus/Minus Icon */}
        <ExpandCollapseIcon isOpen={isOpen} />
      </button>

      {/* Content section */}
      {isOpen && (
        <div className="pb-5 text-[15px] md:text-lg">
          {typeof content === 'string' ? (
            <div dangerouslySetInnerHTML={{ __html: parseNewLines(content) }} />
          ) : (
            content
          )}
        </div>
      )}
    </div>
  );
}

interface ExpandCollapseIconProps {
  isOpen: boolean;
}

function ExpandCollapseIcon(props: ExpandCollapseIconProps) {
  const { isOpen } = props;

  return (
    <div className="relative w-[19px] h-[19px]">
      {/* Horizontal line */}
      <div
        className={`absolute top-1/2 left-0 w-full h-[2px] bg-black transition-transform duration-300 ${
          isOpen ? 'rotate-0' : 'rotate-0'
        }`}
      ></div>

      {/* Vertical line */}
      <div
        className={`absolute left-1/2 top-0 transform translate-x-[-1px] translate-y-[1px] w-[2px] h-full bg-black origin-bottom transition-transform duration-300 ${
          isOpen ? 'scale-y-0' : 'scale-y-1'
        }`}
      ></div>
    </div>
  );
}
