import React, { useState } from 'react';
import VimeoEmbedPortrait from '@components/VimeoEmbedPortrait';
import { PopUp } from '@components/Popup';

interface ExecutiveMemberProps {
  placeholderImage: string;
  videoId: string;
  name: string;
  title: string;
  subTitle: string;
  about: string;
}

export default function ExecutiveMember({
  placeholderImage,
  videoId,
  name,
  title,
  subTitle,
  about,
}: ExecutiveMemberProps) {
  const [isOpenVideo, setIsOpenVideo] = useState(false);

  return (
    <>
      <PopUp
        isOpen={isOpenVideo}
        onClose={() => {
          setIsOpenVideo(false);
        }}
        className="w-full !rounded-[0px] max-w-80"
        isGlobalOverlap={true}
      >
        <div className="w-full aspect-[9/16] relative overflow-hidden">
          <VimeoEmbedPortrait videoId={videoId} />
        </div>
      </PopUp>

      <div
        className="bg-gray-100 cursor-pointer"
        onClick={() => setIsOpenVideo(true)}
      >
        <img
          src={placeholderImage}
          alt={name}
          className="md:w-full object-cover"
        />
      </div>

      {/* Text content */}
      <div className="mt-[20px] md:mt-[40px]">
        <p className="text-[28px] md:text-5xl leading-[36px] md:leading-[45px] font-extrabold">
          {name}
        </p>
        <p className="mt-[5px] md:mt-[10px] text-xl md:text-4xl leading-[26px] md:leading-[40px] font-bold">
          {title}
        </p>
        <p className="mt-[5px] md:mt-[10px] text-base md:text-3xl leading-[20px] md:leading-[30px] font-semibold tracking-[-0.05em]">
          {subTitle}
        </p>

        <p className="mt-[20px] md:mt-[32px] text-[15px] md:text-lg leaing-[22px] md:leading-[26px]">
          {about}
        </p>
      </div>
    </>
  );
}

// TODO: Add responsive for mobile
