import React, { useContext } from 'react';

import { useBookingContext } from './BookingContext';

export function Step01() {
  const { address, setAddress, setCurrentStep } = useBookingContext();

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      setCurrentStep(2);
    }
  };

  return (
    <div>
      <h1 className="text-4xl md:text-5xl font-extrabold mb-[16px] md:mb-[24px]">
        Hello there,
      </h1>

      <p className="font-regular text-base md:text-lg mb-[16px] md:mb-[24px]">
        We offer around-the-clock, instant and anonymous support to help you
        navigate your trauma and anxieties, so you're never alone in facing your
        challenges.
      </p>
      <p className="font-regular text-base md:text-lg mb-[16px] md:mb-[24px]">
        To ensure our Peer Supporters can provide the best care, please take a
        moment to answer the following questions and book your session.
      </p>

      <div>
        <h2 className="text-base md:text-3xl font-semibold my-[16px] md:my-[24px]">
          Before we begin, how would you like to be addressed?
        </h2>
        <input
          type="text"
          placeholder="Enter Name"
          className="w-full border-b border-black focus:outline-none focus:border-black placeholder-gray-500 font-regular text-base md:text-lg"
          value={address}
          onChange={(e) => {
            setAddress(e.target.value);
          }}
          onKeyDown={handleKeyDown}
        />
      </div>
    </div>
  );
}
