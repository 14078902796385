import React, { useState } from 'react';

import BackgroundImage1 from '@assets/footages/landing/testimonial-card-1.svg';
import BackgroundImage2 from '@assets/footages/landing/testimonial-card-2.svg';
import BackgroundImage3 from '@assets/footages/landing/testimonial-card-3.svg';

import BackgroundImage1Mobile from '@assets/footages/landing/testimonial-card-1-mobile.svg';
import BackgroundImage2Mobile from '@assets/footages/landing/testimonial-card-2-mobile.svg';
import BackgroundImage3Mobile from '@assets/footages/landing/testimonial-card-3-mobile.svg';

import LeftArrow from '@assets/icons/shared/circle-arrow-left.svg';
import RightArrow from '@assets/icons/shared/circle-arrow-right.svg';

import QuoteIcon from '@assets/icons/shared/quote-icon.svg';

const testimonials = [
  {
    text: `"There are moments when all I crave is a listening ear and a space to release my troubles. HeyJuni came through for me by chance, offering the support I truly needed. What made it even better was the anonymity they provided. Being completely anonymous allowed me to feel secure in being my authentic self and freely share my story. It was a relief to have a safe outlet where I could express myself without holding back or pretending."`,
    author: 'R, 25 years old',
    backgroundImage: BackgroundImage1,
    backgroundImageMobile: BackgroundImage1Mobile,
  },
  {
    text: `"It feels good to have someone I can talk to. In my culture, it’s not accepted to speak about deep emotional issues. Even with my parents or friends, it’s difficult for me to open up. On a daily basis, I can share 'usual' topics like school or friends with my parents, but when it comes to deeper mental health matters, I can’t talk to them at all. I’ve tried before, but it never works. So, I’m grateful to have a Peer Supporter I can talk to. It just feels good to let it out sometimes, without having to 'walk on eggshells' about what I say or share. I’m truly able to release the emotional baggage in my mind with HeyJuni."`,
    author: 'M, 16 years old',
    backgroundImage: BackgroundImage3,
    backgroundImageMobile: BackgroundImage3Mobile,
  },
  {
    text: `"I had to stop therapy because of financial issues, even though I'm still struggling with depression. Sometimes, talking to a therapist felt overwhelming, like I was obligated to share my entire life story. But now, I don’t really have anyone I can truly open up to. Finding HeyJuni felt like a bit of luck in a dark time. I’m grateful for the support they offer. It's a completely anonymous space where I can let go of my emotional pain whenever I need to, without fear or judgment."`,
    author: 'M, 18 years old',
    backgroundImage: BackgroundImage2,
    backgroundImageMobile: BackgroundImage2Mobile,
  },
  {
    text: `"I feel quite lonely since moving to Singapore. I've been diagnosed with depression, and since I don't have friends here, it is even more challenging for me to socialize and get out of my comfort zone. Talking remotely to my family is difficult because I don't want to burden them or make them feel worried. I have been talking to my dedicated Peer Supporter several times and it really helps me feel less lonely and able to sort my thoughts and emotional struggles."`,
    author: 'S, 18 years old',
    backgroundImage: BackgroundImage1,
    backgroundImageMobile: BackgroundImage1Mobile,
  },
  {
    text: `Talking to HeyJuni's Peer Supporters has helped me feel understood. I connected with a Peer Supporter who truly grasped my struggles, which made me feel less troubled. Dealing with anxiety and depression is tough, but the real challenge is the neglect and rejection I face from my family. Having HeyJuni's support system as a coping mechanism for my daily stress and issues gives me hope. I genuinely appreciate their authenticity and empathy.`,
    author: 'J, 27 years old',
    backgroundImage: BackgroundImage3,
    backgroundImageMobile: BackgroundImage3Mobile,
  },
  {
    text: `"As a young person who relies financially on my parents, finding therapy resources is a challenge. And I don't want to rely on my parents' financial support. That’s why taking up HeyJuni's service was such a relief. Surprisingly, even though it was my first time using this service, I didn’t feel hesitant about sharing everything with the Peer Supporter. I connected deeply with them, and it’s relieving to know I can openly share without fear of judgment and worries about whether I could afford another session or not. Thanks a lot for the experience."`,
    author: 'A, 25 years old',
    backgroundImage: BackgroundImage2,
    backgroundImageMobile: BackgroundImage2Mobile,
  },
  {
    text: `"Finding a Peer Supporter I can deeply connect with and rely on during lonely moments, especially at night when my negative thoughts kick in, brings immense comfort. Knowing that I don’t have to wait days to talk about my issues is a big plus for me. Despite my Peer Supporter being a complete stranger, a bond has somehow formed between us. The connection I felt was profound and reassuring. I used to feel quite lonely, especially when facing hardships, but talking to HeyJuni's Peer Supporters has given me not only comfort but also the opportunity to reflect on myself."`,
    author: 'P, 30 years old',
    backgroundImage: BackgroundImage3,
    backgroundImageMobile: BackgroundImage3Mobile,
  },
];

export function TestimonialsSection() {
  const [currentIndex, setCurrentIndex] = useState(0);

  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % testimonials.length);
  };

  const handlePrev = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? testimonials.length - 1 : prevIndex - 1
    );
  };

  const currentTestimonial = testimonials[currentIndex];

  // TODO: Fix height

  return (
    <section className="relative text-center py-[20px] mb-[64px]">
      <p className="text-5xl md:text-7xl leading-[48px] md:leading-[65px] font-extrabold mb-[40px] md:mb-0">
        Our Testimonials
      </p>

      <div className="relative md:mt-[20px]">
        <div className="">
          <img
            src={currentTestimonial.backgroundImage}
            alt="Testimonial Background"
            className="hidden md:block w-full h-auto object-cover"
          />
          <img
            src={currentTestimonial.backgroundImageMobile}
            alt="Testimonial Background"
            className="md:hidden w-full h-auto object-cover"
          />

          <div className="absolute inset-0 flex flex-col justify-start mt-[31px] px-[27px] md:mt-[39px] md:px-[66px]">
            <img
              src={QuoteIcon}
              alt="Quote Icon"
              className="w-[42px] h-[42px] md:w-[150px] md:h-[150px]"
            />
            <p className="text-left text-sm md:text-2xl font-regular md:leading-[32px] mt-[25px] md:mt-[52px] max-w-[1012px]">
              {currentTestimonial.text}
            </p>

            <p className="font-extrabold text-4xl md:text-5xl text-left mt-[20px] md:mt-[50px]">
              {currentTestimonial.author}
            </p>
            <div className="absolute bottom-[28px] md:bottom-[52px] left-[28px] md:left-[66px]">
              <button onClick={handlePrev}>
                <img
                  src={LeftArrow}
                  alt="Previous"
                  className="w-[42px] h-[42px]"
                />
              </button>
            </div>
            <div className="absolute bottom-[28px] md:bottom-[52px] right-[28px] md:right-[66px]">
              <button onClick={handleNext}>
                <img
                  src={RightArrow}
                  alt="Next"
                  className="w-[42px] h-[42px]"
                />
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
