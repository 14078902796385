import React from 'react';
import peopleImage from '@assets/footages/learn-more/people.svg';
import Button from '@components/Button';
import GeneralFAQ from '@components/FAQs/GeneralFAQ';
import Accordion from '@components/Accordion';
import { openGoogleForm } from '@src/utils/open-googleform';

// TODO: Define some common class to use global

export default function JoinUsSection() {
  return (
    <section className="relative bg-black py-[50px] md:py-[80px] overflow-hidden">
      <div className="text-5xl leading-[48px] md:text-7xl font-extrabold md:leading-[65px] text-white text-center md:w-1/2 mx-auto">
        <p>Join us and</p>
        <p>make an impact</p>
      </div>

      <div className="text-center mt-[20px]">
        <Button
          label="Become a Peer Supporter"
          className="h-[50px] rounded-[20px] md:h-[60px] md:rounded-[24px] font-semibold text-lg md:text-2xl !px-[30px]"
          onClick={openGoogleForm}
          // TODO: Put this className in css file
        />
      </div>
    </section>
  );
}
