import { BookingTimeType } from '@src/types/booking';
import { TimeOption } from '@src/types/common';
import { getClientTimeZoneOffset } from '@src/utils/time';
import React, {
  createContext,
  useState,
  useEffect,
  useContext,
  ReactNode,
} from 'react';
import { useLocation } from 'react-router-dom';

// TODO: Refactor this
/**
 * interface BookingContextState {}
 * interface BookingContextActions {}
 * interface BookingContextProps {
  state: BookingContextState;
  actions: BookingContextActions;
}

 */

interface BookingContextProps {
  currentStep: number;
  setCurrentStep: (value: number) => void;

  address: string;
  setAddress: (value: string) => void;
  anxiousReason: string;
  setAnxiousReason: (value: string) => void;
  status: number;
  setStatus: (value: number) => void;
  email: string;
  setEmail: (value: string) => void;
  isChecked: boolean;
  setIsChecked: (value: boolean) => void;
  age: string;
  setAge: (value: string) => void;
  gender: string;
  setGender: (value: string) => void;

  // Save data of single or multiple choices
  dataList03: string[];
  setDataList03: (value: string[]) => void;
  dataList04: string[];
  setDataList04: (value: string[]) => void;
  dataList06: string[];
  setDataList06: (value: string[]) => void;
  dataList07: string[];
  setDataList07: (value: string[]) => void;
  dataList09: string[];
  setDataList09: (value: string[]) => void;

  // Time
  bookingTimeType: BookingTimeType | null;
  setBookingTimeType: (value: BookingTimeType | null) => void;
  selectedTimezone: string | null;
  setSelectedTimezone: (value: string | null) => void;
  selectedDate: Date | null;
  setSelectedDate: (value: Date | null) => void;
  startTime: TimeOption | null;
  setStartTime: (value: TimeOption | null) => void;
}

const BookingContext = createContext<BookingContextProps | undefined>(
  undefined
);

export function BookingProvider({ children }: { children: ReactNode }) {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const [currentStep, setCurrentStep] = useState<number>(
    queryParams.get('page') === 'success' ? 13 : 1
  );

  const [address, setAddress] = useState<string>('');
  const [anxiousReason, setAnxiousReason] = useState<string>('');
  const [status, setStatus] = useState<number>(0);
  const [email, setEmail] = useState('');
  const [isChecked, setIsChecked] = useState(false);
  const [age, setAge] = useState('Select Category');
  const [gender, setGender] = useState('Select Category');

  const [dataList03, setDataList03] = useState<string[]>([]);
  const [dataList04, setDataList04] = useState<string[]>([]);
  const [dataList06, setDataList06] = useState<string[]>([]);
  const [dataList07, setDataList07] = useState<string[]>([]);
  const [dataList09, setDataList09] = useState<string[]>([]);

  // time
  const [bookingTimeType, setBookingTimeType] =
    useState<BookingTimeType | null>(null);
  const [selectedTimezone, setSelectedTimezone] = useState<string | null>(
    getClientTimeZoneOffset()
  );
  const [selectedDate, setSelectedDate] = useState<Date | null>(null);
  const [startTime, setStartTime] = useState<TimeOption | null>(null);

  // Detect query parameter changes and update currentStep
  useEffect(() => {
    const pageParam = queryParams.get('page');
    if (pageParam === 'success') {
      setCurrentStep(13);
    } else {
      // setCurrentStep(1);
    }
  }, [location.search]); // Trigger when URL search parameters change

  return (
    <BookingContext.Provider
      value={{
        currentStep,
        setCurrentStep,
        address,
        setAddress,
        anxiousReason,
        setAnxiousReason,
        dataList03,
        setDataList03,
        dataList04,
        setDataList04,
        dataList06,
        setDataList06,
        dataList07,
        setDataList07,
        dataList09,
        setDataList09,
        status,
        setStatus,

        email,
        setEmail,
        isChecked,
        setIsChecked,

        age,
        setAge,
        gender,
        setGender,

        bookingTimeType,
        setBookingTimeType,
        selectedTimezone,
        setSelectedTimezone,
        selectedDate,
        setSelectedDate,
        startTime,
        setStartTime,
      }}
    >
      {children}
    </BookingContext.Provider>
  );
}

// Create a custom hook to use the BookingContext
export function useBookingContext() {
  const context = useContext(BookingContext);
  if (!context) {
    throw new Error('useBookingContext must be used within a BookingProvider');
  }
  return context;
}
