import React, { useRef, useEffect, useState } from 'react';
import clsx from 'clsx';

interface SwipeProps {
  children: React.ReactNode;
  className?: string;
  currentStepList: string[];
  cardWidth: number;
  gap: number;
  boundaryPadding: number;
}

export function Swipe(props: SwipeProps) {
  const {
    currentStepList,
    children,
    cardWidth,
    gap,
    boundaryPadding,
    className,
  } = props;

  const [currentStep, setCurrentStep] = useState(0); // Track the current step index
  const delta = cardWidth + 10;
  const widthToSwipe =
    cardWidth * currentStepList.length +
    gap * (currentStepList.length - 1) +
    2 * boundaryPadding -
    window.innerWidth +
    delta;

  const startX = useRef<number | null>(null);
  const currentTranslateX = useRef(0);
  const containerRef = useRef<HTMLDivElement>(null);
  const innerContainerRef = useRef<HTMLDivElement>(null);
  const totalWidthRef = useRef<number>(0);
  const containerWidthRef = useRef<number>(0);

  const startX0 = useRef<number | null>(null);
  const startTimeRef = useRef<number | null>(null);

  useEffect(() => {
    if (containerRef.current) {
      containerWidthRef.current = containerRef.current.offsetWidth;
      totalWidthRef.current = innerContainerRef.current?.scrollWidth || 0;
    }
  }, []);

  const handleTouchStart = (e: React.TouchEvent<HTMLDivElement>) => {
    startX0.current = e.touches[0].clientX;
    startX.current = e.touches[0].clientX;
    startTimeRef.current = e.timeStamp;
    if (innerContainerRef.current) {
      innerContainerRef.current.style.transition = 'none';
    }
  };

  const handleMouseDown = (e: React.MouseEvent<HTMLDivElement>) => {
    e.preventDefault();

    startX0.current = e.clientX;
    startX.current = e.clientX;
    startTimeRef.current = e.timeStamp;
    if (innerContainerRef.current) {
      innerContainerRef.current.style.transition = 'none';
    }
    // Add a mousemove event listener to handle dragging
    window.addEventListener('mousemove', handleMouseMove);
    window.addEventListener('mouseup', handleMouseUp);
  };

  const handleTouchMove = (e: React.TouchEvent<HTMLDivElement>) => {
    if (startX.current === null) return;
    const currentX = e.touches[0].clientX;
    updateTranslation(currentX);
  };

  const handleMouseMove = (e: MouseEvent) => {
    e.preventDefault(); // Prevent default browser behavior

    if (startX.current === null) return;
    const currentX = e.clientX;
    updateTranslation(currentX);
  };

  const MIN_MOVE_THRESHOLD = 10; // Minimum movement to be recognized as valid
  const updateTranslation = (currentX: number) => {
    const diff = currentX - startX.current!;
    if (Math.abs(diff) < MIN_MOVE_THRESHOLD) return;

    let newCurrentTranslateX = currentTranslateX.current + diff;

    // Try to break left limit
    if (newCurrentTranslateX > 0) {
      newCurrentTranslateX = 0;
    }

    // Try to break right limit
    if (
      newCurrentTranslateX <
      -(totalWidthRef.current - containerWidthRef.current)
    ) {
      newCurrentTranslateX = -(
        totalWidthRef.current - containerWidthRef.current
      );
    }

    if (innerContainerRef.current) {
      innerContainerRef.current.style.transform = `translateX(${newCurrentTranslateX}px)`;
    }

    currentTranslateX.current = newCurrentTranslateX;
    startX.current = currentX;

    const newStep = Math.abs(
      Math.floor((newCurrentTranslateX / widthToSwipe) * currentStepList.length)
    );
    setCurrentStep(newStep);
  };

  const handleTouchEnd = (e: React.TouchEvent<HTMLDivElement>) => {
    endInteraction(e.changedTouches[0].clientX, e.timeStamp);
  };

  const handleMouseUp = (e: MouseEvent) => {
    e.preventDefault();

    endInteraction(e.clientX, e.timeStamp);
    window.removeEventListener('mousemove', handleMouseMove);
    window.removeEventListener('mouseup', handleMouseUp);
  };

  const endInteraction = (endX: number, timeStamp: number) => {
    if (
      startX.current === null ||
      startTimeRef.current === null ||
      startX0.current === null
    )
      return;

    const timeTaken = timeStamp - startTimeRef.current;
    const distance = endX - startX0.current;
    const speed = Math.abs(distance / timeTaken);

    // Adjust these thresholds to reduce sensitivity
    const flickThreshold = 1.0; // Increase speed requirement for flick
    const distanceThreshold = 100; // Increase minimum swipe distance for flick
    const timeThreshold = 150; // Maximum time (in ms) allowed for a flick

    let additionalTranslate = 0;

    if (
      speed > flickThreshold &&
      Math.abs(distance) > distanceThreshold &&
      timeTaken < timeThreshold
    ) {
      additionalTranslate = distance * 10; // Apply multiplier if all conditions met
    }

    currentTranslateX.current += additionalTranslate;

    if (currentTranslateX.current > 0) currentTranslateX.current = 0;
    if (
      currentTranslateX.current <
      -(totalWidthRef.current - containerWidthRef.current)
    ) {
      currentTranslateX.current = -(
        totalWidthRef.current - containerWidthRef.current
      );
    }

    if (innerContainerRef.current) {
      innerContainerRef.current.style.transition = 'transform 0.3s ease';
      innerContainerRef.current.style.transform = `translateX(${currentTranslateX.current}px)`;
    }

    startX.current = null;
    startTimeRef.current = null;

    const newStep = Math.abs(
      Math.floor(
        (currentTranslateX.current / widthToSwipe) * currentStepList.length
      )
    );
    setCurrentStep(newStep);
  };

  return (
    <>
      <div
        ref={containerRef}
        className={clsx(
          `w-full flex items-center overflow-hidden relative`,
          className
        )}
        onTouchStart={handleTouchStart}
        onTouchMove={handleTouchMove}
        onTouchEnd={handleTouchEnd}
        onMouseDown={handleMouseDown} // Add mouse down event for desktop
      >
        <div
          ref={innerContainerRef}
          className="flex"
          style={{
            transform: `translateX(${currentTranslateX.current}px)`,
          }}
        >
          {children}
        </div>
      </div>
      <div className="hidden md:flex justify-center md:mt-[10px]">
        {currentStepList?.[0] !== '' ? (
          <img
            src={
              currentStepList[Math.min(currentStep, currentStepList.length - 1)]
            }
            alt={`Step ${currentStep + 1}`}
            className="hidden md:block"
          />
        ) : (
          <div className="flex justify-center space-x-2">
            {currentStepList.map((_, idx) => (
              <span
                key={idx}
                className={clsx('w-2 h-2 rounded-full', {
                  'bg-gray-300':
                    idx !== Math.min(currentStep, currentStepList.length - 1),
                  'bg-purple-500':
                    idx === Math.min(currentStep, currentStepList.length - 1),
                })}
              ></span>
            ))}
          </div>
        )}
      </div>
    </>
  );
}
