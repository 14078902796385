import { z } from 'zod';

export const PagingSchema = z.object({
    pageSize: z.number(),
    totalItems: z.number(),
    totalPages: z.number(),
});
export type Paging = z.infer<typeof PagingSchema>;

export const PagingInputSchema = z.object({
    page: z.number(),
});
export type PagingInput = z.infer<typeof PagingInputSchema>;
