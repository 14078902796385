import { ReactNode, useState } from 'react';
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
  Outlet,
} from 'react-router-dom';
import { httpBatchLink } from '@trpc/client';
import type { TRPCRouter } from '@backend/src/trpcRouter';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { createTRPCReact } from '@trpc/react-query';
import Header from '@components/Header';
import Footer from '@components/Footer';
import Home from '@src/pages/Home';
import MeetTheTeam from '@src/pages/MeetTheTeam';
import MeetTheTeam_V0 from '@src/pages/MeetTheTeam/index_v0';
import MissionAndVision from '@src/pages/MissionAndVision';
import Blog from '@src/pages/Blog';
import Pricing from '@src/pages/Pricing';
import BecomeAListener from '@src/pages/BecomeAListener';
import ContactUs from '@src/pages/ContactUs';
import CrisisHelpline from '@src/pages/CrisisHelpline';
import FAQ from '@src/pages/FAQ';
import PrivacyPolicy from '@src/pages/PrivacyPolicy';
import TermsAndConditions from '@src/pages/TermsAndConditions';
import NotFound from '@src/pages/NotFound';
import Booking from '@src/pages/Booking';
import LearnMore from './pages/LearnMore';
import MainLayout from './layouts/MainLayout';
import {
  getAccessToken,
  isAccessTokenExpired,
  refreshAccessToken,
} from './utils/auth';
import { Snackbar } from '@components/Snackbar';

const BACKEND_URL = 'https://juniweb2-0.onrender.com/api';
export const trpc = createTRPCReact<TRPCRouter>();

interface ProtectedRouteProps {
  children: ReactNode;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(true);
  if (!isAuthenticated) {
    return <Navigate to="/login" replace />;
  }

  return children;
};

// Layout Component for Unprotected Routes
const UnprotectedLayout = () => (
  <>
    <Header />
    <MainLayout>
      <Outlet />
    </MainLayout>
    <Footer />
  </>
);

function App() {
  const [showErrorSnackbar, setShowErrorSnackbar] = useState(false);
  const [errorSnackBarMessage, setErrorSnackbarMessage] = useState<string>('');

  function showError(err: Error) {
    setErrorSnackbarMessage(err.message);
    setShowErrorSnackbar(true);
  }

  const [queryClient] = useState(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            onError: (err) => showError(err as Error),
            useErrorBoundary: false,
          },
          mutations: {
            onError: (err) => showError(err as Error),
            useErrorBoundary: false,
          },
        },
      })
  );

  const [trpcClient] = useState(() =>
    trpc.createClient({
      links: [
        httpBatchLink({
          url: BACKEND_URL,
          headers: async () => {
            let accessToken = getAccessToken();
            if (accessToken && isAccessTokenExpired(accessToken)) {
              accessToken = await refreshAccessToken();
            }
            console.log('accessToken:', accessToken);
            return {
              ...(accessToken
                ? { Authorization: `Bearer ${accessToken}` }
                : {}),
            };
          },
        }),
      ],
    })
  );

  return (
    <div className="text-[#181818]">
      <trpc.Provider client={trpcClient} queryClient={queryClient}>
        <Snackbar
          message={errorSnackBarMessage}
          onClose={() => {
            setShowErrorSnackbar(false);
            setErrorSnackbarMessage('');
          }}
          show={showErrorSnackbar}
        />
        <QueryClientProvider client={queryClient}>
          <Router>
            <Routes>
              {/* Unprotected Routes */}
              <Route element={<UnprotectedLayout />}>
                <Route path="/" element={<Home />} />
                <Route path="team-juni" element={<MeetTheTeam />} />
                <Route path="team-juni-0" element={<MeetTheTeam_V0 />} />
                <Route
                  path="mission-and-vision"
                  element={<MissionAndVision />}
                />
                <Route path="blog" element={<Blog />} />
                <Route path="pricing" element={<Pricing />} />
                <Route path="become-a-listener" element={<BecomeAListener />} />
                <Route path="contact-us" element={<ContactUs />} />
                <Route path="crisis-helpline" element={<CrisisHelpline />} />
                <Route path="faq" element={<FAQ />} />
                <Route path="privacy-policy" element={<PrivacyPolicy />} />
                <Route
                  path="terms-and-conditions"
                  element={<TermsAndConditions />}
                />
                <Route path="booking" element={<Booking />} />
                <Route path="learn-more" element={<LearnMore />} />
                <Route path="*" element={<NotFound />} />
              </Route>
            </Routes>
          </Router>
        </QueryClientProvider>
      </trpc.Provider>
    </div>
  );
}

export default App;
