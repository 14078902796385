import z from 'zod';
import { BookingFormDataSchema } from './createBookingRequest';
import { MeetingDetailsSchema } from './bookingRequest';

export const AssignVolunteerToBookingRequestInputSchema = z.object({
    bookingRequestId: z.number(),
    volunteerUserId: z.number().nullable(),
});
export type AssignVolunteerToBookingRequestInput = z.infer<
    typeof AssignVolunteerToBookingRequestInputSchema
>;

export const AssignedBookingRequestSchema = z.object({
    id: z.number(),
    preferredDateTime: z.coerce.date(),
    meetingDetails: MeetingDetailsSchema,
    formData: BookingFormDataSchema,
    doneAt: z.coerce.date().nullable(),
    cancelledAt: z.coerce.date().nullable(),
});
export type AssignedBookingRequest = z.infer<typeof AssignedBookingRequestSchema>;

export const AssignedBookingRequestsSchema = z.array(AssignedBookingRequestSchema);
export type AssignedBookingRequests = z.infer<typeof AssignedBookingRequestsSchema>;

export const GetAssignedBookingRequestInputSchema = z.object({
    bookingRequestId: z.number(),
});
export type GetAssignedBookingRequestInput = z.infer<typeof GetAssignedBookingRequestInputSchema>;
