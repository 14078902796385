import React, { useRef, useEffect } from 'react';

import healing01Img from '@assets/footages/learn-more/healing01.svg';
import healing02Img from '@assets/footages/learn-more/healing02.svg';
import healing03Img from '@assets/footages/learn-more/healing03.svg';
import healing04Img from '@assets/footages/learn-more/healing04.svg';
import healing05Img from '@assets/footages/learn-more/healing05.svg';

// TODO: Import svg instead later
import healing01ImgMobile from '@assets/footages/learn-more/healing01-mobile.png';
import healing02ImgMobile from '@assets/footages/learn-more/healing02-mobile.png';
import healing03ImgMobile from '@assets/footages/learn-more/healing03-mobile.png';
import healing04ImgMobile from '@assets/footages/learn-more/healing04-mobile.png';
import healing05ImgMobile from '@assets/footages/learn-more/healing05-mobile.png';

import healing01StepImg from '@assets/footages/learn-more/healing-step01.svg';
import healing02StepImg from '@assets/footages/learn-more/healing-step02.svg';
import healing03StepImg from '@assets/footages/learn-more/healing-step03.svg';
import healing04StepImg from '@assets/footages/learn-more/healing-step04.svg';
import healing05StepImg from '@assets/footages/learn-more/healing-step05.svg';

import healing01StepImgMobile from '@assets/footages/learn-more/healing-step01-mobile.svg';
import healing02StepImgMobile from '@assets/footages/learn-more/healing-step02-mobile.svg';
import healing03StepImgMobile from '@assets/footages/learn-more/healing-step03-mobile.svg';
import healing04StepImgMobile from '@assets/footages/learn-more/healing-step04-mobile.svg';
import healing05StepImgMobile from '@assets/footages/learn-more/healing-step05-mobile.svg';

import clsx from 'clsx';
import { Swipe } from '@components/Swipe';
import { MobileSwipe } from '@components/MobileSwipe';

const cards = [
  {
    title: 'What is Sexual Violence',
    subTitle: 'What was your experience with Sexual Violence?',
    description:
      'Sexual violence can range from verbal harassment and unwanted advances to physical assault and rape. Often dismissed as "jokes" or "flirting," every act of sexual violation, no matter how minor it seems, leaves a lasting impact.',
    image: healing01Img,
    imageMobile: healing01ImgMobile,
    stepImage: healing01StepImg,
    stepImageMobile: healing01StepImgMobile,
  },
  {
    title: 'Aftermath: Trauma & Anxiety',
    subTitle: 'What Is The Impact of Sexual Violence?',
    description:
      'Experiencing sexual violence can trigger deep emotional scars and feelings of shame and fear. This can lead to acute and chronic anxiety, PTSD, and long term trauma.',
    image: healing02Img,
    imageMobile: healing02ImgMobile,
    stepImage: healing02StepImg,
    stepImageMobile: healing02StepImgMobile,
  },
  {
    title: 'Signs and Symptoms',
    subTitle: 'Are you experiencing these symptoms?',
    description:
      'Symptoms of trauma and anxiety caused by sexual violence could be fear, feelings of shame, self-blame, panic attacks, or insomnia. Do you feel on edge, struggle with sleep, or find it hard to trust others?',
    image: healing03Img,
    imageMobile: healing03ImgMobile,
    stepImage: healing03StepImg,
    stepImageMobile: healing03StepImgMobile,
  },
  {
    title: 'Talking to find Relief',
    subTitle: 'Have you thought about talking to ease the pain?',
    description:
      'Sharing experiences and feelings can be the first step towards healing. Being in an anonymous and safe space, and having someone who listens without judgment contributes to healing and reducing anxiety.',
    image: healing04Img,
    imageMobile: healing04ImgMobile,
    stepImage: healing04StepImg,
    stepImageMobile: healing04StepImgMobile,
  },
  {
    title: 'Find a Safe Place',
    subTitle: "We're Here for You whenever you’re ready.",
    description:
      "When you’re ready to talk, we're ready to listen. Our trained Peer Supporters are here to help you ease your anxiety, identify triggers, teach you coping techniques, or validate your feelings to empower you.",
    image: healing05Img,
    imageMobile: healing05ImgMobile,
    stepImage: healing05StepImg,
    stepImageMobile: healing05StepImgMobile,
  },
];

const currentStepList = [
  healing01StepImg,
  healing02StepImg,
  healing03StepImg,
  healing04StepImg,
  healing05StepImg,
];

export function HealingSwipe() {
  return (
    <Swipe
      currentStepList={currentStepList}
      cardWidth={664}
      gap={40}
      boundaryPadding={76}
    >
      {cards.map((card, index) => {
        const className =
          index === 0
            ? 'md:pr-[20px] md:pl-[76px]'
            : index === cards.length - 1
              ? 'md:pl-[20px] md:pr-[76px]'
              : 'md:px-[20px]';

        return (
          <Card
            title={card.title}
            subTitle={card.subTitle}
            description={card.description}
            image={card.image}
            imageMobile={card.imageMobile}
            stepImage={card.stepImage}
            stepImageMobile={card.stepImageMobile}
            className={clsx('pb-[10px]', className)}
          />
        );
      })}
    </Swipe>
  );
}

interface CardProps {
  title: string;
  subTitle: string;
  description: string;
  image: string;
  imageMobile: string;
  stepImage: string;
  stepImageMobile: string;
  className?: string;
}

function Card(props: CardProps) {
  const {
    title,
    subTitle,
    description,
    image,
    imageMobile,
    stepImage,
    stepImageMobile,
    className,
  } = props;

  return (
    <div
      className={clsx(
        'hidden md:inline-flex flex-col gap-[28px] md:gap-[20px] items-center',
        className
      )}
    >
      <div className="w-[88%] h-[452px] md:w-[664px] md:h-[357px] flex flex-row overflow-hidden rounded-[20px] shadow-[0_5px_10px_rgba(0,0,0,0.1)]">
        <div className="flex-1">
          <div className="px-[12px] py-[8px] md:px-[20px] md:pt-[20px] font-semibold text-base md:text-3xl">
            {subTitle}
          </div>
          <div className="px-[12px] py-[8px] md:p-[20px] font-regular text-sm md:text-lg">
            {description}
          </div>
        </div>
        <div className="relative">
          <img
            src={image}
            alt="What is Sexual Violence"
            className="hidden md:block max-w-full max-h-full object-contain"
          />
          <img
            src={imageMobile}
            alt="What is Sexual Violence"
            className="md:hidden max-w-full max-h-full object-contain"
          />
          <div className="absolute top-0 left-0 p-[10px] md:p-0 md:top-[20px] md:left-[20px] text-white font-semibold md:font-bold text-xl md:text-4xl">
            {title}
          </div>
        </div>
      </div>
    </div>
  );
}

function MobileCards() {
  return (
    <>
      {cards.map((card, index) => {
        const className = '!inline-flex min-w-full';

        return (
          <Card
            title={card.title}
            subTitle={card.subTitle}
            description={card.description}
            image={card.image}
            imageMobile={card.imageMobile}
            stepImage={card.stepImage}
            stepImageMobile={card.stepImageMobile}
            className={clsx('pb-[10px]', className)}
          />
        );
      })}
    </>
  );
}

export function HealingSwipeMobile() {
  return (
    <MobileSwipe dataList={cards}>
      <MobileCards />
    </MobileSwipe>
  );
}
