import React from 'react';
import heading from '@assets/footages/contact-us/heading.svg';
import headingMobile from '@assets/footages/contact-us/heading-mobile.svg';

export default function ContactUs() {
  return (
    <div className="relative pt-[58px] md:pt-[90px] px-0 pb-[150px]">
      <div
        className="hidden md:block absolute top-0 right-0"
        style={{ zIndex: -1 }}
      >
        <img src={heading} alt="heading background" />
      </div>

      <div
        className="md:hidden absolute top-0 left-0 transform translate-y-[22px]"
        style={{ zIndex: -1 }}
      >
        <img src={headingMobile} alt="heading background" />
      </div>

      <div className="max-w-[752px] px-[28px] md:px-[76px]">
        <div>
          <p className="text-6xl md:text-8xl font-extrabold leading-[58px] md:leading-[85px]">
            We'd love to hear from you.
          </p>

          <p className="hidden md:block mt-[50px] text-4xl font-bold">
            contact@heyjuni.com | +65 87494745
          </p>

          <div className="md:hidden mt-[28px] text-[28px] leading-[36px] font-bold flex gap-[15px] items-center">
            <div className="border-2 border-l-2 border-black self-stretch my-[10px]"></div>
            <div>
              <div>contact@heyjuni.com</div>
              <div>+65 8749 4745</div>
            </div>
          </div>

          <div className="mt-[70px] md:mt-[50px] text-[15px] md:text-lg">
            <p>
              If you have any questions, feedback, or simply want to connect
              with us, please don't hesitate to reach out.
            </p>
            <br />
            <p>
              We value your trust and confidentiality, and rest assured that
              your messages will be handled with the utmost care and privacy.
            </p>
          </div>
        </div>

        <div className="mt-[97px]">
          <form className="text-[15px] md:text-lg">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-[30px] md:gap-[24px]">
              <div className="col-span-1">
                <input
                  type="text"
                  id="firstName"
                  className="w-full p-2 border-b-2 border-[#181818] bg-transparent outline-none placeholder-gray-500"
                  placeholder="First Name*"
                />
              </div>
              <div className="col-span-1">
                <input
                  type="text"
                  id="lastName"
                  className="w-full p-2 border-b-2 border-[#181818] bg-transparent outline-none placeholder-gray-500"
                  placeholder="Last Name*"
                />
              </div>
            </div>
            <div className="mt-[30px] md:mt-[50px]">
              <input
                type="email"
                id="email"
                className="w-full p-2 border-b-2 border-[#181818] bg-transparent outline-none placeholder-gray-500"
                placeholder="Email*"
              />
            </div>
            <div className="mt-[30px] md:mt-[50px]">
              <input
                type="text"
                id="subject"
                className="w-full p-2 border-b-2 border-[#181818] bg-transparent outline-none placeholder-gray-500"
                placeholder="Subject*"
              />
            </div>
            <div className="mt-[30px] md:mt-[50px]">
              <textarea
                id="message"
                className="w-full p-2 border-b-2 border-[#181818] bg-transparent outline-none placeholder-gray-500"
                rows={4}
                placeholder="Message*"
              ></textarea>
            </div>
            <div className="mt-[30px] md:mt-[50px] text-center md:text-left">
              <button
                type="submit"
                className="bg-[#FFE816] hover:bg-[#9DFFE2]
                active:bg-[#D8CFFC] text-black hover:text-white
                active:text-black px-[30px] text-lg md:text-2xl rounded-[20px] md:rounded-[24px] h-[50px] md:h-[60px] font-semibold"
              >
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
