import React, { useState } from 'react';
import { CountdownTimer } from './Countdown';
import Button from '@components/Button';
import blogBackgroundImg from '@assets/footages/blog/comming-soon-background.svg';
import blogBackgroundImgMobile from '@assets/footages/blog/comming-soon-background-mobile.svg';
import clockBackgroundImg from '@assets/footages/blog/clock-background.svg';
import { z } from 'zod';
import { trpc } from '@src/App';

export default function Blog() {
  const [email, setEmail] = React.useState('');
  const [subscribed, setSubscribed] = React.useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const subscriptionMutation =
    trpc.subscription.createSubscription.useMutation();

  const subscribe = async () => {
    if (!email) return; // Check if the email is not empty
    const emailSchema = z.string().email();
    const parseResult = emailSchema.safeParse(email);
    if (!parseResult.success) {
      // Check if the email is valid
      alert('Invalid email address');
      return;
    }

    try {
      setIsLoading(true);
      await subscriptionMutation.mutateAsync({ email });
      setSubscribed(true);
    } catch (error) {
      console.error('Subscription failed:', error);
    }

    setIsLoading(false);
  };
  // Set the target date to 30 days in the future from today
  const targetDate = new Date();
  targetDate.setDate(targetDate.getDate() + 30);

  return (
    <>
      <div className="relative overflow-hidden md:py-[40px] md:px-[76px]">
        <div
          className="hidden md:block absolute left-0 top-0 w-full"
          style={{ zIndex: -1 }}
        >
          <img src={blogBackgroundImg} alt="" className="w-full object-cover" />
        </div>

        <div
          className="md:hidden absolute left-0 top-0 w-full h-full bg-[#D8CFFC]"
          style={{ zIndex: -1 }}
        >
          <img src={blogBackgroundImgMobile} alt="" className="w-full" />
        </div>

        <div className="pt-[28px] px-[20px] md:pt-0 md:px-0">
          <div className="font-extrabold text-6xl md:text-8xl leading-[58px] md:leading-[85px] mb-[20px]">
            Blog
          </div>
          <div className="text-lg md:text-2xl leading-[26px] md:leading-[32px]">
            Engaging and informative mental health and sexual violence content
            coming soon
          </div>
        </div>

        <div className="relative overflow-hidden mt-[84px] md:mt-[40px] mb-[45px] md:mb-0 mx-[18px] md:mx-0 bg-white rounded-[40px] py-[32px] md:py-[10px] px-[20px] md:px-[40px] flex flex-col md:flex-row md:gap-[100px] justify-center shadow-lg">
          <div className="hidden md:block absolute left-0 bottom-0 w-auto">
            <img src={clockBackgroundImg} alt="" />
          </div>

          <div className="md:px-[20px] md:py-[64px]">
            <div className="font-extrabold text-xl md:text-5xl md:leading-[45px] text-center">
              Coming Soon!
            </div>
            <div className="mb-[10px] md:mb-0 md:mt-[10px] text-center text-semibold md:text-3xl md:leading-[30px]">
              Great things are coming your way
            </div>
            <CountdownTimer targetDate={targetDate} />
          </div>

          <div className="hidden md:block">
            <SubscribeSection
              subscribed={subscribed}
              subscribe={subscribe}
              email={email}
              setEmail={setEmail}
              isLoading={isLoading}
            />
          </div>
        </div>
      </div>

      <div className="md:hidden">
        <SubscribeSection
          subscribed={subscribed}
          subscribe={subscribe}
          email={email}
          setEmail={setEmail}
          isLoading={isLoading}
        />
      </div>
    </>
  );
}

interface SubscribeSectionProps {
  subscribed: boolean;
  subscribe: () => void;
  email: string;
  setEmail: (value: string) => void;
  isLoading?: boolean;
}

function SubscribeSection(props: SubscribeSectionProps) {
  const { subscribed, subscribe, email, setEmail, isLoading } = props;

  return (
    <div className="flex-1 p-[20px] md:px-[20px] md:pt-[64px] flex flex-col gap-[40px]">
      {subscribed ? (
        <div className="text-center text-5xl my-20 transition-opacity delay-1000">
          Thank you for subscribing!
        </div>
      ) : (
        <>
          <div className="font-semibold text-3xl leading-[30px]">
            Notify me upon release:
          </div>
          <div>
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Enter your email"
              className="w-full border-b border-gray-300 focus:outline-none focus:border-gray-600 placeholder-gray-400 py-1 text-lg"
            />
          </div>
          <div>
            <Button
              label={isLoading ? 'Loading...' : 'Notify Me'}
              className="w-full rounded-[24px] h-[50px] font-semibold md:text-2xl"
              onClick={subscribe}
            />
            <div className="mt-[10px] text-[14px] md:text-sm text-gray-400">
              By clicking on Notify Me, you agree to receive occasional emails
              from HeyJuni.
            </div>
          </div>
        </>
      )}
    </div>
  );
}
