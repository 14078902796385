import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { faChevronUp } from '@fortawesome/free-solid-svg-icons';
import clsx from 'clsx';

interface DropdownFieldProps {
  options: string[];
  labels?: Record<string, string>;
  selectedOption: string;
  onChangeSelection: (value: string) => void;
}

export function DropdownField(props: DropdownFieldProps) {
  const { options, labels, selectedOption, onChangeSelection } = props;

  const [isOpen, setIsOpen] = useState(false);

  const handleOptionClick = (option: string) => {
    onChangeSelection(option);
    setIsOpen(false); // Close dropdown after selection
  };

  return (
    <div className="w-full max-w-md mx-auto mb-8 md:mb-[52px]">
      {/* Dropdown header */}
      <div
        onClick={() => {
          setIsOpen(!isOpen);
        }}
        className={clsx(
          'relative flex items-center cursor-pointer transition-all duration-300',
          'h-[40px] md:w-[532px] px-4 md:px-[28px] rounded-[8px]',
          'shadow-[0_-1px_4px_rgba(0,0,0,0.1),0_5px_8px_rgba(0,0,0,0.1)]',
          selectedOption !== 'Select Category' ? 'bg-[#FEE60C]' : 'bg-white',
          selectedOption !== 'Select Category' && 'justify-end',
          selectedOption === 'Select Category'
            ? 'justify-between'
            : 'justify-end'
        )}
      >
        <span
          className={clsx(
            selectedOption === 'Select Category' && 'text-gray-500',
            selectedOption !== 'Select Category' && 'text-black',
            selectedOption !== 'Select Category' &&
              'absolute left-1/2 transform -translate-x-1/2'
          )}
        >
          {labels && selectedOption !== 'Select Category'
            ? labels[selectedOption]
            : selectedOption}
        </span>
        <FontAwesomeIcon icon={isOpen ? faChevronUp : faChevronDown} />
      </div>

      {/* Dropdown options with height, scale, and opacity animation */}
      {/* TODO: Make animation for this isOpen && */}

      {isOpen && (
        <div
          className={`flex flex-col gap-[5px] md:mt-[22px] transition-all duration-300 origin-top ${
            isOpen ? 'max-h-96 opacity-100' : 'max-h-0 opacity-0'
          }`}
          style={{ transitionProperty: 'max-height, opacity' }}
        >
          {options.map((option) => (
            <div
              key={option}
              onClick={() => {
                if (!isOpen) return;
                handleOptionClick(option);
              }}
              className="md:w-[532px] md:h-[40x] px-4 py-3 bg-white rounded-[8px] shadow-lg hover:bg-[#9DFFE2] cursor-pointer transition-colors duration-300"
            >
              {labels ? labels[option] : option}
            </div>
          ))}
        </div>
      )}
    </div>
  );
}
