import { useState } from 'react';
import { loadStripe, Stripe } from '@stripe/stripe-js';
import {
  EmbeddedCheckoutProvider,
  EmbeddedCheckout,
} from '@stripe/react-stripe-js';
import { trpc } from '@src/App';
import {
  AgeGroup,
  CauseOfAnxiety,
  FeelingDescriptions,
  Gender,
  OutcomeExpectation,
  PreferredMode,
} from '@source/types';
import { useBookingContext } from './BookingContext';
import Button from './components/Button';
import { BookingTimeType } from '@src/types/booking';
import { formatToISO8601, getClientTimeZoneOffset } from '@src/utils/time';
import { Timezone } from '@backend/src/types';
import { PopUp } from '@components/Popup';
import closeIcon from '@assets/icons/shared/close-icon.svg';
import { Spinner } from '@components/Spinner';

export function Step12() {
  const [stripePromise, setStripePromise] = useState<Stripe | null>(null);
  const [clientSecret, setClientSecret] = useState<string | null>(null);
  const createBookingMutation =
    trpc.bookingRequest.createBookingRequest.useMutation();

  const [isLoading, setIsLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  // TODO: Refactor later
  const {
    address,
    anxiousReason,
    email,
    gender,
    age,
    dataList03,
    dataList04,
    dataList06,
    dataList07,
    dataList09,
    isChecked,
    status,
    bookingTimeType,
    selectedTimezone,
    selectedDate,
    startTime,
  } = useBookingContext();

  const handleCheckoutClick = async () => {
    const bookingDateTime =
      bookingTimeType === BookingTimeType.BOOK_NOW
        ? new Date(Date.now() + 15 * 60 * 1000).toISOString() // Adds 15 minutes
        : formatToISO8601(selectedTimezone, selectedDate, startTime);

    const payload = {
      preferredName: address,
      concernDescription: anxiousReason,
      causeOfAnxiety:
        (dataList03?.[0] as CauseOfAnxiety) ?? CauseOfAnxiety.OTHER,
      feelingDescriptions: dataList04 as FeelingDescriptions,
      anxietyIntensity: status,
      outcomeExpectation:
        (dataList06?.[0] as OutcomeExpectation) ?? OutcomeExpectation.OTHER,
      currentlySeekingTherapy: dataList07?.[0] === 'YES' ? true : false,
      ageGroup: age as AgeGroup,
      gender: gender as Gender,
      preferredMode: (dataList09?.[0] as PreferredMode) ?? PreferredMode.TEXT,
      preferredDateTime: new Date(bookingDateTime),
      timezone: selectedTimezone as Timezone,
      email: email,
    };

    // TODO: Turn off when api call fails
    setIsLoading(true);
    const response = await createBookingMutation.mutateAsync(payload);

    setClientSecret(response.paymentSecret);
    const stripePromise = await loadStripe(response.publishableKey);
    setStripePromise(stripePromise);

    setIsLoading(false);

    // TODO: Also open modal when success
    setIsOpen(true);
  };

  const handleCloseModal = () => {
    setStripePromise(null);
    setIsOpen(false);
  };

  return (
    <>
      {isLoading && <Spinner />}

      <div className="hidden md:block booking-step-title">Payment</div>
      <div className="block md:hidden font-extrabold text-4xl">Payment</div>

      <div className="mt-[23px]">
        <div className="font-regular text-sm md:text-lg">
          To ensure meaningful support, our Peer Supporters undergo specialized
          training and dedicate their time to you. For this reason, we charge
          $29.90 SGD per session. Kindly complete the payment to confirm your
          booking.
        </div>
        <div className="flex justify-center">
          <Button
            label="Pay Now"
            className="w-full md:w-auto text-center mt-[24px]"
            onClick={handleCheckoutClick}
          />

          <PopUp
            isOpen={isOpen}
            onClose={() => {
              setIsOpen(false);
            }}
            className="rounded-0 md:rounded-[25px] w-[100%] md:w-[93.75%] h-[99.9%] md:h-[99%] pt-[46px] md:py-[20px] md:px-[60px] !bg-[#D8FFF3]"
            isGlobalOverlap={true}
          >
            <div
              className="absolute right-[18px] top-[18px] md:right-[28px] md:top-[28px] cursor-pointer"
              onClick={() => setIsOpen(false)}
            >
              <img
                src={closeIcon}
                alt="Close Icon"
                className="w-[15px] h-[15px] object-contain"
              />
            </div>
            <div className="h-full">
              {stripePromise ? (
                <EmbeddedCheckoutProvider
                  stripe={stripePromise}
                  options={{
                    clientSecret,
                  }}
                >
                  <EmbeddedCheckout className="h-full overflow-auto" />
                </EmbeddedCheckoutProvider>
              ) : (
                <div className="spinner"></div>
              )}
            </div>
          </PopUp>
        </div>
      </div>
    </>
  );
}
