import React, { useState, useRef, useEffect } from 'react';
import sliderBar from '@assets/footages/landing/stability-scale.svg';

// TODO: Update any
interface StateSliderProps {
  states: Array<any>;
  currentSection: number;
  onChangeCurrentSection: (value: number) => void;
}

export function StateSlider(props: StateSliderProps) {
  const { states, currentSection, onChangeCurrentSection } = props;

  const [position, setPosition] = useState(0); // position in percentage
  const [isDragging, setIsDragging] = useState(false);
  const sliderRef = useRef<HTMLDivElement>(null);

  // Helper to update the current section
  const updateCurrentSection = (position: number) => {
    if (position < 0.25) {
      onChangeCurrentSection(0);
    } else if (position < 0.5) {
      onChangeCurrentSection(1);
    } else if (position < 0.75) {
      onChangeCurrentSection(2);
    } else {
      onChangeCurrentSection(3);
    }
  };

  // Start drag (for both mouse and touch)
  const handleMouseDown = (e: React.MouseEvent | React.TouchEvent) => {
    e.preventDefault();
    setIsDragging(true);
  };

  // Move drag (for both mouse and touch)
  const handleMove = (clientX: number) => {
    if (!isDragging || !sliderRef.current) return;

    const sliderRect = sliderRef.current.getBoundingClientRect();
    let newPosition = (clientX - sliderRect.left) / sliderRect.width;

    // Boundaries: ensure the position stays within the slider
    newPosition = Math.max(0, Math.min(newPosition, 1));

    setPosition(newPosition);
    updateCurrentSection(newPosition); // Update the section whenever the position changes
  };

  const handleMouseMove = (e: MouseEvent) => {
    handleMove(e.clientX);
  };

  const handleTouchMove = (e: TouchEvent) => {
    handleMove(e.touches[0].clientX);
  };

  // End drag (for both mouse and touch)
  const handleMouseUp = () => {
    setIsDragging(false);
  };

  // Click on slider
  const handleSliderClick = (e: React.MouseEvent) => {
    if (!sliderRef.current) return;
    const sliderRect = sliderRef.current.getBoundingClientRect();
    const clickPosition = (e.clientX - sliderRect.left) / sliderRect.width;
    const clampedPosition = Math.max(0, Math.min(clickPosition, 1)); // Bound the position between 0 and 1

    setPosition(clampedPosition);
    updateCurrentSection(clampedPosition); // Update the section based on the clicked position
  };

  useEffect(() => {
    const onMouseMove = (e: MouseEvent) => handleMouseMove(e);
    const onTouchMove = (e: TouchEvent) => handleTouchMove(e);
    const onMouseUp = () => handleMouseUp();

    if (isDragging) {
      window.addEventListener('mousemove', onMouseMove);
      window.addEventListener('mouseup', onMouseUp);
      window.addEventListener('touchmove', onTouchMove);
      window.addEventListener('touchend', onMouseUp);
    }

    return () => {
      window.removeEventListener('mousemove', onMouseMove);
      window.removeEventListener('mouseup', onMouseUp);
      window.removeEventListener('touchmove', onTouchMove);
      window.removeEventListener('touchend', onMouseUp);
    };
  }, [isDragging]);

  return (
    <div className="relative w-full mx-auto mt-0 md:mt-8 md:w-[880px]">
      {/* Slider Bar */}
      <div
        className="relative w-full h-4 cursor-pointer"
        onClick={handleSliderClick}
        ref={sliderRef}
      >
        <img src={sliderBar} alt="Slider Bar" className="w-full h-auto" />
      </div>

      {/* Draggable Icon */}
      <div
        className="absolute top-[-12px] md:top-[-24px] w-[28px] h-[28px] md:w-[60px] md:h-[60px] cursor-pointer"
        style={{
          left: position === 0 ? '14px' : `calc(${position * 100}% - 24px)`,
        }}
        onMouseDown={handleMouseDown}
        onTouchStart={handleMouseDown}
      >
        <img
          src={states[currentSection].icon}
          alt="Draggable Icon"
          className="w-full h-auto"
        />
      </div>
    </div>
  );
}
