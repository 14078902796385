import { TimeOption } from '@src/types/common';
import { getClientTimeZoneOffset, isOffTime } from '@src/utils/time';
import { useMemo, useState } from 'react';
import DatePicker from 'react-datepicker';
import Select from 'react-select';
import 'react-datepicker/dist/react-datepicker.css';
import { useBookingContext } from '../BookingContext';

export default function AppointmentSelector() {
  const timezoneOptions = useTimeZoneOptions();
  const clientTimeZoneOffset = getClientTimeZoneOffset();

  const {
    selectedTimezone,
    setSelectedTimezone,
    selectedDate,
    setSelectedDate,
    startTime,
    setStartTime,
  } = useBookingContext();

  const handleDateChange = (date: Date | null) => {
    setSelectedDate(date);
    setStartTime(null); // Reset start time when the date changes
  };

  const handleStartTimeChange = (option: TimeOption | null) => {
    setStartTime(option);
  };

  // Check if selectedDate is the same as minDate (start of today in the selected timezone)
  const isSelectToday = useMemo(() => {
    if (!selectedDate) return false;

    const minDate = getMinDateForTimezone(
      selectedTimezone ?? clientTimeZoneOffset
    );
    return selectedDate.toDateString() === minDate.toDateString();
  }, [selectedDate, selectedTimezone]);

  // Then use these values in generateTimeOptions
  const timeOptions = useMemo(() => {
    if (!isSelectToday)
      return generateTimeOptions(-1, 0, selectedTimezone, selectedDate);

    const offsetInHours = parseInt(
      selectedTimezone ?? clientTimeZoneOffset,
      10
    );
    const currentUtcDate = new Date();
    const localDateWithOffset = new Date(
      currentUtcDate.getTime() + offsetInHours * 60 * 60 * 1000
    );

    const currentHourAndMinute = {
      hour: localDateWithOffset.getUTCHours(),
      minute: localDateWithOffset.getUTCMinutes(),
    };

    return generateTimeOptions(
      currentHourAndMinute.hour,
      currentHourAndMinute.minute,
      selectedTimezone,
      selectedDate
    );
  }, [selectedTimezone, isSelectToday, selectedDate]);

  return (
    <div className="w-full mx-auto bg-white rounded-[17px]">
      <div className="min-h-[110px] space-y-6 grid grid-cols-1 md:grid-cols-3 gap-6 items-start md:mt-[40px]">
        {/* Timezone Selector */}
        <div className="space-y-2 md:mt-0">
          <label className="text-sm md:text-lg font-semibold">
            Select Timezone
          </label>
          <Select
            options={timezoneOptions}
            value={timezoneOptions.find(
              (option) => option.value === selectedTimezone
            )}
            onChange={(option) => {
              setSelectedTimezone(option ? option.value : '');
              setSelectedDate(null);
              setStartTime(null);
            }}
            placeholder="Choose a timezone"
            className="w-full h-[40px]"
            styles={{
              control: (provided) => ({
                ...provided,
                borderRadius: '8px',
                height: '40px',
              }),
            }}
          />
        </div>

        <div className="space-y-2" style={{ marginTop: 0 }}>
          <label className="text-sm md:text-lg font-semibold">
            Select a Date
          </label>
          <DatePicker
            selected={selectedDate}
            onChange={handleDateChange}
            dateFormat="MMMM d, yyyy"
            minDate={getMinDateForTimezone(
              selectedTimezone ?? clientTimeZoneOffset
            )}
            className="custom-datepicker w-full border border-gray-300 rounded-lg p-2 h-[40px]"
            placeholderText="Choose a date"
            dayClassName={(date) => {
              const isToday =
                date.toDateString() ===
                getMinDateForTimezone(
                  selectedTimezone ?? clientTimeZoneOffset
                ).toDateString();
              return isToday ? '!bg-blue-100 !font-bold' : '';
            }}
          />
        </div>

        {/* Start Time Picker */}
        <div className="space-y-2" style={{ marginTop: 0 }}>
          <label className="text-sm md:text-lg font-semibold">Start Time</label>{' '}
          <Select
            options={timeOptions}
            value={startTime}
            onChange={handleStartTimeChange}
            placeholder="Choose start time"
            className="w-full"
            styles={{
              menuPortal: (base) => ({ ...base, zIndex: 9999 }),
              control: (provided) => ({
                ...provided,
                borderRadius: '8px',
                height: '40px',
              }),
            }}
            isDisabled={!selectedDate}
          />
        </div>
      </div>

      {/* Display Selected Appointment */}
      {/* {selectedDate && startTime && (
        <div className="mt-6 md:mt-0 text-center p-4 bg-blue-100 rounded-[10px] md:rounded-[15px]">
          <p className="text-lg">
            <strong>Selected Appointment</strong>
          </p>
          <p className="text-sm md:text-lg mt-2">
            {startTime.label}, {selectedDate.toDateString()}, Timezone:{' '}
            {selectedTimezone}
          </p>
        </div>
      )} */}
    </div>
  );
}

// Hook to return timezone options
function useTimeZoneOptions() {
  const timezones = [
    { value: '-12', label: 'UTC-12 (Baker Island, Howland Island)' },
    { value: '-11', label: 'UTC-11 (Pago Pago, Niue)' },
    { value: '-10', label: 'UTC-10 (Honolulu, Papeete)' },
    { value: '-9', label: 'UTC-9 (Anchorage, Fairbanks)' },
    { value: '-8', label: 'UTC-8 (Los Angeles, Vancouver, Tijuana)' },
    { value: '-7', label: 'UTC-7 (Denver, Phoenix, Calgary)' },
    { value: '-6', label: 'UTC-6 (Chicago, Mexico City, Winnipeg)' },
    { value: '-5', label: 'UTC-5 (New York, Toronto, Havana)' },
    { value: '-4', label: 'UTC-4 (Santiago, Santo Domingo, Halifax)' },
    { value: '-3', label: 'UTC-3 (São Paulo, Buenos Aires, Montevideo)' },
    { value: '-2', label: 'UTC-2 (Fernando de Noronha, South Georgia Island)' },
    { value: '-1', label: 'UTC-1 (Praia, Azores)' },
    { value: '0', label: 'UTC+0 (London, Dublin, Lisbon)' },
    { value: '1', label: 'UTC+1 (Paris, Berlin, Rome)' },
    { value: '2', label: 'UTC+2 (Cairo, Athens, Jerusalem)' },
    { value: '3', label: 'UTC+3 (Moscow, Istanbul, Riyadh)' },
    { value: '4', label: 'UTC+4 (Dubai, Baku, Yerevan)' },
    { value: '5', label: 'UTC+5 (Karachi, Tashkent, Maldives)' },
    { value: '5.5', label: 'UTC+5:30 (New Delhi, Mumbai, Colombo)' },
    { value: '5.75', label: 'UTC+5:45 (Kathmandu)' },
    { value: '6', label: 'UTC+6 (Dhaka, Almaty, Thimphu)' },
    { value: '6.5', label: 'UTC+6:30 (Yangon)' },
    { value: '7', label: 'UTC+7 (Bangkok, Jakarta, Hanoi)' },
    { value: '8', label: 'UTC+8 (Beijing, Singapore, Manila)' },
    { value: '9', label: 'UTC+9 (Tokyo, Seoul, Pyongyang)' },
    { value: '9.5', label: 'UTC+9:30 (Adelaide, Darwin)' },
    { value: '10', label: 'UTC+10 (Sydney, Melbourne, Brisbane)' },
    { value: '11', label: 'UTC+11 (Solomon Islands, New Caledonia)' },
    { value: '12', label: 'UTC+12 (Auckland, Wellington, Fiji)' },
    { value: '13', label: "UTC+13 (Nuku'alofa, Apia)" },
    { value: '14', label: 'UTC+14 (Kiritimati)' },
  ];

  return timezones;
}

// Generate time options in 1-hour intervals with custom logic for the same date
const generateTimeOptions = (
  currentHour: number,
  currentMinute: number,
  selectedTimezone: string | null,
  selectedDate: Date | null
): TimeOption[] => {
  const options: TimeOption[] = [];
  let hour = currentMinute < 30 ? currentHour : currentHour + 1;

  // Ensure the next slot starts at the hour after the rounded hour
  hour += 1;

  while (hour < 24) {
    const hourStr = hour < 10 ? `0${hour}` : `${hour}`;
    const period = hour < 12 ? 'AM' : 'PM';
    const label = `${hour % 12 || 12}:00 ${period}`;
    const value = `${hourStr}:00`;

    const option = { label, value };
    const isHide =
      isOffTime() && isOffTime(selectedTimezone, selectedDate, option);

    if (!isHide) options.push(option);
    hour++; // Increment by 1 hour
  }

  return options;
};

// Function to calculate `minDate` based on `selectedTimezone`
const getMinDateForTimezone = (timezoneOffset: string): Date => {
  const offsetInHours = parseInt(timezoneOffset, 10);
  const currentUtcDate = new Date();

  // Adjust the UTC date by the timezone offset in hours
  const localDateWithOffset = new Date(
    currentUtcDate.getTime() + offsetInHours * 60 * 60 * 1000
  );

  // Set this date to the start of the day in the selected timezone
  localDateWithOffset.setUTCHours(0, 0, 0, 0);

  return localDateWithOffset;
};
