import React from 'react';
import HeartPerson1 from '@assets/footages/meet-the-team/heart-person-1.svg';

interface CoreTeamProps {
  avatar: string;
  name: string;
  title: string;
}

export default function CoreTeam({ avatar, name, title }: CoreTeamProps) {
  return (
    <div key={name} className="flex flex-col items-start">
      <img
        src={avatar}
        alt={name}
        className="mb-[20px] md:mb-[32px] w-full h-auto object-cover"
      />
      <div className="mb-[5px] md:mb-[10px] font-bold md:text-4xl md:leading-[40px]">
        {name}
      </div>
      <div className="font-semibold text-[12px] md:text-3xl leading-[20px] md:leading-[30px]">
        {title}
      </div>
    </div>
  );
}
