import React from 'react';
import clsx from 'clsx';

// TODO: Will remove and use the global

interface ButtonProps {
  className?: string;
  label: string;
  onClick?: () => void;
  variant?: 'primary' | 'outlined'; // Add variant prop
}

export default function Button(props: ButtonProps) {
  const { className, label, onClick, variant = 'primary' } = props; // Default variant is primary

  return (
    <button
      onClick={onClick}
      className={clsx(
        'whitespace-nowrap rounded-[20px] h-[50px] md:min-w-[154px] px-[30px] font-semibold text-lg text-black', // Common classes
        {
          // Primary variant (default)
          'bg-[#FEE60C]': variant === 'primary',

          // Outlined variant
          'bg-[#EBEDF0]': variant === 'outlined',
        },
        className
      )}
    >
      {label}
    </button>
  );
}
