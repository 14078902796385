import z from 'zod';

export const AuthenticationTokenPayloadSchema = z.object({
    userId: z.number(),
    email: z.string(),
    role: z.number(),
});
export type AuthenticationTokenPayload = z.infer<typeof AuthenticationTokenPayloadSchema>;

export const ResetPasswordTokenPayloadSchema = z.object({
    userId: z.number(),
});
export type ResetPasswordTokenPayload = z.infer<typeof ResetPasswordTokenPayloadSchema>;
