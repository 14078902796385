import React from 'react';

import BackgroundImage from '@assets/footages/landing/testimonial-card-1.svg';
import LeftArrow from '@assets/icons/shared/circle-arrow-left.svg';
import RightArrow from '@assets/icons/shared/circle-arrow-right.svg';
import SectionTitle from '@components/SectionTitle';
import { HoverCard } from './components/HoverCard';

import ArrowIcon01 from '@assets/footages/landing/arrow-circle-01.svg';
import ArrowIcon02 from '@assets/footages/landing/arrow-circle-02.svg';
import ArrowIcon03 from '@assets/footages/landing/arrow-circle-03.svg';
import { useNavigate } from 'react-router-dom';

export function GetInvolvedSection() {
  const navigate = useNavigate();

  const cards = [
    {
      title: "Work with us. Let's build HeyJuni together.",
      color: '#FEE60C',
      arrowIcon: ArrowIcon01,
      handleClick: () => navigate('/contact-us'),
    },
    {
      title: 'Join as Peer Supporter. Help those in distress.',
      color: '#D8FFF3',
      arrowIcon: ArrowIcon02,
      handleClick: () => navigate('/become-a-listener'),
    },
    {
      title: 'Partner and collaborate with HeyJuni.',
      color: '#AF9CFB',
      arrowIcon: ArrowIcon03,
      handleClick: () => navigate('/contact-us'),
    },
  ];

  return (
    <div className="text-center py-[20px] mb-[64px]">
      <p className="text-5xl md:text-7xl leading-[48px] md:leading-[65px] font-extrabold mb-[40px] md:mb-0">
        Get involved
      </p>

      {/* Cards Container */}
      <div className="flex flex-col md:flex-row gap-[20px] md:gap-[24px] md:mt-[20px] px-[28px] md:px-[76px]">
        {cards.map((card, index) => (
          <HoverCard
            key={index}
            color={card.color}
            text={card.title}
            arrowIcon={card.arrowIcon}
            handleClick={card.handleClick}
          />
        ))}
      </div>
    </div>
  );
}
