// useScrollToTop.ts
import { useCallback, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export default function useScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
}

export const useScrollToTopFunction = (): (() => void) => {
  const scrollToTop = useCallback(() => {
    window.scrollTo({
      top: 0,
    });
  }, []);

  return scrollToTop;
};
