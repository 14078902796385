import React from 'react';
import missionAndVisionHeading from '@assets/footages/mission-and-vision/heading.svg';
import background1 from '@assets/footages/mission-and-vision/background-1.svg';
import background2 from '@assets/footages/mission-and-vision/background-2.svg';
import HeadingBackground from '@assets/footages/mission-and-vision/heading-background.svg';
import TeamMembers from '@assets/footages/mission-and-vision/team-members.svg';
import Organizations from '@assets/footages/mission-and-vision/organizations.svg';
import Businesses from '@assets/footages/mission-and-vision/businesses.svg';
import University from '@assets/footages/mission-and-vision/university.svg';
import Button from '@components/Button';
import clsx from 'clsx';
import { useNavigate } from 'react-router-dom';
import { openWhatsapp } from '@src/utils/open-whatsapp';

// TODO: Check padidng and margin of the page

export default function MissionAndVision() {
  const navigate = useNavigate();

  return (
    <div className="relative pt-[20px] md:py-[94px] px-[28px] md:px-[76px]">
      {/* TODO: Fix the yellow line */}
      <div className="absolute right-0 top-0" style={{ zIndex: -1 }}>
        <img src={HeadingBackground} alt="Heading Background" />
      </div>

      <div className="mb-[120px] md:mb-[100px]">
        <div className="text-5xl md:text-8xl leading-[48px] md:leading-[85px] font-extrabold">
          <div>Democratizing Mental</div>
          <div>Health Care</div>
        </div>

        <div className="text-lg md:text-2xl mt-[20px] md:mt-[40px]">
          Ending Stigma and Bringing Wellbeing Closer and Faster To Everyone
        </div>

        <Button
          label="Book a Demo"
          className="mt-[40px] md:mb-[100px] h-[50px] md:h-[60px] rounded-[20px] md:rounded-[24px] px-[30px] md:px-[30px] font-semibold text-lg md:text-2xl"
          onClick={() => navigate('/contact-us')}
        />
      </div>

      <div className="mx-auto mb-[120px] md:mb-[100px]">
        <div className="mb-[40px] w-full">
          <img src={TeamMembers} alt="Team Members" className="w-full" />
        </div>

        <div>
          <div className="md:pt-[20px] mb-[20px] md:mb-[32px] font-extrabold text-5xl md:text-7xl leading-[48px] md:leading-[65px]">
            Mission & Vision
          </div>
          <div className="md:text-lg md:leading-[26px]">
            <p className="pb-[20px]">
              We believe in breaking the stigma around mental health, promoting
              inclusivity in support, and creating a world where mental health
              care is not only effective but also easily accessible, affordable,
              available, and grounded in true human connection.{' '}
            </p>
            <p className="pb-[20px]">
              We commit to deliver innovative mental health care in the fastest
              and most convenient way through anonymous and non-judgmental
              support with trained professionals, continually improved by
              advanced clinical and technological solutions that are purposeful
              and safe.
            </p>
          </div>
        </div>

        <div>
          <div className="md:pt-[20px] mb-[20px] md:mb-[32px] font-extrabold text-5xl md:text-7xl leading-[48px] md:leading-[65px]">
            Our Story
          </div>
          <div className="md:text-lg md:leading-[26px]">
            <p className="pb-[20px]">
              HeyJuni was built on the foundations of lived experiences and
              expertise in mental health, driven by the recognition of a harsh
              reality: Half the world population - 4 billion people - will face
              one or more mental health struggles in their lifetime, often going
              undiagnosed or untreated for years. In a world where mental health
              was dismissed, we learned to cope alone, bottling up our
              struggles. As the pain deepened and turned into serious
              conditions, the fear of stigma kept us from seeking help.
            </p>
            <p className="pb-[20px]">
              Yet, in our darkest moments, we discovered that healing begins
              with human connection - someone who truly understands, offers a
              comforting word, provides emotional guidance, and shares
              experiences that help piece things back together. We want to
              create a world where empathy replaces stigma and where no one has
              to suffer alone.
            </p>
            <p className="md:pb-[20px]">
              With roots in many languages, the name Juni reflects the human
              touch and connection we aim to offer through our service. Kind,
              warm and soothing, it symbolizes protection, renewal, and growth.
              Chosen in June, just as summer began, the name felt like a natural
              calling for our team.
            </p>
          </div>
        </div>
      </div>

      <div className="text-center mb-[120px] md:mb-0">
        <div className="md:pt-[20px] mb-[40px] md:mb-[64px] font-extrabold text-5xl md:text-7xl leading-[48px] md:leading-[65px]">
          Our Partners
        </div>
        {/* TODO: Separate images here */}
        {/* TODO: Fix this later */}
        <div className="mb-[40px] md:mb-[64px]">
          <div className="flex justify-center mb-[96px] md:mb-[80px]">
            <PartnerItem
              title="Organizations"
              img={Organizations}
              description="We have the pleasure to work with these organizations to help grow our mission together."
              className="md:w-[510px]"
            />
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-y-[96px] md:gap-y-0 md:gap-x-[40px]">
            <PartnerItem
              title="Businesses"
              img={Businesses}
              description="Some businesses we have the privilege to collaborate on projects and aid in building HeyJuni together."
            />
            <PartnerItem
              title="University Clubs"
              img={University}
              description="We proudly sponsor diverse halls and clubs from universities across Singapore."
            />
          </div>
        </div>
        <Button
          label="Partner with Us"
          className="md:mb-[20px] h-[50px] md:h-[60px] rounded-[20px] md:rounded-[24px] px-[30px] md:px-[30px] font-semibold text-lg md:text-2xl"
          onClick={openWhatsapp}
        />
      </div>
    </div>
  );
}

interface PartnerItemProps {
  title: string;
  img: string;
  description: string;
  className?: string;
}

function PartnerItem(props: PartnerItemProps) {
  const { title, img, description, className } = props;

  return (
    <div className={clsx('text-center', className)}>
      <div className="mb-[16px] md:mb-[24px] font-bold text-xl md:text-4xl md:leading-[40px]">
        {title}
      </div>
      <div className="w-full">
        <img src={img} alt={title} className="mx-auto" />
      </div>
      <div className="mt-[20px] md:text-lg md:leading-[26px]">
        {description}
      </div>
    </div>
  );
}
