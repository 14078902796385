export const EXECUTIVE_MEMBERS = [
  {
    videoId: '1025694435',
    name: 'Dr. Malia Nguyen',
    title: 'Co-founder, CEO',
    subTitle: 'Problem Solving. Innovating with Purpose. Connecting.',
    about:
      'Equipped with a Doctorate and Medical Degree in Human Medicine and having personally navigated trauma, Malia represents a fusion of empathy, domain knowledge, and clinical expertise. Her lived experiences drive her commitment to transforming and scaling mental health care and solve complex challenges with resilience. Coupled with her deep insights and scientific understanding, she shapes HeyJuni’s vision, mission, and product with passion, clarity and purpose. Malia is adept at building connections, identifying the innate talents in others, and empowering them to reach their full potential, fostering both personal & organizational success.',
  },
  {
    videoId: '1025695390',
    name: 'Mervyn Lau',
    title: 'Co-founder, COO',
    subTitle: 'Resilient. Action-Oriented. Strategic Thinking.',
    about:
      "With resilience and grit as a lawyer, coupled with perseverance through two previous startups, Mervyn is a tireless force in everything he undertakes. His ability to turn initiatives into swift action is one of the most crucial qualities for driving HeyJuni’s Vision, Mission, and Culture toward success and ensuring a consistent focus on its objectives. Mervyn is a natural strategist functioning at high-speed. He excels at translating the overarching vision into actionable plans, creating a structure necessary to achieve HeyJuni's goals, and driving the organization toward scalability, product-market fit and expansion globally.",
  },
  {
    videoId: '1025696593',
    name: 'Linh Nguyen',
    title: 'Co-founder, CTO',
    subTitle: 'Learning at Speed. Data-driven. Logical Thinking.',
    about:
      'With a solid foundation in data science, AI, and programming, coupled with understanding of mental health through previous involvement in a mental health NGO, Linh has the ability to develop products that align with HeyJuni’s Vision, Mission, and Culture. As a lifelong learner - especially in AI alignment and Natural Language Processing - she is dedicated to drive HeyJuni toward impactful tech solutions that leverages the capabilities of data & machine learning. Linh’s proficiency in utilizing algorithms for personalized user experiences, product automations, and her vision for integrating advanced analytics into our platform ensure we remain at the top of innovation.',
  },
  {
    videoId: '1025697166',
    name: 'Nora Liu',
    title: 'Head of Domain',
    subTitle: 'Empathy. Knowledge-sharing. Community Bonding.',
    about:
      'With extensive experience in therapy, Nora embodies the caring and reliable qualities essential for mental health care. Her work with low- and no-income populations has equipped her with valuable insights into the unique challenges faced by individuals with limited access to care. Nora’s patience and stability as a therapist position her as a strong pillar for our users, overseeing their knowledge progression, product satisfaction, and overall well-being. She cultivates a trusting environment where individuals feel safe and understood, empowering them to thrive and driving the effectiveness of HeyJuni’s platform in meeting the different needs of our community.',
  },
];

export const CORE_MEMBERS = [
  {
    name: 'Aishah',
    title: 'Domain Advisor & Trainer',
  },
  {
    name: 'Chiranjib B.',
    title: 'Tech & Solution Advisor',
  },
  {
    name: 'Sabrina Prabowo',
    title: 'UX/UI Lead',
  },
  {
    name: 'Lac Thien Ho',
    title: 'Fullstack Developer ',
  },
  {
    name: 'Lynn Tan',
    title: 'Product Manager',
  },
  {
    name: 'Kelsey Ng',
    title: 'Content/Marketing Consultant',
  },
  {
    name: 'Akshita Bhatia',
    title: 'Clinical Consultant',
  },
  {
    name: '⁠Pradnya Govind',
    title: 'Lead Peer Supporter',
  },
  {
    name: 'Preston Hew',
    title: 'Clinical Consultant',
  },
];

export const SPECIAL_THANKS_MEMBERS = [
  {
    name: 'Aloysius Sim',
    title: 'UX/UI Designer',
  },
  {
    name: 'Jeremy Chern',
    title: 'UX/UI Designer',
  },
  {
    name: 'Alex Zeng',
    title: 'UX/UI Designer',
  },
  {
    name: 'Evelyn Chong',
    title: 'UX/UI Designer',
  },
];
