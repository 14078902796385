import z from 'zod';
import { PagingInputSchema } from '../paging';
import { BookingRequestStatusSchema } from './bookingRequest';
import { BookingFormDataSchema } from './createBookingRequest';

export const GetBookingRequestsInputSchema = z
    .object({
        status: BookingRequestStatusSchema,
    })
    .merge(PagingInputSchema);
export type GetBookingRequestsInput = z.infer<typeof GetBookingRequestsInputSchema>;

export const GetBookingRequestInputSchema = z.object({
    bookingRequestId: z.number(),
});
export type GetBookingRequestInput = z.infer<typeof GetBookingRequestInputSchema>;

export const AvailableBookingRequestSchema = z.object({
    id: z.number(),
    preferredDateTime: z.coerce.date(),
    formData: BookingFormDataSchema,
    accepted: z.boolean(),
});
export type AvailableBookingRequest = z.infer<typeof AvailableBookingRequestSchema>;

export const AvailableBookingRequestsSchema = z.array(AvailableBookingRequestSchema);
export type AvailableBookingRequests = z.infer<typeof AvailableBookingRequestsSchema>;

export const GetAvailableBookingRequestInputSchema = z.object({
    bookingRequestId: z.number(),
});
export type GetAvailableBookingRequestInput = z.infer<typeof GetAvailableBookingRequestInputSchema>;
