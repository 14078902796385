import React, { useState } from 'react';
import Option from './components/Option';
import { useBookingContext } from './BookingContext';

const options = [
  { label: 'Yes', value: 'YES' },
  { label: 'No', value: 'NO' },
];

export function Step07() {
  const { setCurrentStep, dataList07, setDataList07 } = useBookingContext();

  return (
    // TODO: ?? Layout for mobile screen
    <>
      <div className="booking-step-title">Are you currently in therapy?</div>
      <div className="grid grid-cols-1 gap-[20px] mt-[30px] w-full mx-auto md:px-[204px]">
        {options.map((option) => (
          <Option
            key={option.value}
            value={option.value}
            label={option.label}
            isSelected={dataList07.includes(option.value)}
            onClick={() => setDataList07([option.value])}
            themeColor="md:bg-[#CCFBF1]"
            mobileThemeColor="bg-[#D8CFFC]"
            height="md:h-[62px]"
            mobileHeight="h-[50px]"
          />
        ))}
      </div>
    </>
  );
}
