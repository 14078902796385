import Button from '@components/Button';
import React from 'react';

import TitleIcon from '@assets/footages/landing/juni-vs-therapy.svg';

import AnonymityImage from '@assets/footages/landing/full-anonymity.svg'; // Replace with your image paths
import AnytimeImage from '@assets/footages/landing/anytime-anywhere.svg';
import ExpertiseImage from '@assets/footages/landing/assure-expertise.svg';
import SupportImage from '@assets/footages/landing/adaptive-support.svg';
import SectionTitle from '@components/SectionTitle';
import { useNavigate } from 'react-router-dom';

export function OfferSection() {
  const navigate = useNavigate();

  const offers = [
    {
      title: 'Anonymous, <br />Undisclosed',
      description:
        'Share your story openly while remaining anonymous throughout the conversation. In this safe space, you don’t have to reveal your identity and can be completely private. Be your authentic self, feel secure, and embrace vulnerability, knowing you won’t be exposed.',
      backgroundImage: AnonymityImage,
    },
    {
      title: 'Instant - Anytime,<br />Anywhere',
      description:
        "Anxiety and trauma can arise anytime, anywhere. We're here for you 24/7, even when no one else is. You'll receive support within 15 minutes of your booking because we believe your mental health shouldn't have to wait.",
      backgroundImage: AnytimeImage,
    },
    {
      title: 'Trained Female <br />Peer Supporters',
      description:
        'With all our Peer Supporters being female, you can feel safe and truly understood. Each Peer Supporter is carefully selected, trained in anxiety and trauma support, and receives regular clinical supervision.',
      backgroundImage: ExpertiseImage,
    },
    {
      title: 'Personalized &<br />Real-Time Care',
      description:
        "Your story is unique. This is why we match you with a Peer Supporter who understands your individual needs to ensure meaningful conversations. You'll have someone by your side to guide you in real time, helping you ease your anxiety and navigate coping.",
      backgroundImage: SupportImage,
    },
  ];

  return (
    <section className="text-center px-[20px] md:px-[76px] py-[20px] mb-[64px]">
      {/* <SectionTitle value="What we offer" /> */}
      <p className="text-5xl md:text-7xl leading-[48px] md:leading-[65px] font-extrabold mb-[40px] md:mb-0">
        What we offer
      </p>

      {/* Offer Grid */}
      <div className="grid grid-cols-1 md:grid-cols-2 gap-[20px] md:gap-[40px] md:my-[40px] mx-auto">
        {offers.map((offer, index) => (
          <div
            key={index}
            className="relative bg-cover bg-center p-[28px] md:p-[32px] md:h-[528px]"
            style={{ backgroundImage: `url(${offer.backgroundImage})` }}
          >
            {/* Overlay Text */}
            <div className="relative z-10 text-left">
              <h3
                className="text-[28px] md:text-6xl leading-[36px] md:leading-[62px] font-extrabold mb-[20px] md:mb-[32px]"
                dangerouslySetInnerHTML={{ __html: offer.title }}
              ></h3>
              <p className="md:text-2xl md:leading-[32px] md:font-regular">
                {offer.description}
              </p>
            </div>
          </div>
        ))}
      </div>

      <div className="text-center mt-[60px] md:mt-0">
        <Button
          label="Learn more"
          className="h-[50px] md:h-[60px] !px-[30px] text-lg md:text-2xl md:leading-[30px] font-semibold rounded-[20px] md:rounded-[24px]"
          onClick={() => navigate('/learn-more')}
        />
      </div>
    </section>
  );
}
