import React from 'react';

interface YouTubeEmbedProps {
  videoId: string;
}

export default function YouTubeEmbedPortrait({ videoId }: YouTubeEmbedProps) {
  return (
    <div className="relative w-full h-full overflow-hidden">
      <iframe
        className="absolute top-0 left-1/2 transform -translate-x-1/2 h-full w-auto"
        src={`https://www.youtube.com/embed/${videoId}`}
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        title="Embedded YouTube Video"
      ></iframe>
    </div>
  );
}
