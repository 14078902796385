import React, { useState } from 'react';
import { AgeGroup, Gender } from '@source/types';
import Option from './components/Option';
import { DropdownField } from './components/DropdownField';
import { useBookingContext } from './BookingContext';

// TODO: Refactor

const options01 = [
  AgeGroup['16_19'],
  AgeGroup['20_24'],
  AgeGroup['25_29'],
  AgeGroup['30_34'],
  AgeGroup['35_39'],
  AgeGroup['40_45'],
  AgeGroup['ABOVE_45'],
];

const optionLabels01 = {
  '16_19': '16-19',
  '20_24': '20-24',
  '25_29': '25-29',
  '30_34': '30-34',
  '35_39': '35-39',
  '40_45': '40-45',
  ABOVE_45: '45 and above',
};

const options02 = [
  Gender.MALE,
  Gender.FEMALE,
  Gender.NON_BINARY,
  Gender.PREFER_NOT_TO_SAY,
  Gender.OTHER,
];

const optionLabels02 = {
  MALE: 'Male',
  FEMALE: 'Female',
  NON_BINARY: 'Non-Binary',
  PREFER_NOT_TO_SAY: 'Prefer not to say',
  OTHER: 'Others',
};

export function Step08() {
  const { age, setAge, gender, setGender } = useBookingContext();

  return (
    <div className="text-center w-full">
      <h1 className="text-base md:text-3xl font-semibold mb-6 md:mb-[15px]">
        What age group do you belong to?
      </h1>
      <DropdownField
        options={options01}
        labels={optionLabels01}
        selectedOption={age}
        onChangeSelection={setAge}
      />

      <h1 className="text-base md:text-3xl font-semibold mb-6 md:mb-[15px]">
        What gender do you belong to?
      </h1>
      <DropdownField
        options={options02}
        labels={optionLabels02}
        selectedOption={gender}
        onChangeSelection={setGender}
      />
    </div>
  );
}
