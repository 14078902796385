import React, { useContext, useState } from 'react';
import { CauseOfAnxiety } from '@source/types';

import { useBookingContext } from './BookingContext';
import Option from './components/Option';

const options = [
  {
    label: 'Sexual Assault/Violence',
    value: CauseOfAnxiety.SEXUAL_ABUSE_OR_HARASSMENT,
  },
  {
    label: 'Relationship/Family Conflict',
    value: CauseOfAnxiety.FAMILY_CONFLICT,
  },
  {
    label: 'Lack of Self-Confidence',
    value: CauseOfAnxiety.PUBLIC_SPEAKING,
  },
  { label: 'Financial Stress', value: CauseOfAnxiety.FINANCIAL_STRESS },
  { label: 'Work/School Stress', value: CauseOfAnxiety.WORK_OR_SCHOOL_STRESS },
  { label: 'Bullying/Isolation', value: CauseOfAnxiety.BULLYING },
  {
    label: 'Phobia (e.g. Fear of Flying)',
    value: CauseOfAnxiety.FEAR_OF_FLYING,
  },
  {
    label: 'Anxiety due to life changes',
    value: CauseOfAnxiety.ANXIETY_DUE_TO_LIFE_CHANGES,
  },
  { label: 'Other', value: CauseOfAnxiety.OTHER },
];

export function Step03() {
  const { setCurrentStep, dataList03, setDataList03 } = useBookingContext();

  return (
    <>
      <div className="booking-step-title">
        What's causing your anxiety or worry, making you seek support?
      </div>
      <div className="grid grid-cols-2 sm:grid-cols-2 lg:grid-cols-3 gap-[10px] md:gap-[20px] mt-[30px] md:mt-[40px]">
        {options.map((option) => (
          <Option
            key={option.value}
            value={option.value}
            label={option.label}
            isSelected={dataList03.includes(option.value)}
            onClick={() => {
              setDataList03([option.value]);
            }}
            themeColor="md:bg-[#D8CFFC]"
            mobileThemeColor="bg-[#D8FFF3]"
            height="md:h-[75px]"
            mobileHeight="h-[70px]"
            shadow="md:shadow-[0px_4px_8px_rgba(0,0,0,0.25)]"
          />
        ))}
      </div>
    </>
  );
}
