import Accordion from '@components/Accordion';
import React from 'react';

export default function ListenerFAQ() {
  const items = [
    {
      title: 'Is my support a one-time event or an ongoing relationship?',
      content: (
        <>
          <p>
            It’s entirely up to the User and you! If a User wishes to connect
            with you again, you have the option to engage with them for ongoing
            support. This continuity can enhance the comfort and trust in your
            interactions.
          </p>
          <br />
          <p>
            If a User requests to speak with you again during an ongoing
            session, please inform our admin at{' '}
            <a href="mailto:anna@heyjuni.com" className="text-blue-800">
              anna@heyjuni.com
            </a>{' '}
            about their request.
          </p>
          <br />
          <p>
            In cases where the User reaches out without your prior knowledge, we
            will notify you and ask for your consent to proceed.
          </p>
        </>
      ),
    },
    {
      title: 'What benefit do I get as a Peer Supporter?',
      content: (
        <>
          <p>Create Impact and Raise Awareness</p>
          <ol className="list-disc list-inside inline-block">
            <li>
              Make a difference by supporting others and promoting mental health
              care.
            </li>
            <li>
              Help to break the stigma around mental health and make a positive
              societal impact.
            </li>
          </ol>
          <br />
          <br />
          <p>Provide Meaningful and Flexible Care</p>
          <ol className="list-disc list-inside inline-block">
            <li>
              Earn financial income while offering emotional support to those in
              need.
            </li>
            <li>
              Gain practical experience, including clocking hours that can
              benefit your career growth.
            </li>
            <li>
              Enjoy the flexibility of working from anywhere and at times that
              suit your schedule.
            </li>
          </ol>
          <br />
          <br />
          <p>Access to Training and Professional Development</p>
          <ol className="list-disc list-inside inline-block">
            <li>
              Participate in specialized training programs with accreditation
              and certification.
            </li>
            <li>
              Benefit from ongoing supervision and emotional support from
              experienced professionals and clinicians.
            </li>
            <li>
              Improved your skills through specialized subject-based learning
              and real-world practice.
            </li>
          </ol>
          <br />
          <br />
          <p>Join a Community and Movement</p>
          <ol className="list-disc list-inside inline-block">
            <li>
              Be part of a supportive community working to democratize mental
              health care.
            </li>
            <li>
              Contribute to making mental health care inclusive by empowering
              everyday individuals to participate in the system.
            </li>
            <li>
              Leave a lasting legacy by advocating for accessible mental health
              support and breaking down barriers together.
            </li>
          </ol>
        </>
      ),
    },
    {
      title: 'What skills do I learn as a Peer Supporter?',
      content: (
        <>
          <p>
            As a Peer Supporter at HeyJuni, you will acquire a range of valuable
            skills that enhance your ability to provide effective peer support:
          </p>
          <ol className="list-decimal list-inside inline-block">
            <br />
            <li>
              <span className="font-bold">Peer-to-Peer Support:</span> Learn how
              to connect with others on a personal level, offering support that
              fosters understanding and camaraderie.
            </li>
            <br />
            <li>
              <span className="font-bold">Empathy and Depth:</span> Develop the
              ability to empathize with Users, allowing you to provide profound
              emotional support and create a safe space for sharing.
            </li>
            <br />
            <li>
              <span className="font-bold">Active Listening:</span> Master the
              art of active listening, which involves fully engaging with the
              User, reflecting on their feelings, and responding thoughtfully.
            </li>
            <br />
            <li>
              <span className="font-bold">
                Cognitive Behavioral Techniques:
              </span>{' '}
              Gain insights into CBT strategies that help individuals identify
              and challenge negative thought patterns, promoting healthier
              mindsets.
            </li>
            <br />
            <li>
              <span className="font-bold">Psychological First Aid:</span> Learn
              how to provide immediate support in times of crisis, equipping you
              with the skills to address emotional distress effectively.
            </li>
            <br />
            <li>
              <span className="font-bold">Mindfulness Techniques:</span> Explore
              mindfulness practices that can enhance your own well-being while
              helping Users manage stress and anxiety.
            </li>
            <br />
            <li>
              <span className="font-bold">Online Support Navigation:</span>{' '}
              Understand the nuances of providing support in an online
              environment, ensuring a seamless and effective experience for
              Seekers.
            </li>
            <br />
            <li>
              <span className="font-bold">Mentoring Other Listeners:</span>{' '}
              Develop leadership skills by mentoring fellow Peer Supporters,
              fostering a collaborative environment where everyone supports one
              another.
            </li>
            <br />
            <li>
              <span className="font-bold">Peer Check-Ins:</span> Learn the
              importance of checking in with your peers, promoting a culture of
              mutual support and accountability within the Listening community.
            </li>
          </ol>
          <br />
          <p>
            By acquiring these skills, you will not only enhance your ability to
            support others but also enrich your personal growth and emotional
            intelligence. This training prepares you to make a meaningful
            difference in the lives of those seeking support.
          </p>
        </>
      ),
    },
    {
      title: 'What does the training curriculum cover?',
      content: (
        <>
          <p>
            Our curriculum provides specialized training on trauma and anxiety
            related to sexual violence, giving you a thorough and compassionate
            understanding of this sensitive subject. Flexible, Remote Learning.
          </p>
          <br />
          <p>
            The program is fully remote, allowing you to complete it at your
            convenience. It includes self-guided modules, pre-recorded video
            content, and regular check-ins with our clinicians and trainers.
          </p>
          <br />
          <p className="font-bold">Core Modules</p>
          <ol className="list-disc list-inside inline-block">
            <li>
              <span className="font-semibold">
                Understanding Sexual Violence:
              </span>{' '}
              Types, consequences, and effects.
            </li>
            <li>
              <span className="font-semibold">Peer Support Essentials:</span>{' '}
              Foundations of empathy, active listening, and supportive
              communication.
            </li>
            <li>
              <span className="font-semibold">Psychological First Aid:</span>{' '}
              Immediate response techniques for emotional support.
            </li>
            <li>
              <span className="font-semibold">CBT Principles:</span> Core
              cognitive-behavioral techniques for peer support.
            </li>
            <li>
              <span className="font-semibold">
                Effective Online Communication:
              </span>{' '}
              Building connections and trust in virtual support settings.
            </li>
          </ol>
        </>
      ),
    },
    {
      title:
        'Do I receive an accreditation or certification after the training/course?',
      content: (
        <>
          <p>
            Yes, upon completing the training, you will receive a certification
            from us. The certification will be endorsed by a qualified trainer
            and expert in the field, recognizing your skills and the successful
            completion of the program.
          </p>
        </>
      ),
    },
    {
      title: 'How long is the Peer Supporters course/curriculum?',
      content: (
        <>
          <p>
            The onboarding program for Peer Supporters are designed to be
            flexible, allowing you to progress at your own pace. This mandatory
            curriculum covers essential skills and knowledge necessary for
            effective peer support.
          </p>
          <br />
          <p>
            However, we encourage you to complete it as quickly as possible so
            you can start providing support and gaining actual skills sooner.
            The faster you finish, the sooner you can begin practicing.
          </p>
          <br />
          <p>
            Once you complete onboarding, we offer ongoing advanced training
            programs to further develop your skills and keep you updated on the
            latest best practices in peer support.
          </p>
        </>
      ),
    },
    {
      title:
        'Do I need specific requirements/qualifications to join the course?',
      content: (
        <div>
          <p>
            You don't need professional qualifications to join the course. At
            HeyJuni, we believe that everyone has the potential to be part of
            the mental health care system.
          </p>
          <br />
          <p className="text-3xl font-semibold">
            However to become a Peer Supporter, you should bring these values:
          </p>
          <ul className="list-disc list-inside">
            <li>A basic level of empathy</li>
            <li>A genuine desire to help others</li>
            <li>Integrity and a willingness to learn</li>
            <li>Familiarity with digital platforms</li>
            <li>
              An intention to make a positive impact on people's lives. We value
              diverse backgrounds and experiences, as they enrich the support we
              provide.
            </li>
          </ul>
          <br />
          <p className="text-3xl font-semibold">
            However, if you’d like to become a Peer Supporter on our platform,
            these are some further criteria you should bring:
          </p>
          <ul className="list-disc list-inside">
            <li>Be at least 18 years old</li>
            <li>Identify as female</li>
            <li>Complete the HeyJuni training curriculum</li>
            <li>Have basic tech skills</li>
            <li>Possess strong communication abilities</li>
            <li>
              Ideally have personal or professional experience related to mental
              health or support services.
            </li>
          </ul>
        </div>
      ),
    },
    {
      title: 'How do I get booked as a Peer Supporter?',
      content: (
        <>
          <p>
            When a booking request is received, you will be notified promptly.
            You can then decide whether to accept the opportunity to provide
            support. Once you confirm your willingness to take on the session,
            our system will identify the best match from all available Peer
            Supporters based on their expertise and the User’s needs. The most
            suitable Peer Supporter will be assigned to the User for that
            session.
          </p>
        </>
      ),
    },
    {
      title:
        'What if a Support Seeker is having suicide and self-harm ideations?',
      content: (
        <>
          <p>
            If someone is in crisis, engaging in self-harm, or having suicidal
            thoughts, it is essential for them to seek immediate help from
            specialized services. At HeyJuni, we do not provide support in these
            situations.
          </p>
          <br />
          <p>
            In such cases, the Peer Supporter should inform the User that the
            conversation must end. They should then advise the User to reach out
            to professional crisis support services or helplines that are
            specifically trained to handle these issues.
          </p>
          <br />
          <p>
            Following this, the Peer Supporter is required to inform their
            clinician, mentor, or supervisor about the situation. This will
            ensure appropriate supervision and check-ins regarding the matter.
          </p>
        </>
      ),
    },
    {
      title: 'Who are mentors/supervisors?',
      content: (
        <>
          <p>Clinicians, trainers, lead Peer Supporters.</p>
        </>
      ),
    },
    {
      title: 'What are important "house rules" or Peer Supporters?',
      content: (
        <>
          <p>
            It is of utmost necessity to accept and follow the rules, and to
            agree to our terms and conditions. As a Peer Supporter, you are the
            owner of your actions and responsibilities. Therefore it is
            important that you read through our terms and conditions and apply
            the rules within the HeyJuni platform and community. Adhering to our
            rules and terms and conditions is crucial for a smooth experience
            within the HeyJuni platform and community. Violation of the rules
            and terms and conditions will result in the termination of your
            membership.
          </p>
          <br />
          <p className="font-bold">Ensure Safety:</p>
          <ol className="list-disc list-inside inline-block">
            <li>
              Create and maintain a safe environment for yourself, the User, and
              your peers.
            </li>
            <li>
              Interact with sincerity, treating others with dignity, respect,
              and care.
            </li>
          </ol>
          <br />
          <p className="">
            Share only appropriate and safe content (text, voice files, images,
            videos) that are compliant with our rules and terms.
          </p>
          <ol className="list-disc list-inside inline-block">
            <li>
              We take safety seriously, and any violations and breaches may lead
              to deactivation of your HeyJuni profile and account.
            </li>
          </ol>
          <br />
          <br />
          <p className="font-bold">Respect Anonymity:</p>
          <ol className="list-disc list-inside inline-block">
            <li>
              Do not ask support seekers about their identity, personal
              information or any other information that disclose their privacy.
            </li>
            <li>
              Avoid asking for or sharing visual content from a User. - Keep
              identifiable information about Users confidential and do not share
              these with other members in the HeyJuni community.
            </li>
            <li>
              Do not share your own personal information or details about
              yourself, remain professional at all times.
            </li>
            <li>
              Breaching anonymity guidelines may result in deactivation of your
              profile and account.
            </li>
          </ol>
          <br />
          <br />
          <p className="font-bold">Foster Support:</p>
          <ol className="list-disc list-inside inline-block">
            <li>
              Offer genuine support to Users and your peers, demonstrating
              respect and kindness.
            </li>
            <li>
              Practice empathy, mindfulness, and considerate behavior throughout
              the platform.
            </li>
            <li>
              Allow yourself to receive support when you feel overwhelmed or
              triggered.
            </li>
          </ol>
          <br />
          <br />
          <p>
            By accepting and abiding by these guidelines, you contribute to
            creating a positive and supportive environment within the HeyJuni
            community.
          </p>
        </>
      ),
    },
    {
      title: 'Is there a cost for the training?',
      content: (
        <div>
          <ul className="list-disc list-inside">
            <li>
              One-Time Fee: The training program fee is 150 SGD. This one-time
              payment covers all materials, program organization, and trainer
              compensation.
            </li>
            <li>
              This fee ensures that you receive high-quality, consistent
              training from start to finish.
            </li>
          </ul>
        </div>
      ),
    },
  ];

  return (
    <>
      <p className="text-5xl font-extrabold mb-10">Peer Supporters</p>
      <Accordion items={items} />
    </>
  );
}
