import React from 'react';
import heroImage from '@assets/footages/landing/hero.svg';
import heroImageMobile from '@assets/footages/landing/hero-mobile.svg';
import Button from '@components/Button';
import { useNavigate } from 'react-router-dom';

export function IntroSection() {
  const navigate = useNavigate();

  return (
    <div className="flex flex-col lg:flex-row mb-[64px]">
      <div className="md:hidden pl-[26px]">
        <img src={heroImageMobile} alt="Hero" className="w-full h-[413]" />
      </div>

      <div className="flex flex-col px-[26px] md:pl-[76px] md:pr-0 mt-[20px] md:mt-[106px] md:pt-0">
        <div className="hidden md:block font-extrabold md:text-8xl md:leading-[85px] tracking-[-0.03em]">
          <p>Share Your Story.</p>
          <p>Begin To Heal.</p>
        </div>

        <div className="md:hidden font-extrabold text-5xl leading-[48px]">
          <p>Share Your Story.</p>
          <p>Begin To Heal.</p>
        </div>

        <div className="font-bold md:font-semibold text-xl md:text-3xl mt-[20px]">
          <p>Find Trauma Support Anonymously Within 15 Minutes.</p>
        </div>

        <p className="mt-[20px] md:mt-[28px] font-regular text-base md:text-2xl md:pr-[68px] leading-[22px] md:leading-[32px]">
          Are you struggling to cope with your trauma and anxiety from sexual
          assault? Talk to a Peer Supporter in a safe space to confide, find
          guidance, and ease your negative feelings when you need it most. Peer
          support for women, by women.
        </p>
        <Button
          label="Learn More"
          className="font-semibold mt-[20px] md:mt-[28px] text-lg md:text-2xl h-[50px] md:h-[60px] w-[154px] md:w-[174px] rounded-[20px] md:rounded-2xl"
          onClick={() => navigate('/learn-more')}
        />
      </div>
      <img
        src={heroImage}
        alt="Hero"
        className="hidden lg:block h-full object-contain"
      />
    </div>
  );
}
