import z from 'zod';
import { PagingInputSchema } from './paging';

export enum Gender {
    MALE = 'MALE',
    FEMALE = 'FEMALE',
    NON_BINARY = 'NON_BINARY',
    PREFER_NOT_TO_SAY = 'PREFER_NOT_TO_SAY',
    OTHER = 'OTHER',
}
export const GenderSchema = z.nativeEnum(Gender);

export const UserBasicInfoSchema = z.object({
    email: z.string(),
    gender: GenderSchema,
    preferredName: z.string(),
});
export type UserBasicInfo = z.infer<typeof UserBasicInfoSchema>;

export const UserBasicInfoWithIdSchema = z
    .object({
        id: z.number(),
    })
    .merge(UserBasicInfoSchema);
export type UserBasicInfoWithId = z.infer<typeof UserBasicInfoWithIdSchema>;

export enum Role {
    Admin = 1,
    Speaker = 2,
    Volunteer = 3,
}
export const RoleSchema = z.nativeEnum(Role);

export const UserSchema = z
    .object({
        id: z.number(),
        phone: z.string().nullable(),
        firstName: z.string().nullable(),
        lastName: z.string().nullable(),
        isActive: z.boolean(),
        role: z.number(),
    })
    .merge(UserBasicInfoSchema);
export type User = z.infer<typeof UserSchema>;

export const UsersSchema = z.array(UserSchema);
export type Users = z.infer<typeof UsersSchema>;

////////////////////////////////////////////////////////////////////////////////////////

export const GetUsersPagingInputSchema = z.object({
    role: RoleSchema,
});
export type GetUsersPagingInput = z.infer<typeof GetUsersPagingInputSchema>;

export const GetUsersInputSchema = z
    .object({
        role: RoleSchema,
    })
    .merge(PagingInputSchema);
export type GetUsersInput = z.infer<typeof GetUsersInputSchema>;
