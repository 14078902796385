import ReactDOM from 'react-dom';

export function Spinner() {
  return ReactDOM.createPortal(
    <>
      <div className="overlay-container bg-black bg-opacity-20"></div>
      <div className="fixed top-1/2 left-1/2 z-[9999] transform -translate-x-1/2 -translate-y-1/2">
        <div className="spinner"></div>
      </div>
    </>,
    document.body // Render directly into the body
  );
}
