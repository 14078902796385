import { TimeOption } from '@src/types/common';
import { format, toZonedTime, fromZonedTime } from 'date-fns-tz';

// Mapping of UTC offsets to representative time zone names
const timezoneMapping: Record<string, string> = {
  '-12': 'Etc/GMT+12',
  '-11': 'Pacific/Midway',
  '-10': 'Pacific/Honolulu',
  '-9': 'America/Anchorage',
  '-8': 'America/Los_Angeles',
  '-7': 'America/Denver',
  '-6': 'America/Chicago',
  '-5': 'America/New_York',
  '-4': 'America/Halifax',
  '-3': 'America/Argentina/Buenos_Aires',
  '-2': 'Atlantic/South_Georgia',
  '-1': 'Atlantic/Azores',
  '0': 'UTC',
  '1': 'Europe/Paris',
  '2': 'Europe/Athens',
  '3': 'Europe/Moscow',
  '4': 'Asia/Dubai',
  '5': 'Asia/Karachi',
  '5.5': 'Asia/Kolkata', // New Delhi, Mumbai, Colombo
  '5.75': 'Asia/Kathmandu', // Kathmandu
  '6': 'Asia/Dhaka',
  '6.5': 'Asia/Yangon', // Yangon
  '7': 'Asia/Jakarta',
  '8': 'Asia/Singapore',
  '9': 'Asia/Tokyo',
  '9.5': 'Australia/Adelaide', // Adelaide, Darwin
  '10': 'Australia/Sydney',
  '11': 'Pacific/Noumea',
  '12': 'Pacific/Fiji',
  '13': 'Pacific/Tongatapu', // Nuku'alofa, Apia
  '14': 'Pacific/Kiritimati', // Kiritimati
};

/**
 * Converts a date-time from one timezone to another timezone.
 *
 * @param date - The date-time to convert.
 * @param fromTimeZone - The source timezone (e.g., 'Asia/Singapore').
 * @param toTimeZone - The target timezone (e.g., 'America/New_York').
 * @returns A Date object in the target timezone.
 */
function convertTimeZone(
  date: Date,
  fromTimeZone: string,
  toTimeZone: string
): Date {
  // Convert the source date-time to UTC
  const utcDate = fromZonedTime(date, fromTimeZone);

  // Convert the UTC date-time to the target timezone
  return toZonedTime(utcDate, toTimeZone);
}

/**
 * Checks if the provided or current time in a specified time zone falls between 1:00 AM and 6:00 AM,
 * rounding up to the nearest hour if the minutes are 30 or more.
 *
 * @param {string} selectedTimeZone - The timezone offset as a string (e.g., "-7", "0", "8").
 *                                    This should be an integer value from -12 to 11, representing the
 *                                    offset from UTC in hours.
 * @param {Date} selectedDate - The selected date (used for its year, month, and day).
 *                              Only the date part is used, so time in this Date object is ignored.
 * @param {StartTime} startTime - An object representing the selected start time, with:
 *                                - `label`: a human-readable time label (e.g., "12:00 AM").
 *                                - `value`: a 24-hour time string (e.g., "00:00", "13:30").
 * @returns {boolean} - Returns `true` if the rounded provided/current time in the specified timezone
 *                      is between 1:00 AM and 6:00 AM, otherwise returns `false`.
 */
export function isOffTime(
  selectedTimeZone?: string | null,
  selectedDate?: Date | null,
  startTime?: TimeOption
): boolean {
  const timeZone = 'Asia/Singapore'; // Default timezone for UTC+8 if not specified
  const offsetInHours = selectedTimeZone ? parseInt(selectedTimeZone, 10) : 8;

  let dateWithTime: Date;

  if (selectedDate && startTime) {
    // Parse the selected date and start time to create a Date object with the specified time
    const [hours, minutes] = startTime.value.split(':').map(Number);
    dateWithTime = new Date(selectedDate);
    dateWithTime.setHours(hours, minutes, 0, 0);
  } else {
    // Use current time if no input provided
    dateWithTime = new Date();
  }

  const zonedDate = selectedTimeZone
    ? convertTimeZone(dateWithTime, timezoneMapping[selectedTimeZone], timeZone)
    : toZonedTime(dateWithTime, timeZone);

  const currentZonedDate = toZonedTime(new Date(), timeZone);
  const isSameDate =
    format(zonedDate, 'yyyy-MM-dd') === format(currentZonedDate, 'yyyy-MM-dd');
  if (!isSameDate) return false;

  // Extract the hours and minutes from the zoned time
  let hours = parseInt(format(zonedDate, 'H'), 10);
  const minutes = parseInt(format(zonedDate, 'm'), 10);

  // Round up the hour if minutes >= 30
  if (minutes >= 30) {
    hours += 1;
  }

  // Check if the hour is within the range of 1:00 AM to 6:00 AM
  return hours >= 1 && hours <= 6;
}

// Utility to get client's current timezone offset in hours
export const getClientTimeZoneOffset = (): string => {
  const offset = -new Date().getTimezoneOffset() / 60;
  return offset.toString();
};

/**
 * Formats the given date and time with a specified timezone offset into an ISO 8601 string in UTC.
 *
 * @param {string} selectedTimeZone - The timezone offset as a string (e.g., "-7", "0", "8").
 *                                    This should be an integer value from -12 to 11, representing the
 *                                    offset from UTC in hours.
 * @param {Date} selectedDate - The selected date (used for its year, month, and day).
 *                              Only the date part is used, so time in this Date object is ignored.
 * @param {StartTime} startTime - An object representing the selected start time, with:
 *                                - `label`: a human-readable time label (e.g., "12:00 AM").
 *                                - `value`: a 24-hour time string (e.g., "00:00", "13:30").
 * @returns {string} - The ISO 8601 formatted date and time in UTC, as a string (e.g., "2024-12-12T21:15:00Z").
 *
 * @example
 * const selectedTimeZone = "7"; // UTC+7
 * const selectedDate = new Date('2024-11-11'); // November 11, 2024
 * const startTime = { label: '12:00 AM', value: '00:00' };
 *
 * "2024-11-10T17:00:00Z" (example output in UTC)
 */
export function formatToISO8601(
  selectedTimeZone: string | null,
  selectedDate: Date | null,
  startTime: TimeOption | null
): string {
  if (!selectedTimeZone || !selectedDate || !startTime) return '';

  // const timezoneOffset = parseInt(selectedTimeZone, 10);
  const timezoneOffset = parseFloat(selectedTimeZone);
  const year = selectedDate.getFullYear();
  const month = selectedDate.getMonth();
  const day = selectedDate.getDate();

  const [hours, minutes] = startTime.value.split(':').map(Number);
  const localDate = new Date(Date.UTC(year, month, day, hours, minutes));

  // Split timezoneOffset into hours and minutes
  const offsetHours = Math.trunc(timezoneOffset);
  const offsetMinutes = (timezoneOffset - offsetHours) * 60;

  // Adjust UTC time for the offset
  localDate.setUTCHours(localDate.getUTCHours() - offsetHours);
  localDate.setUTCMinutes(localDate.getUTCMinutes() - offsetMinutes);

  return localDate.toISOString();
}
