import Button from '@components/Button';
import React from 'react';
import versus from '@assets/footages/landing/vs.png';
import logo from '@assets/logo.svg';
import { useNavigate } from 'react-router-dom';
import { openWhatsapp } from '@src/utils/open-whatsapp';

const comparisonData = [
  {
    title: 'STIGMA',
    leftText:
      'Provides an anonymous, judgment-free space for you to explore your emotions and feelings.',
    rightText:
      'You might be feeling intimidated due to stigmatization or are simply not ready yet for therapy.',
  },
  {
    title: 'COST',
    leftText:
      "HeyJuni's support is readily available and budget-friendly, ensuring your access without hassles.",
    rightText:
      'Therapy costs can be a burden if you lack financial support or have limited insurance coverage.',
  },
  {
    title: 'TIME',
    leftText:
      'Day or night, with no appointments or planning needed. You can reach out whenever you need HeyJuni.',
    rightText:
      'Therapy demands emotional commitments: time-consuming searches, long waits & motivational efforts.',
  },
  {
    title: 'COMMITMENT',
    leftText:
      "HeyJuni's support & service is tailored to your own pace. Whether you need us frequently or occasionally.",
    rightText:
      'The notion of committing to a long-term relationship with a therapist can be overwhelming & feel unfamiliar.',
  },
  {
    title: 'PERSONALISATION',
    leftText:
      'Quick, personalized support by matching you with a Peer Supporter for your specific needs while remaining anonymous.',
    rightText:
      'Building quick rapport with a therapist takes time & can be hindered by trust/cultural issues, impeding bonding.',
  },
];

export function ComparisonSection() {
  const navigate = useNavigate();

  return (
    <section className="text-center py-[20px] md:pt-[40px] mb-[64px]">
      {/* Header with logos */}
      <p className="hidden md:block text-5xl md:text-7xl md:leading-[65px] font-extrabold">
        Why we don’t offer therapy
      </p>

      <div className="mx-auto md:mx-[76px] md:my-[40px] md:border md:border-gray-300">
        <div className="flex items-center h-[80px] md:h-[100px]">
          <div className="flex-1 flex justify-end md:justify-center items-center pr-[30px] md:pr-0">
            <img
              src={logo}
              alt="HeyJuni"
              className="h-[45px] md:h-[54px] w-[65px] md:w-[81px]"
            />
          </div>
          <img
            src={versus}
            alt="vs"
            className="flex-shrink-0 h-[40px] md:h-[60px] w-[40px] md:w-[60px]"
          />
          <div className="flex-1 flex justify-start md:justify-center items-center pl-[30px] md:pl-0">
            <p className="text-4xl md:text-5xl font-extrabold">Therapy</p>
          </div>
        </div>

        {/* Comparison Table */}
        <div>
          {comparisonData.map(({ title, leftText, rightText }, index) => (
            <div key={index}>
              <div className="bg-[#181818] text-white text-base md:text-3xl md:leading-[30px] font-semibold py-2 px-4">
                {title}
              </div>
              <div className="flex">
                <div className="text-sm md:text-lg md:font-regular md:leading-[26px] text-left w-1/2 bg-teal-100 py-[20px] px-[28px] md:p-[40px]">
                  <p>{leftText}</p>
                </div>
                <div className="text-sm md:text-lg md:font-regular md:leading-[26px] text-left w-1/2 bg-white py-[20px] px-[28px] md:p-[40px]">
                  <p>{rightText}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>

      <div className="mt-[35px] md:mt-0 md:pb-[40px]">
        <p className="text-base md:text-4xl font-semibold md:bold mb-[18px] md:mb-[20px]">
          Still curious?
        </p>
        <Button
          label="WhatsApp Us"
          className="h-[50px] w-[168px] md:h-[60px] md:w-[192px] text-lg md:text-2xl font-semibold rounded-[20px] md:rounded-[24px] md:rounded-2xl"
          onClick={openWhatsapp}
        />
      </div>
    </section>
  );
}
