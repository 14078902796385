import React from 'react';
import pricingBackground from '@assets/footages/pricing/pricing-bg.svg';
import pricingBackgroundMobile from '@assets/footages/pricing/pricing-bg-mobile.svg';
import Card from './Card';
import Button from '@components/Button';
import TypingImage from '@assets/footages/pricing/typing-image.svg';
import { useNavigate } from 'react-router-dom';

import PlanBg01 from '@assets/footages/pricing/pay_as_you_go.svg';
import PlanBg02 from '@assets/footages/pricing/monthly_plan.svg';
import PlanBg03 from '@assets/footages/pricing/unlimited_plan.svg';

import PlanBg01Mobile from '@assets/footages/pricing/pay_as_you_go-mobile.svg';
import PlanBg02Mobile from '@assets/footages/pricing/monthly_plan-mobile.svg';
import PlanBg03Mobile from '@assets/footages/pricing/unlimited_plan-mobile.svg';

import './index.css';
import { openWhatsapp } from '@src/utils/open-whatsapp';

export default function Pricing() {
  const navigate = useNavigate();

  return (
    <div className="relative">
      <div
        className="hidden md:block absolute top-0 right-0"
        style={{ zIndex: -1 }}
      >
        <img src={pricingBackground} alt="pricing-bg" />
      </div>

      <div
        className="md:hidden absolute top-0 right-0 w-full"
        style={{ zIndex: -1 }}
      >
        <img
          src={pricingBackgroundMobile}
          alt="pricing-bg"
          className="w-full h-full object-cover"
        />
      </div>

      <div className="px-[40px] md:px-[76px]">
        <div className="pt-[58px] md:pt-[88px] pb-[20px] md:pb-[40px] font-extrabold text-6xl leading-[58px] md:text-8xl md:leading-[85px]">
          <p>Your Safe Space,</p>
          <p>Anytime You Need It.</p>
        </div>
        <div className="mb-[64px] md:mb-[48px] text-lg md:text-2xl md:leading-[32px]">
          Explore our plans and pricing
        </div>
      </div>

      <div className="md:py-[20px] px-[30px] md:px-[60px] grid grid-cols-1 md:grid-cols-3 gap-[40px]">
        {cardData.map((card, index) => (
          <Card
            key={index}
            title={card.title}
            src={card.src}
            srcMobile={card.srcMobile}
            price={card.price}
            priceFrequency={card.priceFrequency}
            description={card.description}
            features={card.features}
            buttonText={card.buttonText}
            onClick={() => {
              if (index === 0) {
                navigate('/booking');
              } else {
                openWhatsapp();
              }
            }}
          />
        ))}
      </div>

      <div className="pt-[20px] md:pt-[12px] pb-[40px] md:pb-[64px] px-[34px] md:px-[76px] text-[11px] md:text-[12px] leading-[16px] md:leading-[20px] text-[#00000080]">
        <span className="text-red-500">*</span>
        Deciding how often to attend support session is a collaborative process
        between you and your Peer Supporter and based on your own needs. For
        your well-being, we recommend one session per day, allowing you time to
        rest and reflect on what you’ve shared. To support this balance,
        sessions are currently limit at one per day.
      </div>

      <div className="relative">
        <div className="md:pl-[76px] md:py-[116px] md:w-[418px] box-content text-center md:text-left">
          <div className="font-extrabold text-5xl leading-[45px] mb-[20px]">
            HeyJuni for Enterprise
          </div>
          <div className="px-[30px] md:px-0 mb-[30px] md:mb-[40px] text-lg leading-[26px]">
            Built for larger organisations who wants to provide peer support to
            their employees
          </div>
          <Button
            label="Contact Sales"
            className="mb-[20px] md:mb-0 h-[50px] md:h-[60px] px-[30px] rounded-[20px] font-semibold text-lg md:text-2xl"
            onClick={() => navigate('/contact-us')}
          />
        </div>

        <div className="hidden md:block absolute top-0 right-0">
          <img src={TypingImage} alt="Typing Image" />
        </div>
      </div>
    </div>
  );
}

export const cardData = [
  {
    title: 'Pay as You Go',
    src: PlanBg01,
    srcMobile: PlanBg01Mobile,
    price: 'SGD $29.90',
    priceFrequency: (
      <>
        <div>Per</div>
        <div>Session</div>
      </>
    ),
    description:
      'Be flexible and pay for a single sessions you need in the moment.',
    features: [
      '24/7 instant emotional support whenever and wherever you need it',
      "Access to HeyJuni's WhatsApp customer support",
    ],
    buttonText: 'Book Session',
  },
  {
    title: 'Monthly Plan',
    src: PlanBg02,
    srcMobile: PlanBg02Mobile,
    price: 'SGD $49.90',
    priceFrequency: (
      <>
        <div>Per</div>
        <div>Month</div>
      </>
    ),
    description:
      'Enjoy more benefits and reduced session fees with our Monthly Plan.',
    features: [
      <div>
        <span className="font-bold underline">4 Sessions,</span> each 50 minutes
      </div>,
      '24/7 instant emotional support whenever and wherever you need it',
      "Access to HeyJuni's WhatsApp customer support",
    ],
    buttonText: 'Contact Us to Purchase',
  },
  {
    title: 'Unlimited Plan',
    src: PlanBg03,
    srcMobile: PlanBg03Mobile,
    price: 'SGD $499.90',
    priceFrequency: (
      <>
        <div>Per</div>
        <div>Year</div>
      </>
    ),
    description:
      'Enjoy unlimited access to sessions all year long. Connect with your Peer Supporter as often as you like.',
    features: [
      <div>
        <span className="font-bold underline">UNLIMITED Sessions,</span> each 50
        minutes
      </div>,
      <div className="base-text relative font-bold underline">
        All year long - 365 days support for you
        <span className="text-red-500">*</span>
        <div className="tooltip absolute top-0 left-0 z-50 bg-white w-[360px] h-[542px] p-[50px] rounded-[20px] shadow-lg">
          <h2 className="text-center font-semibold text-xl underline mb-[20px]">
            All year long - 365 days support for you*
          </h2>

          <p className="text-gray-700 text-base leading-[24px] font-regular mb-[50px]">
            *Deciding how often to attend support session is a collaborative
            process between you and your Peer Supporter and based on your own
            needs. For your well-being, we recommend one session per day,
            allowing you time to rest and reflect on what you’ve shared. To
            support this balance, sessions are currently limited to one per day.
          </p>

          <div className="text-center">
            <Button
              label="Okay"
              className="font-semibold md:text-xl md:h-[44px] md:px-[20px] md:rounded-[20px]"
            />
          </div>
        </div>
      </div>,
      '24/7 instant emotional support whenever and wherever you need it',
      "Access to HeyJuni's WhatsApp customer support",
    ],
    buttonText: 'Contact Us to Purchase',
  },
];
