import React from 'react';

import { IntroSection } from './intro-section';
import { WorkFlowSection } from './work-flow-section';
import { QuizSection } from './quiz-section';
import { ComparisonSection } from './comparison-section';
import { OfferSection } from './offer-section';
import { SubscribeSection } from './subscribe-section';
import { TestimonialsSection } from './testimonials-section';
import { GetInvolvedSection } from './get-involved-section';

function Home() {
  return (
    <div className="overflow-hidden">
      <IntroSection />
      <WorkFlowSection />
      <QuizSection />
      <ComparisonSection />
      <OfferSection />
      <TestimonialsSection />
      <GetInvolvedSection />
      <SubscribeSection />
    </div>
  );
}

export default Home;
