import { ReactNode } from 'react';
import Button from '@components/Button';
import CheckIcon from '@assets/footages/pricing/check-icon.svg';

interface CardProps {
  title: string;
  src: string;
  srcMobile: string;
  price: string;
  priceFrequency: ReactNode;
  description: string;
  features: (string | ReactNode)[];
  buttonText: string;
  onClick: () => void;
}

function Card({
  title,
  src,
  srcMobile,
  price,
  priceFrequency,
  description,
  features,
  buttonText,
  onClick,
}: CardProps) {
  return (
    <div className="relative overflow-hidden bg-white rounded-[20px] shadow-lg py-[32px] px-[20px] md:p-[50px] flex flex-col items-center text-center border">
      <div className="hidden md:block absolute top-0 left-0 w-full">
        <img src={src} alt={title} className="w-full" />
      </div>

      <div className="md:hidden absolute top-0 left-0 w-full">
        <img src={srcMobile} alt={title} className="w-full" />
      </div>

      <div className="flex-1">
        <h2 className="font-bold text-xl md:text-4xl leading-[26px] md:leading-[40px] mb-[35px] md:mb-[48px]">
          {title}
        </h2>

        <div className="flex flex-row items-center justify-center gap-[10px] md:gap-[8px] mb-[20px] md:mb-[48px]">
          <span className="font-extrabold text-5xl leading-[45px]">
            {price}
          </span>
          <div className="text-left font-semibold md:text-lg">
            {priceFrequency}
          </div>
        </div>

        <div className="mb-[95px] md:mb-[103px] md:min-h-[84px]">
          {description}
        </div>

        <h3 className="md:text-lg font-semibold mb-[10px]">What to Expect:</h3>

        <div className="text-left space-y-[10px]">
          {features.map((feature, index) => (
            <div
              key={index}
              className="flex flex-row items-start gap-[15px] text-[13px] md:text-lg md:leading-[26px]"
            >
              <div className="w-[20px] h-[20px]">
                <img
                  src={CheckIcon}
                  alt="CheckIcon"
                  className="w-[20px] h-[20px]"
                />
              </div>
              <div className="flex-1">{feature}</div>
            </div>
          ))}
        </div>
      </div>

      <Button
        label={buttonText}
        className="mt-[50px] h-[34px] md:h-[60px] rounded-[11px] md:rounded-[20px] px-[30px] font-semibold text-base md:text-2xl"
        onClick={onClick}
      />
    </div>
  );
}

export default Card;
