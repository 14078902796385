import React from 'react';
import { IntroSection } from './intro-section';
import RealWomenSection from './real-women-section';
import { HealingSection } from './healing-section';
import { WorkFlowSection } from '../Home/work-flow-section';
import ListenersPromiseSection from './listeners-promise-section';
import FaqSection from './faq-section';
import TalkToListenerSection from './talk-to-listener-section';
import { MeetSupportersSection } from '../BecomeAListener/meet-some-supporters-section';
import { useNavigate } from 'react-router-dom';

// TODO: Create common button used for all places in this page

function LearnMore() {
  const navigate = useNavigate();

  return (
    <div>
      <IntroSection />
      <HealingSection />
      <RealWomenSection />
      <MeetSupportersSection
        actionLabel="Book a Peer Supporter"
        handleAction={() => navigate('/booking')}
      />
      <ListenersPromiseSection />
      <WorkFlowSection />
      <FaqSection />
      <TalkToListenerSection />
    </div>
  );
}

export default LearnMore;
