import React from 'react';
import clsx from 'clsx';

interface OptionProps {
  value: string;
  label: string;
  isSelected: boolean;
  onClick: (value: string) => void;
  themeColor?: string;
  mobileThemeColor?: string;
  height?: string;
  mobileHeight?: string;
  shadow?: string;
  disabled?: boolean;
}

export default function Option({
  value,
  label,
  isSelected,
  onClick,
  themeColor,
  mobileThemeColor,
  height,
  mobileHeight,
  shadow,
  disabled,
}: OptionProps) {
  return (
    <button
      onClick={() => onClick(value)}
      className={clsx(
        'w-full rounded-[10px] md:rounded-[15px] text-center font-semibold text-sm md:text-lg transition-colors duration-300',
        height,
        mobileHeight,
        !isSelected && themeColor,
        !isSelected && mobileThemeColor,
        shadow,
        !isSelected &&
          'md:hover:bg-[#9DFFE2] md:hover:shadow-none md:text-black',
        isSelected && 'bg-[#FEE60C] text-black',
        'px-[15px]',
        disabled && '!text-white !bg-[#C4C4C4] !cursor-default'
      )}
      // enable dynamic newlines
      dangerouslySetInnerHTML={{ __html: label }}
    ></button>
  );
}

// TODO: Padding is temp solution
