import { z } from 'zod';

export const ResetPasswordInputSchema = z.object({
    email: z.string().email(),
});
export type ResetPasswordInput = z.infer<typeof ResetPasswordInputSchema>;

export const SetPasswordInputSchema = z.object({
    resetPasswordToken: z.string(),
    // No email needed, as included in the token
    password: z.string(),
});
export type SetPasswordInput = z.infer<typeof SetPasswordInputSchema>;
