import React, { useContext, useState } from 'react';
import { FeelingDescription } from '@source/types';

import { useBookingContext } from './BookingContext';
import Option from './components/Option';

const options = [
  {
    label: 'Nervous, anxious<br/> or on edge',
    value: FeelingDescription.NERVOUS,
  },
  {
    label: 'Unable to relax<br/>or find steadiness',
    value: FeelingDescription.UNABLE_TO_RELAX,
  },
  {
    label: 'As if something awful<br/>might happen',
    value: FeelingDescription.SOMETHING_AWFUL,
  },
  {
    label: 'Unable to stop<br/>or control worrying',
    value: FeelingDescription.UNCONTROLLED_WORRYING,
  },
  {
    label: 'Restless that<br/>it is hard to sit still',
    value: FeelingDescription.RESTLESS,
  },
  {
    label: 'Unable to<br/>concentrate',
    value: FeelingDescription.UNABLE_TO_CONCENTRATE,
  },
  {
    label: 'The urge to<br/>escape the situation',
    value: FeelingDescription.URGE_TO_ESCAPE,
  },
  {
    label: 'Uncontrollable and<br/>have obsessive thoughts',
    value: FeelingDescription.OBSESSIVE_THOUGHTS,
  },
  { label: 'Other', value: FeelingDescription.TOO_MANY_WORRIES },
];
// TODO: Fix this anchor TOO_MANY_WORRIES -> OTHER

export function Step04() {
  const { setCurrentStep, dataList04, setDataList04 } = useBookingContext();

  return (
    <>
      <div className="booking-step-title">
        Choose the descriptions that match how you're feeling right now
      </div>
      <div className="grid grid-cols-2 sm:grid-cols-2 lg:grid-cols-3 gap-[10px] md:gap-y-[16px] md:gap-x-[20px] mt-[30px] md:mt-[40px]">
        {options.map((option) => (
          <Option
            key={option.value}
            value={option.value}
            label={option.label}
            isSelected={dataList04.includes(option.value)}
            onClick={() => {
              let newList = [...dataList04];
              if (!dataList04.includes(option.value))
                newList.push(option.value);
              else {
                newList = [...newList.filter((item) => item !== option.value)];
              }

              setDataList04(newList);
            }}
            themeColor="md:bg-[#D8FFF3]"
            mobileThemeColor="bg-[#D8CFFC]"
            // height="md:h-[90px]"
            height="md:h-[80px]" // Change to fit the screen
            mobileHeight="h-[70px]"
          />
        ))}
      </div>
    </>
  );
}
