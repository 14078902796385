import React, { useEffect } from 'react';

export const Snackbar = (args: {
  message: string;
  show: boolean;
  onClose: () => void;
}) => {
  const { message, onClose, show } = args;
  useEffect(() => {
    if (show) {
      const timer = setTimeout(() => {
        onClose();
      }, 3000); // Snackbar will close after 3 seconds

      return () => clearTimeout(timer); // Cleanup the timer if `show` changes
    }
  }, [show, onClose]);

  return (
    <div
      style={{
        visibility: show ? 'visible' : 'hidden',
        minWidth: '250px',
        backgroundColor: '#333',
        color: '#fff',
        textAlign: 'center',
        borderRadius: '2px',
        padding: '16px',
        position: 'fixed',
        zIndex: 3,
        left: '50%',
        bottom: '30px',
        transform: 'translateX(-50%)',
      }}
    >
      {message}
      <button
        onClick={onClose}
        style={{
          marginLeft: '20px',
          backgroundColor: '#444',
          color: 'white',
          border: 'none',
          padding: '5px',
          borderRadius: '2px',
          cursor: 'pointer',
        }}
      >
        X
      </button>
    </div>
  );
};
