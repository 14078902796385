interface VimeoEmbedProps {
  videoId: string;
}

// TODO: Will update later

export default function VimeoEmbedPortrait({ videoId }: VimeoEmbedProps) {
  return (
    <iframe
      src={`https://player.vimeo.com/video/${videoId}?autoplay=1&badge=0&autopause=0&player_id=0&app_id=58479`}
      className="absolute top-0 left-0 w-full h-full"
      frameBorder="0"
      allow="autoplay; fullscreen; picture-in-picture; clipboard-write"
      allowFullScreen
      title="Vimeo Video Player"
    ></iframe>
  );
}
