import React from 'react';
import meetTheTeamHeading from '@assets/footages/meet-the-team/heading.svg';
import YouTubeEmbedPortrait from '@components/YouTubeEmbedPortrait';
import { useNavigate } from 'react-router-dom';

export default function MeetTheTeam_V0() {
  const navigate = useNavigate();
  const gotoContactUs = () => {
    navigate('/contact-us');
  };

  return (
    <>
      <div
        style={{
          backgroundImage: `url(${meetTheTeamHeading})`,
          backgroundPosition: 'top right',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'contain',
        }}
        className="px-28 pt-32 pb-24 w-full "
      >
        <div className="text-7xl font-black">Meet the Team</div>
        <div className="text-xl mt-8">
          Get to know the dedicated team behind HeyJuni!
        </div>
      </div>
      <div className="flex justify-center items-center gap-32 mt-12">
        <div className="w-[270px] h-[480px]">
          <YouTubeEmbedPortrait videoId="wkcZYIU3nW0" />
        </div>
        <div className="w-80 flex items-center">
          <div>
            <p className="text-5xl font-extrabold">Dr. Malia Nguyen</p>
            <p className="text-3xl font-semibold">Chief Executive Officer</p>
            <br />
            <p>
              Meet Malia, the Head of Product at JUNI. Her profound commitment
              and passion for mental health fuels her mission to create a
              platform that helps people navigate their emotional struggles.
            </p>
          </div>
        </div>
      </div>
      <div className="flex justify-center items-center gap-16 mt-12">
        <div className="w-80 flex items-center">
          <div>
            <p className="text-5xl font-extrabold">Mervyn Lau</p>
            <p className="text-3xl font-semibold">Chief Operating Officer</p>
            <br />
            <p>
              Meet Mervyn, our Chief Operating Officer, he is also the Chief
              Executive Officer of ‘Kickoff by Zuju’ and is an Advocate &
              Solicitor of the Supreme Court of Singapore.
            </p>
          </div>
        </div>
        <div className="w-[270px] h-[480px] bg-blue-200">
          <YouTubeEmbedPortrait videoId="wkcZYIU3nW0" />
        </div>
      </div>
      <div className="flex justify-center items-center gap-32 mt-12">
        <div className="w-[270px] h-[480px] bg-blue-200">
          <YouTubeEmbedPortrait videoId="wkcZYIU3nW0" />
        </div>
        <div className="w-80 flex items-center">
          <div>
            <p className="text-5xl font-extrabold">Linh Nguyen</p>
            <p className="text-3xl font-semibold">Co-founder, CTO</p>
            <br />
            <p>
              Meet Linh, our Chief Technology Officer and the founder of
              Beautiful Mind Vietnam, the country's largest non-profit dedicated
              to spreading knowledge and offering free counseling and support
              for mental health.
            </p>
          </div>
        </div>
      </div>
      <div className="flex justify-center items-center gap-16 mt-12">
        <div className="w-80 flex items-center">
          <div>
            <p className="text-5xl font-extrabold">Nora Liu</p>
            <p className="text-3xl font-semibold">Chief Domain Officer</p>
            <br />
            <p>
              We are thrilled to introduce Nora, an expert in counseling and
              psychology, and a cornerstone of JUNI since day one.
            </p>
          </div>
        </div>
        <div className="w-[270px] h-[480px] bg-blue-200">
          <YouTubeEmbedPortrait videoId="MhINSddDewA" />
        </div>
      </div>
      <div className="bg-[#181818] flex flex-col justify-center items-center mt-24 p-12">
        <p className="text-7xl font-extrabold text-white">
          Be a part of our team
        </p>
        <button
          className="bg-[#FFE816] hover:bg-[#977CFF]
            active:bg-[#D8CFFC] text-black hover:text-white
            active:text-black px-4 py-2 rounded-2xl mt-10 mb-8
            h-12 font-semibold text-lg"
          onClick={gotoContactUs}
        >
          Contact Us
        </button>
      </div>
    </>
  );
}
