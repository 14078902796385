import z from 'zod';

export const AcceptBookingRequestInputSchema = z.object({
    bookingRequestId: z.number(),
});
export type AcceptBookingRequestInput = z.infer<typeof AcceptBookingRequestInputSchema>;

export const CancelBookingRequestAcceptationInputSchema = z.object({
    bookingRequestId: z.number(),
});
export type CancelBookingRequestAcceptationInput = z.infer<
    typeof CancelBookingRequestAcceptationInputSchema
>;

export const GetBookingRequestAcceptedVolunteerInputSchema = z.object({
    bookingRequestId: z.number(),
});
export type GetBookingRequestAcceptedVolunteerInput = z.infer<
    typeof GetBookingRequestAcceptedVolunteerInputSchema
>;
