import React, { ReactNode } from 'react';

interface MainLayoutProps {
  children: ReactNode;
}

export default function MainLayout(props: MainLayoutProps) {
  const { children } = props;

  return <div className="mt-[62px] md:mt-[90px]">{children}</div>;
}
