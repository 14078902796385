import React from 'react';
import backgroundImage from '@assets/footages/learn-more/intro.svg';
import backgroundImageMobile from '@assets/footages/learn-more/intro-mobile.png';
import Button from '@components/Button';
import { useNavigate } from 'react-router-dom';
import { openWhatsapp } from '@src/utils/open-whatsapp';

export function IntroSection() {
  const navigate = useNavigate();

  return (
    <section className="relative bg-white mb-[64px] md:mb-[40px]">
      <div
        className="hidden md:block absolute inset-0 bg-no-repeat bg-cover z-0"
        style={{ backgroundImage: `url(${backgroundImage})` }}
      ></div>

      <div className="md:hidden absolute left-0 right-0">
        <img src={backgroundImageMobile} className="w-full" />
      </div>

      {/* TODO: Will refactor, create a component for this: desktop and mobile img */}

      <div className="relative z-10 px-[25px] py-[20px] md:px-[76px] md:py-[94px]">
        <div className="md:max-w-[646px] flex flex-col gap-[20px] md:gap-[40px]">
          <h1 className="font-extrabold leading-[58px] md:leading-[85px] text-6xl md:text-8xl">
            You are not alone
          </h1>

          <div className="font-bold text-xl md:font-semibold md:text-3xl">
            We're here to listen to your story, and guide you through coping
            with your trauma and anxiety.
          </div>
          <div className="font-regular text-lg md:font-regular md:text-2xl">
            HeyJuni is dedicated to offering on-demand, instant emotional
            support for women facing trauma and anxiety stemming from sexual
            assault. In an anonymous and non-judgmental space, you are matched
            with trained and vetted female Peer Supporters - empathic
            professionals who provide a safe environment to confide, find
            guidance for coping, and relief from anxiety in the moment.
          </div>

          <div>
            <Button
              label="Get Support"
              className="h-[50px] rounded-[20px] md:h-[60px] md:rounded-[24px] font-semibold text-lg md:text-2xl !px-[30px]"
              onClick={openWhatsapp}
            />
          </div>
        </div>
      </div>
    </section>
  );
}
