import React from 'react';
import clsx from 'clsx';

interface ButtonProps {
  className?: string;
  label: string;
  onClick?: () => void;
  variant?: 'primary' | 'outlined'; // Add variant prop
}

export default function Button(props: ButtonProps) {
  const { className, label, onClick, variant = 'primary' } = props; // Default variant is primary

  return (
    <button
      onClick={onClick}
      className={clsx(
        'whitespace-nowrap px-2 md:px-4 py-1 md:py-2 rounded-3xl md:rounded-3xl transition-all duration-100', // Common classes
        {
          // Primary variant (default)
          'text-black bg-btn-primary hover:bg-[#9DFFE2] active:bg-[#D8CFFC] active:text-black':
            variant === 'primary',

          // Outlined variant
          'bg-[#EBEDF0] text-black hover:bg-[#9DFFE2] active:bg-[#D8CFFC] active:text-black':
            variant === 'outlined',
        },
        className
      )}
    >
      {label}
    </button>
  );
}
