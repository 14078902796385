import Button from '@components/Button';

import becomeSupporterImg01 from '@assets/footages/become-a-listener/become-supporter-step-01.svg';
import becomeSupporterImg02 from '@assets/footages/become-a-listener/become-supporter-step-02.svg';
import becomeSupporterImg03 from '@assets/footages/become-a-listener/become-supporter-step-03.svg';
import becomeSupporterImg04 from '@assets/footages/become-a-listener/become-supporter-step-04.svg';
import { MobileSwipe } from '@components/MobileSwipe';
import { openWhatsapp } from '@src/utils/open-whatsapp';

const steps = [
  {
    id: 1,
    img: becomeSupporterImg01,
    title: 'Fill out Form',
    description:
      'Click on “Become Peer Supporter” and complete the application form.',
  },
  {
    id: 2,
    img: becomeSupporterImg02,
    title: 'Get in Touch',
    description:
      'Our team will get in touch with you after reviewing your application.',
  },
  {
    id: 3,
    img: becomeSupporterImg03,
    title: 'Complete Training',
    description:
      'Complete our Training and Curriculum if you haven’t done so already.',
  },
  {
    id: 4,
    img: becomeSupporterImg04,
    title: 'Start Onboarding',
    description:
      'Start onboarding process with our clinicians, get ready to support others.',
  },
];

function MobileCards() {
  return (
    <>
      {steps.map((step) => (
        <div
          key={step.id}
          className="min-w-full flex flex-col items-center justify-center"
        >
          <img src={step.img} alt={step.title} />
          <p className="font-extrabold text-5xl md:leading-[48px]">{step.id}</p>
          <p className="font-extrabold text-[28px] md:leading-[36px]">
            {step.title}
          </p>
          <p className="px-[40px] text-lg text-center">{step.description}</p>
        </div>
      ))}
    </>
  );
}

export function BecomeSupporterSection() {
  return (
    <div className="mb-[84px] md:mb-0 md:py-[64px]">
      <div className="py-[20px] md:py-0 text-center font-extrabold text-5xl md:text-7xl leading-[48px] md:leading-[65px]">
        Steps to be a Peer Supporter
      </div>

      <div className="hidden md:flex flex-wrap md:py-[18px]">
        {steps.map((step) => (
          <div
            key={step.id}
            className="w-1/4 flex flex-col items-center gap-[16px]"
          >
            <img src={step.img} alt={step.title} />
            <p className="font-extrabold md:text-5xl md:leading-[48px]">
              {step.id}
            </p>
            <p className="font-extrabold md:text-[28px] md:leading-[36px]">
              {step.title}
            </p>
            <p className="text-lg text-center">{step.description}</p>
          </div>
        ))}
      </div>

      <MobileSwipe dataList={steps}>
        <MobileCards />
      </MobileSwipe>

      <div className="flex flex-col items-center mt-[40px] md:mb-[54px]">
        <Button
          label="Start your journey"
          className="h-[50px] md:h-[60px] px-[30px] rounded-[20px] font-semibold text-lg md:text-2xl"
          onClick={openWhatsapp}
        />
      </div>
    </div>
  );
}
