import React from 'react';
import peopleImage from '@assets/footages/learn-more/people.svg';
import { useNavigate } from 'react-router-dom';
import Button from '@components/Button';

export default function ListenersPromiseSection() {
  const navigate = useNavigate();

  return (
    <section className="relative bg-white md:py-[64px] md:px-[76px] mb-[64px] md:mb-[40px] overflow-hidden">
      {/* TODO: Create the common css for title */}
      <div className="text-5xl leading-[48px] md:leading-[65px] md:text-7xl font-extrabold text-center">
        Peer Supporters Promise
      </div>

      <div className="grid grid-cols-1 md:grid-cols-3 mt-[16px] mb-[40px] px-[28px] md:px-0 md:my-[40px] gap-[20px]">
        <Card
          title="Anonymity:"
          description="Our Peer Supporters follow strict policies to protect your privacy and anonymity."
          bgColor="bg-[#D8FFF3]"
        />
        <Card
          title="Training:"
          description="They’re professionally trained in providing active listening, empathy, and emotional guidance."
          bgColor="bg-[#D8CFFC]"
        />
        <Card
          title="Trust:"
          description="Our peer supporters provide non-judgmental anxiety support, grounded in clinical evidence."
          bgColor="bg-[#FEE60C]"
        />
      </div>

      <div className="text-center">
        <Button
          label="Discover Pricing"
          className="h-[50px] rounded-[20px] md:h-[60px] md:rounded-[24px] font-semibold text-lg md:text-2xl !px-[30px]"
          onClick={() => navigate('/pricing')}
        />
      </div>
    </section>
  );
}

interface CardProps {
  title: string;
  description: string;
  bgColor: string;
}

function Card({ title, description, bgColor }: CardProps) {
  return (
    <div
      className={`flex flex-col items-center justify-center md:justify-start ${bgColor} text-center py-[40px] md:py-[48px] px-[32px] h-[175px] md:h-auto`}
    >
      <h3 className="text-xl md:text-4xl font-bold">{title}</h3>
      <p className="text-base md:text-2xl font-regular">{description}</p>
    </div>
  );
}
