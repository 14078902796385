import React, { useContext, useState, useEffect } from 'react';

import { useBookingContext } from './BookingContext';
import Option from './components/Option';

import icon from '@assets/footages/booking/booking-confirmed.svg';
import { useLocation, useNavigate } from 'react-router-dom';
import Button from './components/Button';
import { trpc } from '@src/App';

export function Step13() {
  const { setCurrentStep, address, setAddress } = useBookingContext();
  const navigate = useNavigate();

  const location = useLocation();

  const [bookingDate, setBookingDate] = useState<string | null>(null);
  const [bookingTime, setBookingTime] = useState<string | null>(null);

  const confirmBookingMutation =
    trpc.bookingRequest.confirmBookingRequest.useMutation();

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    let datetime = queryParams.get('datetime');

    if (datetime) {
      // Use decodeURIComponent to preserve any special characters like '+'
      datetime = decodeURIComponent(datetime);
      const localDate = new Date(datetime);

      const bookingRequestId = queryParams.get('bookingRequestId');
      bookingRequestId &&
        confirmBookingMutation.mutateAsync({
          bookingRequestId: Number(bookingRequestId),
        });

      if (!isNaN(localDate.getTime())) {
        setBookingDate(localDate.toLocaleDateString());
        setBookingTime(
          localDate.toLocaleTimeString([], {
            hour: '2-digit',
            minute: '2-digit',
          })
        );
      }
    }
  }, [location.search]);

  return (
    <>
      <div className="mx-auto mb-[16px] md:mb-[10px]">
        <img
          src={icon}
          alt="Confirmation"
          className="w-[60px] h-[60px] md:w-[88px] md:h-[88px]"
        />
      </div>

      <div className="text-center mb-[24px]">
        <div className="font-extrabold text-4xl md:font-bold md:text-4xl leading-[36px] md:leading-[40px]">
          Your Booking is Confirmed.
          <br />A Peer Supporter will be assigned to you shortly.
        </div>
        <div className="font-semibold text-base md:text-3xl md:leading-[30px] mt-[20px]">
          Your session is scheduled for <span>{bookingDate || '[DATE]'}</span>{' '}
          at <span>{bookingTime || '[TIME]'}</span>
        </div>
      </div>

      <div className="font-regular text-base md:text-lg">
        <div className="mb-6">
          <p>Please check your email for:</p>
          <ul className="list-disc list-inside">
            <li>Details about your designated Peer Supporter</li>
            <li>Your payment invoice</li>
          </ul>
        </div>

        <div className="mb-6">
          <p>
            Make sure to add the session to your calendar so you don’t miss it!
            <br />
            If you have any questions, feel free to contact us at{' '}
            <a href="mailto:contact@heyjuni.com">contact@heyjuni.com</a>.
          </p>
        </div>

        <p className="">We look forward to connecting with you.</p>
      </div>
    </>
  );
}
