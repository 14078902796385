import ChipRadio from '@components/ChipRadio';
import React, { useEffect, useState } from 'react';
import heading from '@assets/footages/faq/heading.svg';
import headingMobile from '@assets/footages/faq/heading-mobile.svg';
import GeneralFAQ from '@components/FAQs/GeneralFAQ';
import HelpSeekersFAQ from '@components/FAQs/HelpSeekersFAQ';
import ListenersFAQ from '@components/FAQs/ListenersFAQ';
import PrivacyFAQ from '@components/FAQs/PrivacyFAQ';
import { useSearchParams } from 'react-router-dom';

export default function FAQ() {
  const [searchParams] = useSearchParams();
  const selected = searchParams.get('selected');

  const [selectedOption, setSelectedOption] = useState<string>(
    selected ?? 'General'
  );

  const renderFAQContent = () => {
    switch (selectedOption) {
      case 'General':
        return <GeneralFAQ />;
      case 'Users':
        return <HelpSeekersFAQ />;
      case 'Peer Supporter':
        return <ListenersFAQ />;
      case 'Privacy':
        return <PrivacyFAQ />;
      default:
        return <div>Please select an FAQs category to view the content.</div>;
    }
  };

  useEffect(() => {
    setSelectedOption(selected ?? 'General');
  }, [selected]);

  return (
    <div className="relative">
      <div
        className="hidden md:block absolute top-0 left-0"
        style={{ zIndex: -1 }}
      >
        <img src={heading} alt="heading background" />
      </div>

      <div
        className="md:hidden absolute top-0 left-0 transform -translate-y-[31px] w-full"
        style={{ zIndex: -1 }}
      >
        <img
          src={headingMobile}
          alt="heading background"
          className="w-full object-cover"
        />
      </div>

      <div className="py-[64px] px-[28px] md:px-0 md:py-[96px] md:w-[58%] mx-auto">
        <div className="font-black text-6xl leading-[58px] md:text-8xl md:leading-[85px]">
          FAQs
        </div>
        <div className="mt-[50px] md:mt-[114px]">
          <ChipRadio
            options={['General', 'Users', 'Peer Supporter', 'Privacy']}
            selectedOption={selectedOption}
          />
        </div>
        <div className="mt-[65px] md:mt-[33px]">{renderFAQContent()}</div>
      </div>
    </div>
  );
}
// max-w-3xl
