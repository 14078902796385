import React from 'react';
import BecomeAListenerHeading from '@assets/footages/become-a-listener/heading.svg';
import BecomeAListenerHeadingMobile from '@assets/footages/become-a-listener/heading-mobile.svg';
import BecomeAListenerHeroImage from '@assets/footages/become-a-listener/hero.png';
import fillOutFormImage from '@assets/footages/become-a-listener/fill-out-form.svg';
import learnAndUpskillImage from '@assets/footages/become-a-listener/learn-and-upskill.svg';
import getCertifiedImage from '@assets/footages/become-a-listener/get-certified.svg';
import giveAListeningEarImage from '@assets/footages/become-a-listener/give-a-listening-ear.svg';
import { WomanSupportSection } from './woman-support-section';
import { SupportersDoSection } from './supporters-do-section';
import { BecomeSupporterSection } from './become-supporter-section';
import TrainingSection from './training-section';
import { WhyBecomeSupportersSection } from './why-become-supporters-section';
import FaqSection from './faq-section';
import JoinUsSection from './join-us-section';
import { MeetSupportersSection } from './meet-some-supporters-section';
import Button from '@components/Button';
import { openGoogleForm } from '@src/utils/open-googleform';

export default function BecomeAListener() {
  return (
    <>
      <div className="relative px-[28px] md:px-[76px] pt-[46px] md:pt-[65px] w-full">
        <div
          className="hidden md:block absolute top-0 right-0"
          style={{ zIndex: -1 }}
        >
          <img src={BecomeAListenerHeading} alt="" />
        </div>

        <div
          className="md:hidden absolute top-0 right-0 w-full"
          style={{ zIndex: -1 }}
        >
          <img src={BecomeAListenerHeadingMobile} alt="" className="w-full" />
        </div>

        <div className="">
          <div className="font-extrabold text-[38px] md:text-8xl leading-[48px] md:leading-[85px] font-black">
            <p>Empower Others.</p>
            <p>Grow Your Impact.</p>
            <p>Build Your Career.</p>
          </div>
          <div className="md:text-2xl md:leading-[32px] mt-[20px] md:mt-[40px] md:w-1/2 pr-[45px] md:pr-0">
            Step into a role where your empathy and life experience become
            powerful tools for healing. Through our peer support training, gain
            skills to help others - all while growing personally and
            professionally. Join a purpose-driven community, get certified, and
            turn your passion for supporting others into a career.
          </div>
          <Button
            label="Become Peer Supporters"
            className="mt-[64px] mb-[52px] md:mt-[40px] md:mb-[64px] rounded-[20px] px-[30px] h-[50px] md:h-[60px] font-semibold text-lg md:text-2xl"
            onClick={openGoogleForm}
          />
        </div>
      </div>
      <WomanSupportSection />
      <SupportersDoSection />
      <MeetSupportersSection
        actionLabel="Become Peer Supporter"
        handleAction={openGoogleForm}
      />
      <BecomeSupporterSection />
      <TrainingSection />
      <WhyBecomeSupportersSection />
      <FaqSection />
      <JoinUsSection />
    </>
  );
}
